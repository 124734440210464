import { OPERATOR_SUCCESS, OPERATOR_SUBMIT, QC_SUCCESS, QC_SUBMIT, COMPLETED } from 'constants/formStatus';
import { OPERATOR, ADMIN, SUPER_ADMIN, QC, FOREMAN } from 'constants/global-roles';
import {
  initialValuesOperator,
  initialValuesOperatorSuccess,
  createEnabledFields,
  initialValuesQc,
  schemaOperator,
  schemaQc,
} from 'pages/FailesSafety/FailesSafetyForm/FailesSafety.schema';

const adminEnabled = {
  [OPERATOR_SUCCESS]: {
    enabledFields: initialValuesOperatorSuccess,
    validationSchema: schemaOperator,
    canAddFiles: true,
    showEmails: true,
    showSign: true,
    showSubmit: true,
    showRollBack: false,
    // rollBackQuery: () => {},
  },
  [OPERATOR_SUBMIT]: {
    enabledFields: initialValuesQc,
    validationSchema: schemaQc,
    canAddFiles: true,
    showEmails: true,
    showSign: true,
    showSubmit: true,
    showRollBack: true,
    // rollBackQuery: rollBackForm2ToOperatorQuery,
  },
  [QC_SUCCESS]: {
    enabledFields: initialValuesQc,
    validationSchema: schemaQc,
    canAddFiles: true,
    showEmails: true,
    showSign: true,
    showSubmit: true,
    showRollBack: true,
    // rollBackQuery: rollBackForm2ToOperatorQuery,
  },
  [QC_SUBMIT]: {
    enabledFields: {},
    validationSchema: null,
    canAddFiles: false,
    showEmails: true,
    showSign: true,
    showSubmit: false,
    showRollBack: true,
    // rollBackQuery: rollBackForm2ToQcQuery,
  },
  [COMPLETED]: {
    enabledFields: {},
    validationSchema: null,
    canAddFiles: false,
    showEmails: true,
    showSign: false,
    showSubmit: false,
    showRollBack: true,
    // rollBackQuery: rollBackForm2ToForemanQuery,
  },
};

const operatorEnabled = {
  [OPERATOR_SUCCESS]: {
    enabledFields: initialValuesOperatorSuccess,
    validationSchema: schemaOperator,
    canAddFiles: true,
    showEmails: true,
    showSign: true,
    showSubmit: true,
    showRollBack: false,
    // rollBackQuery: () => {},
  },
  [OPERATOR_SUBMIT]: {
    enabledFields: {},
    validationSchema: schemaQc,
    canAddFiles: false,
    showEmails: true,
    showSign: false,
    showSubmit: false,
    showRollBack: false,
    // rollBackQuery: () => {},
  },
  [QC_SUCCESS]: {
    enabledFields: {},
    validationSchema: schemaQc,
    canAddFiles: false,
    showEmails: true,
    showSign: false,
    showSubmit: false,
    showRollBack: false,
    // rollBackQuery: () => {},
  },
  [QC_SUBMIT]: {
    enabledFields: {},
    validationSchema: null,
    canAddFiles: false,
    showEmails: true,
    showSign: false,
    showSubmit: false,
    showRollBack: false,
    // rollBackQuery: () => {},
  },
  [COMPLETED]: {
    enabledFields: {},
    validationSchema: null,
    canAddFiles: false,
    showEmails: true,
    showSign: false,
    showSubmit: false,
    showRollBack: false,
    // rollBackQuery: () => {},
  },
};

const qcEnabled = {
  [OPERATOR_SUCCESS]: {
    enabledFields: {},
    validationSchema: null,
    canAddFiles: false,
    showEmails: true,
    showSign: false,
    showSubmit: false,
    showRollBack: false,
    // rollBackQuery: () => {},
  },
  [OPERATOR_SUBMIT]: {
    enabledFields: initialValuesQc,
    validationSchema: schemaQc,
    canAddFiles: true,
    showEmails: true,
    showSign: true,
    showSubmit: true,
    showRollBack: true,
    // rollBackQuery: rollBackForm2ToOperatorQuery,
  },
  [QC_SUCCESS]: {
    enabledFields: initialValuesQc,
    validationSchema: schemaQc,
    canAddFiles: true,
    showEmails: true,
    showSign: true,
    showSubmit: true,
    showRollBack: true,
    // rollBackQuery: rollBackForm2ToOperatorQuery,
  },
  [QC_SUBMIT]: {
    enabledFields: {},
    validationSchema: null,
    canAddFiles: false,
    showEmails: true,
    showSign: false,
    showSubmit: false,
    showRollBack: false,
    // rollBackQuery: () => {},
  },
  [COMPLETED]: {
    enabledFields: {},
    validationSchema: null,
    canAddFiles: false,
    showEmails: true,
    showSign: false,
    showSubmit: false,
    showRollBack: false,
    // rollBackQuery: () => {},
  },
};

const foremanEnabled = {
  [OPERATOR_SUCCESS]: {
    enabledFields: {},
    validationSchema: null,
    canAddFiles: false,
    showEmails: true,
    showSign: false,
    showSubmit: false,
    showRollBack: false,
    // rollBackQuery: () => {},
  },
  [OPERATOR_SUBMIT]: {
    enabledFields: {},
    validationSchema: null,
    canAddFiles: false,
    showEmails: true,
    showSign: false,
    showSubmit: false,
    showRollBack: false,
    // rollBackQuery: () => {},
  },
  [QC_SUCCESS]: {
    enabledFields: {},
    validationSchema: null,
    canAddFiles: false,
    showEmails: true,
    showSign: false,
    showSubmit: false,
    showRollBack: false,
    // rollBackQuery: () => {},
  },
  [QC_SUBMIT]: {
    enabledFields: {},
    validationSchema: null,
    canAddFiles: false,
    showEmails: true,
    showSign: true,
    showSubmit: false,
    showRollBack: true,
    // rollBackQuery: rollBackForm2ToQcQuery,
  },
  [COMPLETED]: {
    enabledFields: {},
    validationSchema: null,
    canAddFiles: false,
    showEmails: true,
    showSign: false,
    showSubmit: false,
    showRollBack: false,
    // rollBackQuery: () => {},
  },
};

export default {
  [SUPER_ADMIN]: {
    createAvailable: true,
    dependencies: adminEnabled,
    enabledFields: createEnabledFields,
    validationSchema: schemaOperator,
  },
  [ADMIN]: {
    createAvailable: true,
    dependencies: adminEnabled,
    enabledFields: createEnabledFields,
    validationSchema: schemaOperator,
  },
  [OPERATOR]: {
    createAvailable: true,
    dependencies: operatorEnabled,
    enabledFields: createEnabledFields,
    validationSchema: schemaOperator,
  },
  [QC]: {
    createAvailable: false,
    dependencies: qcEnabled,
    enabledFields: initialValuesQc,
    validationSchema: schemaQc,
  },
  [FOREMAN]: {
    createAvailable: false,
    dependencies: foremanEnabled,
    enabledFields: {},
    validationSchema: null,
  },
};
