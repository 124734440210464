import * as Yup from 'yup';

const Schema = Yup.object().shape({
  password: Yup.string()
    .min(1, 'Password must be at least 1 characters')
    .required('Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Required'),
});

export const initialValues = {
  password: '',
  confirmPassword: '',
};

export default Schema;
