// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import routes from 'pages/routes';
import { showNotification } from 'utils/index';
import dictionary from 'constants/dictionary';
import dictionaryPhrases from 'constants/dictionary.phrases';
import { useRouting } from 'hooks/useRouting';
import useTable from 'hooks/useTable';
import api from 'api/index';
import { Link } from 'react-router-dom';
import { EditIcon, DeleteIcon, RestoreIcon, List } from 'components/index';
import type { Column } from 'types/frontTypes';
import type { User } from 'types/ServerTypes/User';

const deleteUserQuery = (id) => ({
  query: `mutation deleteUser{
    deleteUser(
      id:"${id}"
    )
  }`,
});

const recoveryUserQuery = (id) => ({
  query: `mutation recoveryUser{
    recoveryUser(
      id:"${id}"
    )
  }`,
});

const getAllUsersQuery = ({ pageBy }: any) => ({
  query: `query findAllUser{
    findAllUser{
      globalRole
      phone
      createdAt
      updateAt
      updateBy{
        firstName,
        lastName
      }
      lastName
      firstName
      authDataId{
        email
      }
      id
      deleted
    }
  }`,
});

const useStyles = makeStyles({
  buttons: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  recovery: {
    display: 'flex',
    justifyContent: 'center',
  },
});

export default () => {
  const { formatRoute } = useRouting();
  const classes = useStyles();

  const createLink = routes.userCreate.path;
  const createAvailable = true;

  const {
    data,
    pagination,
    popup,
    popup: { openPopover },
    recoveryItem,
  } = useTable({
    getItemsQuery: (values: any) =>
      api
        .fetchQuery(getAllUsersQuery(values))
        .then(({ findAllUser }) => ({ data: findAllUser, size: findAllUser.length })),
    deleteItemQuery: (id) =>
      api.fetchQuery(deleteUserQuery(id)).then(() => {
        showNotification(dictionaryPhrases.deleteUserSuccess);
      }),
    recoveryItemQuery: (id) =>
      api.fetchQuery(recoveryUserQuery(id)).then(() => {
        showNotification(dictionaryPhrases.recoveryUserSuccess);
      }),
    isServerPagination: false,
  });

  const columns: Column<User>[] = [
    {
      title: '',
      key: 'editAction',
      render: (record) => {
        return (
          <div className={classes.buttons}>
            {record.deleted ? (
              <RestoreIcon onClick={recoveryItem(record.id)} />
            ) : (
              <Link to={formatRoute(routes.userPage.path, { id: record.id })}>
                <EditIcon />
              </Link>
            )}
          </div>
        );
      },
    },
    {
      title: '',
      key: 'deleteAction',
      render: (record) => {
        return record.deleted ? (
          ' '
        ) : (
          <div className={classes.buttons}>
            <DeleteIcon onClick={openPopover('deleteItem', record)} />
          </div>
        );
      },
    },
    {
      title: dictionary.position,
      key: 'position',
      render: (record) => dictionary[record.globalRole],
    },
    'phone',
    'updateAt',
    {
      title: dictionary.updateBy,
      key: 'updateBy',
      render: (record) => `${record.updateBy.firstName} ${record.updateBy.lastName}`,
    },
    'lastName',
    'firstName',
    {
      title: dictionary.email,
      key: 'email',
      render: (record) => (record.authDataId ? record.authDataId.email : '*****'), // NOTE: admin see null as superadmin's email,
    },
  ];

  const useProjectsData = null;
  const filtering = null;

  return (
    <List
      data={data}
      createLink={createLink}
      columns={columns}
      pagination={pagination}
      createAvailable={createAvailable}
      popup={popup}
      useProjectsData={useProjectsData}
      filtering={filtering}
    />
  );
};
