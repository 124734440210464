import React from 'react';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import T from 'prop-types';

import logo from 'img/logo.png';
import background from 'img/bg-01.jpg';
import dictionary from 'constants/dictionary';
import Agreements from './Agreements/Agreements';
import Form from './LoginForm/LoginForm';
import TermsAndConditions from './TermsAndConditions/TermsAndConditions.container';

const styles = () => ({
  background: {
    minHeight: '100vh',
    background: `url(${background})`,
    backgroundSize: 'cover',
  },
  container: {
    width: '100%',
    margin: '0 auto',
    maxWidth: '980px',
    padding: '10px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    padding: '0 50% 24px 10px',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    background: '0 0',
  },
  formWrapper: {
    width: '52%',
    padding: '58px 84px 40px',
    margin: '5vh auto',
    borderRadius: '5px',
    background: '#fff',
  },
  contentHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    padding: '5px',
    marginRight: '5px',
    border: '1px solid grey',
  },
  docListWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
  },
});

const Login = ({
  classes,
  isOpenAgreements,
  toggleOpenAgreements,
  isOpenTerms,
  handleOpenTerms,
  handleCloseTerms,
  onSubmit,
}) => {
  return (
    <>
      {isOpenTerms && (
        <TermsAndConditions
          isOpenTerms={isOpenTerms}
          handleOpenTerms={handleOpenTerms}
          handleCloseTerms={handleCloseTerms}
        />
      )}

      <div className={classes.background}>
        <div className={classes.container}>
          <div className={classes.header}>
            <div className="logo">
              <a href="#">
                <img src={logo} alt="" />
              </a>
            </div>
          </div>

          <div className={classes.wrapper}>
            <div className={classes.formWrapper}>
              <div className={classes.contentHeader}>
                <h2>{dictionary.login}</h2>
              </div>

              <Form onSubmit={onSubmit} />

              <div className={classes.docListWrapper}>
                <IconButton className={classes.button} onClick={toggleOpenAgreements}>
                  {isOpenAgreements ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                </IconButton>
                <div>{dictionary.agreementDocuments}</div>
              </div>

              {isOpenAgreements && <Agreements />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Login.propTypes = {
  classes: T.objectOf(T.any).isRequired,
  isOpenTerms: T.bool.isRequired,
  isOpenAgreements: T.bool.isRequired,
  toggleOpenAgreements: T.func.isRequired,
  handleOpenTerms: T.func.isRequired,
  handleCloseTerms: T.func.isRequired,
  onSubmit: T.func.isRequired,
};

export default withStyles(styles)(Login);
