import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

import { ProtectedRoute, Layout } from 'components';
import routes from 'pages/routes';

import NotFound from 'pages/NotFound/NotFound';
import Login from 'pages/Login/Login.container';
import Reports from 'pages/Reports/Reports.container';
import ChangePassword from 'pages/ChangePassword/ChangePassword.container';

import HomeDashboard from 'pages/HomeDashboard/HomeDashboard.container';

import Users from 'pages/Users/UserList/UserList';
import UserCreate from 'pages/Users/UserCreate/UserCreate';
import UserPage from 'pages/Users/UserUpdate/UserUpdate';

import Projects from 'pages/Projects/ProjectList/ProjectList';
import ProjectCreate from 'pages/Projects/ProjectCreate/ProjectCreate';
import ProjectPage from 'pages/Projects/ProjectUpdate/ProjectPage.container';

import ProjectUsers from 'pages/ProjectUsers/ProjectUserList/ProjectUserList';
import ProjectUserCreate from 'pages/ProjectUsers/ProjectUserCreate/ProjectUserCreate';
import ProjectUserPage from 'pages/ProjectUsers/ProjectUserUpdate/ProjectUserUpdate.container';

import EmailSchedule from 'pages/EmailSchedule/EmailSchedule';

import Contacts from 'pages/Contacts/ContactList/ContactList';
import ContactCreate from 'pages/Contacts/ContactCreate/ContactCreate';
import ContactPage from 'pages/Contacts/ContactUpdate/ContactPage.container';

import Forms1 from 'pages/TrafficAccidents/TrafficAccidentList/TrafficAccidentList';
import Form1Create from 'pages/TrafficAccidents/TrafficAccidentCreate/TrafficAccidentCreate';
import Form1Page from 'pages/TrafficAccidents/TrafficAccidentUpdate/TrafficAccidentPage.container';

import Forms2 from 'pages/FailesSafety/FailesSafetyList/FailesSafetyList';
import Form2Create from 'pages/FailesSafety/FailesSafetyCreate/FailesSafetyCreate';
import Form2Page from 'pages/FailesSafety/FailesSafetyUpdate/FailesSafetyPage.container';

import Forms3 from 'pages/TreatmentOfImpaired/TreatmentOfImpairedList/TreatmentOfImpairedList';
import Form3Create from 'pages/TreatmentOfImpaired/TreatmentOfImpairedCreate/TreatmentOfImpairedCreate';
import Form3Page from 'pages/TreatmentOfImpaired/TreatmentOfImpairedUpdate/TreatmentOfImpairedPage.container';

import Forms4 from 'pages/TrafficEvents/TrafficEventList/TrafficEventList';
import Form4Create from 'pages/TrafficEvents/TrafficEventCreate/TrafficEventCreate';
import Form4Page from 'pages/TrafficEvents/TrafficEventUpdate/TrafficEventPage.container';

const Router = () => (
  <BrowserRouter>
    <Switch>
      <ProtectedRoute exact {...routes.home} />
      <ProtectedRoute {...routes.login} component={Login} exact />
      <Layout>
        <Switch>
          <ProtectedRoute exact {...routes.dashboard} component={HomeDashboard} />

          <ProtectedRoute {...routes.users} component={Users} exact />
          <ProtectedRoute {...routes.userCreate} component={UserCreate} exact />
          <ProtectedRoute {...routes.userPage} component={UserPage} exact />

          <ProtectedRoute {...routes.projects} component={Projects} exact />
          <ProtectedRoute {...routes.projectCreate} component={ProjectCreate} exact />
          <ProtectedRoute {...routes.projectPage} component={ProjectPage} exact />

          <ProtectedRoute {...routes.projectUsers} component={ProjectUsers} exact />
          <ProtectedRoute {...routes.projectUserCreate} component={ProjectUserCreate} exact />
          <ProtectedRoute {...routes.projectUserPage} component={ProjectUserPage} exact />

          <ProtectedRoute {...routes.emailSchedule} component={EmailSchedule} exact />

          <ProtectedRoute {...routes.forms1} component={Forms1} exact />
          <ProtectedRoute {...routes.form1Create} component={Form1Create} exact />
          <ProtectedRoute {...routes.form1Page} component={Form1Page} exact />

          <ProtectedRoute {...routes.forms2} component={Forms2} exact />
          <ProtectedRoute {...routes.form2Create} component={Form2Create} exact />
          <ProtectedRoute {...routes.form2Page} component={Form2Page} exact />

          <ProtectedRoute {...routes.forms3} component={Forms3} exact />
          <ProtectedRoute {...routes.form3Create} component={Form3Create} exact />
          <ProtectedRoute {...routes.form3Page} component={Form3Page} exact />

          <ProtectedRoute {...routes.forms4} component={Forms4} exact />
          <ProtectedRoute {...routes.form4Create} component={Form4Create} exact />
          <ProtectedRoute {...routes.form4Page} component={Form4Page} exact />

          <ProtectedRoute {...routes.contacts} component={Contacts} exact />
          <ProtectedRoute {...routes.contactCreate} component={ContactCreate} exact />
          <ProtectedRoute {...routes.contactPage} component={ContactPage} exact />

          <ProtectedRoute {...routes.getReports} component={Reports} exact />
          <ProtectedRoute {...routes.changePassword} component={ChangePassword} exact />
          <Route component={NotFound} />
        </Switch>
      </Layout>
      <Route component={NotFound} />
    </Switch>
  </BrowserRouter>
);

export default Router;
