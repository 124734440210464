import React from 'react';
import T from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Formik, Field } from 'formik';
import MenuItem from '@material-ui/core/MenuItem';

import { SelectField, DatePickerField, Actions } from 'components/index';
import dictionary from 'constants/dictionary';
import validationSchema from './ReportsForm.schema';

const styles = (theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '300px',
    margin: '0 auto',
  },
});

const ReportForm = ({
  classes,
  selectValues,
  onSubmit,
  initialValues,
  handleBackAction,
  formTypeMap,
}) => {
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit} className={classes.form}>
            <Field component={SelectField} name="projectId" label={dictionary.projectId}>
              {selectValues.map((value) => (
                <MenuItem key={value.id} value={value.id}>
                  {value.name}
                </MenuItem>
              ))}
            </Field>

            <Field component={SelectField} name="formType" label="בחר ריכוז'">
              {formTypeMap.map((value, index) => (
                <MenuItem key={index} value={value.formType}>
                  {value.name}
                </MenuItem>
              ))}
            </Field>

            <Field component={DatePickerField} name="fromDate" label="מתאריך" />

            <Field component={DatePickerField} name="toDate" label="עד תאריך" />

            <Actions handleBackAction={handleBackAction} />
          </form>
        );
      }}
    </Formik>
  );
};

ReportForm.propTypes = {
  classes: T.objectOf(T.string).isRequired,
  initialValues: T.objectOf(T.string).isRequired,
  selectValues: T.arrayOf(T.objectOf(T.any)).isRequired,
  onSubmit: T.func.isRequired,
  handleBackAction: T.func.isRequired,
};

export default withStyles(styles)(ReportForm);
