// @flow
import React from 'react';
import Right from '@material-ui/icons/ArrowRight';
import Left from '@material-ui/icons/ArrowLeft';
import { makeStyles } from '@material-ui/core/styles';

import dictionary from 'constants/dictionary';
import type { PageBy } from 'types/Pageby';

const useStyles = makeStyles({
  arrow: {
    cursor: 'pointer',
    padding: '0px',
    position: 'relative',
    top: '6px',
  },
});

type Props = {
  rows: number,
  pagination: {
    pageBy: PageBy,
    changePage: (page: number) => void,
  },
};

const Pagination = ({
  rows,
  pagination: {
    pageBy: { page, rowsPerPage },
    changePage,
  },
}: Props) => {
  const classes = useStyles();
  const from = rows > 0 ? (page - 1) * rowsPerPage + 1 : 0;
  // eslint-disable-next-line no-nested-ternary
  const to = rows > 0 ? (from + rowsPerPage - 1 > rows ? rows : from + rowsPerPage - 1) : 0;
  const pages = Math.ceil(rows / rowsPerPage);

  const goToPrevPage = () => (page > 1 ? changePage(page - 1) : null);
  const goToNextPage = () => (page < pages ? changePage(page + 1) : null);

  return (
    <div>
      <Left onClick={goToPrevPage} className={classes.arrow} disabled={page === 1} />
      <span>{`${dictionary.from} ${from} ${dictionary.to} ${to}`}</span>
      <Right onClick={goToNextPage} className={classes.arrow} disabled={page >= pages} />
      <span>{`${dictionary.total} ${rows}`}</span>
    </div>
  );
};

export default Pagination;
