import * as Yup from 'yup';

const Schema = Yup.object().shape({
  name: Yup.string().required('Required'),
  mainContractor: Yup.string().required('Required'),
  logoMainContractor: Yup.mixed(),
  managementCompany: Yup.string().required('Required'),
  contractNumber: Yup.string().required('Required'),
  qcCompany: Yup.string().required('Required'),
  qaCompany: Yup.string().required('Required'),
  customerCompany: Yup.string(),
});

export const initialValues = {
  name: '',
  mainContractor: '',
  logoMainContractor: undefined,
  managementCompany: '',
  contractNumber: '',
  qcCompany: '',
  qaCompany: '',
  customerCompany: '',
};

export default Schema;
