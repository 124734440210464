// @flow
export default (time: null | string | Date): number => {
  if (time === null) {
    return 0;
  }
  if (typeof time === 'string') {
    return Date.parse(time);
  }
  return time.getTime();
};
