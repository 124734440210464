// @flow
import * as React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import type { DefaultInputProps } from 'types/DefaultInputProps';

type Props = DefaultInputProps & {
  onChange: (e: SyntheticEvent<>) => void,
};

const DefaultCheckBoxField = ({ label, value, onChange }: Props) => {
  return (
    <FormControlLabel
      value={value}
      checked={value}
      control={<Checkbox color="default" />}
      label={((label: any): React.Node)}
      labelPlacement="end"
      onChange={onChange}
    />
  );
};

export default DefaultCheckBoxField;
