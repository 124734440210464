// @flow
import * as React from 'react';

import { FORM_HEADER } from 'constants/formSetup';
import dictionaryPhrases from 'constants/dictionary.phrases';
import { DashboardContentWrapper } from 'components/index';
import api from 'api/index';
import useCreateItem from 'hooks/useCreateItem';
import { showNotification, toBase64 } from 'utils/index';
import defaultImage from 'img/defaultImage';

import Form from '../ProjectForm/ProjectForm';
import { initialValues } from '../ProjectForm/ProjectForm.schema';

const createProjectQuery = ({
  name,
  managementCompany,
  contractNumber,
  logoMainContractor,
  mainContractor,
  qcCompany,
  qaCompany,
  customerCompany,
}) => ({
  query: `mutation createProject{
    createProject(
      name: "${name}"
      deleted:false
      managementCompany: "${managementCompany}"
      contractNumber: "${contractNumber}"
      logoMainContractor: "${logoMainContractor}"
      mainContractor: "${mainContractor}"
      qcCompany: "${qcCompany}"
      qaCompany: "${qaCompany}"
      customerCompany: "${customerCompany}"
    )
    {
      id
    }
  }`,
});

export default () => {
  const { handleCreateItem, handleBackAction } = useCreateItem({
    createItemQuery: async (values) => {
      const logoMainContractor = values.logoMainContractor ? await toBase64(values.logoMainContractor) : defaultImage;

      api
        .fetchQuery(createProjectQuery({ ...values, logoMainContractor }))
        .then(() => showNotification(dictionaryPhrases.createProjectSuccess));
    },
  });

  return (
    <DashboardContentWrapper header={FORM_HEADER.project.create}>
      <Form handleBackAction={handleBackAction} initialValues={initialValues} onSubmit={handleCreateItem} />
    </DashboardContentWrapper>
  );
};
