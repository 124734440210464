// @flow
export default (fileInput: Blob): Promise<string> => {
  const reader = new FileReader();

  return new Promise<string>((res) => {
    reader.onloadend = () => {
      const result = typeof reader.result === 'string' ? reader.result.split(',')[1] : '';
      res(result);
    };

    reader.readAsDataURL(fileInput);
  });
};
