// @flow
import React from 'react';
import EmailIcon from '@material-ui/icons/Email';
import NavigationIcon from '@material-ui/icons/Navigation';
import ArrowBack from '@material-ui/icons/ArrowBack';
import SignIcon from '@material-ui/icons/BorderColor';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import dictionary from 'constants/dictionary';
import BlueButton from './BlueButton';
import GreenButton from './GreenButton';
import type { ButtonProps } from './DefaultButton';

const styles = {
  changePasswordButton: {
    width: '100%',
    height: '54px',
  },
};

// eslint-disable-next-line flowtype/no-types-missing-file-annotation
export type ButtonType = 'blue' | 'green' | 'default';

export const ChangePasswordButton = (props: ButtonProps) => (
  <BlueButton style={styles.changePasswordButton} {...props}>
    {dictionary.changePasswordHeader}
  </BlueButton>
);
export const EmailButton = (props: ButtonProps) => (
  <BlueButton {...props}>
    <EmailIcon />
    {dictionary.sendEmail}
  </BlueButton>
);
export const ExcelButton = (props: ButtonProps) => (
  <BlueButton {...props}>
    <NavigationIcon />
    {dictionary.excel}
  </BlueButton>
);
export const CancelButton = (props: ButtonProps) => (
  <BlueButton {...props}>
    <ArrowBack />
    {dictionary.cancel}
  </BlueButton>
);
export const PdfButton = (props: ButtonProps) => (
  <BlueButton {...props}>
    <NavigationIcon />
    {dictionary.pdf}
  </BlueButton>
);
export const SaveButton = (props: ButtonProps) => (
  <GreenButton {...props} type="submit">
    {dictionary.save}
  </GreenButton>
);
export const SignButton = ({ signText, ...props }: ButtonProps & { signText?: string }) => (
  <BlueButton {...props}>
    {signText || dictionary.sign}
    <SignIcon />
  </BlueButton>
);
export const AttachButton = (props: ButtonProps) => (
  <BlueButton {...props}>
    {dictionary.attachFile}
    <AttachFileIcon />
  </BlueButton>
);
export const RollBackButton = (props: ButtonProps) => <BlueButton {...props}>{dictionary.rollBack}</BlueButton>;

export { BlueButton, GreenButton };
