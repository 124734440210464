import { compose, withProps } from 'recompose';

import { toOffsettedTimestamp, showNotification, fromUTC } from 'utils/index';
import { withLogProps, withFormPage } from 'hoc/index';
import { FORM_TYPE, FORM_HEADER } from 'constants/formSetup';
import { QC_SUCCESS, QC_SUBMIT } from 'constants/formStatus';
import enabledDependencyForm3 from 'constants/form3Setup';
import dictionaryPhrases from 'constants/dictionary.phrases';

import Form3Content from '../TreatmentOfImpairedForm/TreatmentOfImpairedForm';
import Component from './FormPage';

export const rollBackForm3Query = (formId) => ({
  query: `mutation {
    revertStatusForm(
      formId: "${formId}"
      formType: THIRD_FORM
    )
  }`,
});

const getForm3Query = (id) => ({
  query: `query{
    findAllTreatmentOfImpairedByMonitoringReport(
      finder:{
        id:"${id}"
      }
    ) {
      forms{
        id
        projectId{
          id
          name
        }
        projectName
        contractNumber
        failesSafetyNo
        openingName
        openingDate
        openingHour
        element
        location
        side
        safetyFlawDetails
        testCode
        monitoringDate
        correctiveAction
        telephoneReportingOperationsTeam
        responsiblePerson
        descriptionOfPerformedCorrectiveAction
        remarks
        reportNumber
        endOfTreatmentTime
        treatmentDateEnd
        status
        eventHappeningTime
        eventHappeningDate
        imageBefore
        imageAfter
        finishDate
        repairDateOfDefects
        openClosedStatus
        documents{
          id
          type
          nameWithExtension
          description
        }
      }
      size
    }
  }`,
});

const updateForm3Query = ({
  formId,
  side,
  location,
  element,
  monitoringDate,
  testCode,
  safetyFlawDetails,
  telephoneReportingOperationsTeam,
  remarks,
  correctiveAction,
  responsiblePerson,
  descriptionOfPerformedCorrectiveAction,
  imageBefore,
  imageAfter,
  reportNumber,
  endOfTreatmentTime,
  treatmentDateEnd,
  newFormFields: { durationRequiredForTreatment, actualTreatmentTime, deviationTime },
  repairDateOfDefects,
}) => {
  return {
    query: `mutation {
      updateFormQcTreatmentOfImpairedByMonitoringReport(
        formId:"${formId}"
        ${side ? `side: "${side}"` : ''}
        ${location ? `location:"${location}"` : ''}
        ${element ? `element:"${element}"` : ''}
        ${monitoringDate ? `monitoringDate:"${toOffsettedTimestamp(monitoringDate)}"` : ''}
        ${testCode ? `testCode:"${testCode}"` : ''}
        ${safetyFlawDetails ? `safetyFlawDetails:"${safetyFlawDetails}"` : ''}
        ${
          telephoneReportingOperationsTeam
            ? `telephoneReportingOperationsTeam:"${telephoneReportingOperationsTeam}"`
            : ''
        }
        ${remarks ? `remarks:"${remarks}"` : ''}
        ${imageBefore ? `imageBefore:"${imageBefore}"` : ''}
        ${correctiveAction ? `correctiveAction:"${correctiveAction}"` : ''}
        ${responsiblePerson ? `responsiblePerson:"${responsiblePerson}"` : ''}
        ${
          descriptionOfPerformedCorrectiveAction
            ? `descriptionOfPerformedCorrectiveAction:"${descriptionOfPerformedCorrectiveAction}"`
            : ''
        }
        ${endOfTreatmentTime ? `endOfTreatmentTime:"${toOffsettedTimestamp(endOfTreatmentTime)}"` : ''}
        ${treatmentDateEnd ? `treatmentDateEnd:"${toOffsettedTimestamp(treatmentDateEnd)}"` : ''}
        ${repairDateOfDefects ? `repairDateOfDefects:"${toOffsettedTimestamp(repairDateOfDefects)}"` : ''}
        ${imageAfter ? `imageAfter:"${imageAfter}"` : ''}
        ${reportNumber ? `reportNumber:"${reportNumber}"` : ''}
        durationRequiredForTreatment: "${durationRequiredForTreatment}"
        actualTreatmentTime: "${actualTreatmentTime}"
        deviationTime: "${deviationTime}"
      ) {
        id
      }
    }`,
  };
};

const submitQCStatusForm3Query = (id, confirmationCode) => ({
  query: `mutation {
    submitQCStatusTreatmentOfImpairedByMonitoringReport(
      treatmentOfImpairedByMonitoringReportId:"${id}"
      confirmationCode: "${confirmationCode}"
      ) {
        id
      }
    }`,
});

const submitForemanStatusForm3Query = (id, confirmationCode) => ({
  query: `mutation {
    submitForemanStatusTreatmentOfImpairedByMonitoringReport(
      treatmentOfImpairedByMonitoringReportId:"${id}"
      confirmationCode: "${confirmationCode}"
    ) {
      id
    }
  }`,
});

const enchance = compose(
  // NOTE this is config, so should be before main logic
  withProps(() => {
    return {
      isCreateForm: false,
      headerUpdate: FORM_HEADER.form3.update,
      formType: FORM_TYPE.form3,
      dependencies: enabledDependencyForm3,
      showFiles: true,
      getCurrentPageQuery: (id) => getForm3Query(id),
      getCurrentPageResponseName: 'findAllTreatmentOfImpairedByMonitoringReport',
      formContent: Form3Content,
      rollBackFormQuery: (formId) => rollBackForm3Query(formId),
      manuallyUpdateInitialValues: (values) => ({
        ...values,
        monitoringDate: new Date(values.monitoringDate),
        treatmentDateEnd: new Date(values.treatmentDateEnd),
        endOfTreatmentTime: fromUTC(`${values.treatmentDateEnd} ${values.endOfTreatmentTime}`),
        eventHappeningDate: new Date(values.eventHappeningDate),
        repairDateOfDefects: new Date(values.repairDateOfDefects),
        eventHappeningTime: fromUTC(`${values.eventHappeningDate} ${values.eventHappeningTime}`),
      }),
      updateCurrentPageQuery: () => (values) => updateForm3Query(values),
      signCurrentPageQuery: (props) => {
        return {
          [QC_SUCCESS]: (values) => {
            if (!props.hasFilesDescriptions()) {
              showNotification(dictionaryPhrases.attachFilesWarning, 'warning');

              return Promise.reject();
            }

            props.setIsFetching(true);

            return props.handleUpdateAndSign(values, submitQCStatusForm3Query);
          },
          [QC_SUBMIT]: () => {
            props.setIsFetching(true);

            return props.handleSign(submitForemanStatusForm3Query);
          },
        }[props.initialValues.status];
      },
    };
  }),
  withFormPage,
  withLogProps,
);

export default enchance(Component);
