// @flow
import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  header: {
    textAlign: 'center',
  },
});

type Props = {
  header: React.Node,
  children: React.Node,
};

const DashboardContentWrapper = ({ header, children }: Props) => {
  const classes = useStyles();
  return (
    <>
      <h1 className={classes.header}>{header}</h1>

      {children}
    </>
  );
};

export default DashboardContentWrapper;
