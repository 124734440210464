import * as Yup from 'yup';

export const schemaAll = Yup.object().shape({
  eventHappeningDate: Yup.date().nullable(),
  eventHappeningTime: Yup.date().nullable(),
  element: Yup.string().required('Required'),
  location: Yup.string().required('Required'),
  side: Yup.string().required('Required'),
  safetyFlawDetails: Yup.string().required('Required'),
  testCode: Yup.string().required('Required'),
  monitoringDate: Yup.date().nullable().required('Required'),
  correctiveAction: Yup.string().required('Required'),
  telephoneReportingOperationsTeam: Yup.string().required('Required'),
  responsiblePerson: Yup.string().required('Required'),
  descriptionOfPerformedCorrectiveAction: Yup.string().required('Required'),
  remarks: Yup.string().required('Required'),
  reportNumber: Yup.string().required('Required'),
  endOfTreatmentTime: Yup.date().nullable().required('Required'),
  treatmentDateEnd: Yup.date().nullable().required('Required'),
  repairDateOfDefects: Yup.date().nullable().required('Required'),
});

export const initialValuesCreate = {
  eventHappeningDate: new Date(),
  eventHappeningTime: new Date(),
  element: '',
  location: '',
  side: '',
  safetyFlawDetails: '',
  testCode: '',
  monitoringDate: new Date(),
  correctiveAction: '',
  telephoneReportingOperationsTeam: '',
  responsiblePerson: '',
  descriptionOfPerformedCorrectiveAction: '',
  remarks: '',
  reportNumber: '',
  endOfTreatmentTime: new Date(),
  treatmentDateEnd: new Date(),
  repairDateOfDefects: new Date(),
};

export const initialValuesUpdate = {
  element: '',
  location: '',
  side: '',
  safetyFlawDetails: '',
  testCode: '',
  monitoringDate: new Date(),
  correctiveAction: '',
  telephoneReportingOperationsTeam: '',
  responsiblePerson: '',
  descriptionOfPerformedCorrectiveAction: '',
  remarks: '',
  reportNumber: '',
  endOfTreatmentTime: new Date(),
  treatmentDateEnd: new Date(),
  repairDateOfDefects: new Date(),
};
