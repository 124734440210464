import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import T from 'prop-types';

import { Loading, EmailButton, PdfButton, ExcelButton } from 'components/index';
import ChooseEmails from './ChooseEmails/ChooseEmails.container';

const styles = () => ({
  actions: {
    textAlign: 'right',
    margin: '30px 0',

    '& > button': {
      marginRight: '10px',

      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  main: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  children: {
    flex: '50%',
    textAlign: 'right',
    color: '#1a237e',
    padding: '10px',
  },
});

const FormInfo = ({
  mapInfo,
  handleDownloadFile,
  handleDialogOpen,
  showEmails,
  classes,
  isFetching,
  dialogs,
  projectId,
  ...rest
}) => (
  <>
    {isFetching && <Loading />}

    <div className={classes.main}>
      {mapInfo.map((elem, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <table key={index} className={classes.children}>
          <tbody>
            <tr>
              <td width="70%">
                <h3>{`${elem.value}`}</h3>
              </td>
              <td width="30%">
                <h2>{` - ${elem.name}`}</h2>
              </td>
            </tr>
          </tbody>
        </table>
      ))}
    </div>

    <div className={classes.actions}>
      <PdfButton onClick={() => handleDownloadFile('pdf')} />

      <ExcelButton onClick={() => handleDownloadFile('excel')} />

      {showEmails && <EmailButton onClick={() => handleDialogOpen('isOpenEmails')} />}

      {dialogs.isOpenEmails && <ChooseEmails dialogs={dialogs} projectId={projectId} {...rest} />}
    </div>
  </>
);

FormInfo.propTypes = {
  classes: T.objectOf(T.string).isRequired,
  mapInfo: T.arrayOf(T.any).isRequired,
  handleDownloadFile: T.func.isRequired,
  handleDialogOpen: T.func.isRequired,
  showEmails: T.bool.isRequired,
  isFetching: T.bool.isRequired,
  dialogs: T.objectOf(T.any).isRequired,
  projectId: T.string.isRequired,
};

export default withStyles(styles)(FormInfo);
