// @flow
import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import type { DefaultInputProps } from 'types/DefaultInputProps';

// FIX should be in one place
const theme = createMuiTheme({
  direction: 'rtl', // Both here and <body dir="rtl">
});

const useStyles = makeStyles(() => ({
  textField: {
    width: '100%',
    maxWidth: '300px',
  },
  inputLabel: {
    position: 'absolute',
    bottom: '-15px',
    left: '0',
  },
}));

type Props = DefaultInputProps & {
  children: React.Node,
  onChange: (e: SyntheticEvent<>) => void,
};

const DefaultSelectField = ({ enabledFields, label, name, value, children, onChange, ...props }: Props) => {
  const classes = useStyles();
  const safeValue = value || '';
  return (
    <ThemeProvider theme={theme}>
      <div dir="rtl" className={classes.textField}>
        <TextField
          select
          disabled={enabledFields ? !enabledFields.hasOwnProperty(name) : false}
          id={name}
          name={name}
          label={label}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          SelectProps={{
            value: safeValue,
            onChange,
            ...props,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          FormHelperTextProps={{
            classes: {
              root: classes.inputLabel,
            },
          }}
        >
          {children}
        </TextField>
      </div>
    </ThemeProvider>
  );
};

export default DefaultSelectField;
