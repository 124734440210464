import { compose, withHandlers, withProps } from 'recompose';
import { withRouter } from 'react-router';

import api from 'api/index';
import { withLogProps } from 'hoc/index';
import routes from 'pages/routes';
import { showNotification } from 'utils/index';
import dictionaryPhrases from 'constants/dictionary.phrases';
import { initialValues } from './ChangePasswordForm/ChangePasswordForm.schema';
import Component from './ChangePassword';

const changePasswordQuery = (password) => ({
  query: `mutation changePassword{
    changePassword(
      password:"${password}"
    )
  }`,
});

const enchance = compose(
  withRouter,
  withHandlers({
    handleSubmit: (props) => (values) => {
      api
        .fetchQuery(changePasswordQuery(values.password))
        .then(() => {
          showNotification(dictionaryPhrases.changePassword, 'success');

          api.removeAllMetaData();

          props.history.push(routes.login.path);
        })
        .catch((err) => {
          console.error(err);
        });
    },
  }),
  withProps((props) => {
    return {
      initialValues,
      onSubmit: props.handleSubmit,
      handleBackAction: () => props.history.goBack(),
    };
  }),
  withLogProps,
);

export default enchance(Component);
