import React from 'react';
import Table from '@material-ui/core/Table';
import withStyles from '@material-ui/core/styles/withStyles';

import { CancelButton, SignButton, SaveButton, RollBackButton } from 'components/index';
import SignModal from './SignModal/SignModal.container';

const styles = () => ({
  root: {
    margin: '25px 0 35px',
  },
  button: {
    border: '1px solid #e0e0e0',
    color: 'white',
    background: ' #00ac66',
    textTransform: 'capitalize',
    float: 'right',
  },
});

const FormActions = ({
  classes,
  showSubmit,
  onSubmit,
  showSign,
  code,
  dialogs,
  handleDialogClose,
  newGeneration,
  handleBackAction,
  handleSignForm,
  handleCodeStateChange,
  handleClickSign,
  showRollBack,
  handleRollBack,
  signText,
}) => {
  return (
    <>
      <Table className={classes.root}>
        <tbody>
          <tr>
            <td>
              <CancelButton onClick={handleBackAction} />
            </td>

            {showRollBack && (
              <td>
                <RollBackButton onClick={handleRollBack} />
              </td>
            )}

            {showSubmit && (
              <td>
                <SaveButton onClick={newGeneration ? null : onSubmit} />
              </td>
            )}

            {showSign && (
              <td>
                <SignButton onClick={handleClickSign} signText={signText} />
              </td>
            )}
          </tr>
        </tbody>
      </Table>

      {showSign && (
        <SignModal
          handleDialogClose={handleDialogClose}
          code={code}
          handleCodeStateChange={handleCodeStateChange}
          dialogs={dialogs}
          handleSignForm={handleSignForm}
        />
      )}
    </>
  );
};

export default withStyles(styles)(FormActions);
