// @flow
import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

import SideBar from './SideBar/SideBar.container';
import Header from './Header/Header';
import Footer from './Footer/Footer';

const useStyles = makeStyles(() => ({
  wrapper: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    width: '85%',
    margin: '0 auto',
  },
  main: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: '1',
  },
  content: {
    width: '80%',
    paddingTop: '15px',
    flexGrow: '1',
    display: 'flex',
    flexDirection: 'column',
  },
}));

type Props = {
  children: React.Node,
};

const Layout = ({ children }: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Header />
      <div className={classes.main}>
        <div className={classes.content}>{children}</div>
        <SideBar />
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
