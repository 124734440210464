/* eslint-disable import/no-cycle */
// @flow

import React, { useState } from 'react';
import { useAsyncEffect } from 'use-async-effect';
import { format } from 'date-fns';

import { DashboardContentWrapper } from 'components/index';
import api from 'api/index';
import type { TestCodeWithUnderline } from 'types/testCode';
import type { Record } from 'types/utility';
import { showNotification } from 'utils/index';

import dictionaryPhrases from 'constants/dictionary.phrases';
import dictionary from 'constants/dictionary';

import EmailScheduleTable from './EmailScheduleTable';

const month = {
  JANUARY: 'JANUARY',
  FEBRUARY: 'FEBRUARY',
  MARCH: 'MARCH',
  APRIL: 'APRIL',
  MAY: 'MAY',
  JUNE: 'JUNE',
  JULY: 'JULY',
  AUGUST: 'AUGUST',
  SEPTEMBER: 'SEPTEMBER',
  OCTOBER: 'OCTOBER',
  NOVEMBER: 'NOVEMBER',
  DECEMBER: 'DECEMBER',
};

type Month = $Keys<typeof month>;
type ServerMonthBox = { key: Month, value: boolean };

const defaultMonthPart: Record<Month, boolean> = {
  DECEMBER: false,
  NOVEMBER: false,
  OCTOBER: false,
  SEPTEMBER: false,
  AUGUST: false,
  JULY: false,
  JUNE: false,
  MAY: false,
  APRIL: false,
  MARCH: false,
  FEBRUARY: false,
  JANUARY: false,
};

type AlertFromServer = {
  dayNumber: string,
  dayTime: string | null,
  monthBox: null | ServerMonthBox[],
  monthDay: string | null,
  testCode: TestCodeWithUnderline,
  testCodeDescription: string,
};

type AlertArgument = {
  testCode: TestCodeWithUnderline,
  monthDay: number,
  monthBox: ServerMonthBox[],
  dayTime: number,
};

type FindAllAlertsRes = {
  findAllAlerts: {
    alerts: AlertFromServer[],
  },
};

export type TableRow = {|
  date: number,
  time: Date,
  DECEMBER: boolean,
  NOVEMBER: boolean,
  OCTOBER: boolean,
  SEPTEMBER: boolean,
  AUGUST: boolean,
  JULY: boolean,
  JUNE: boolean,
  MAY: boolean,
  APRIL: boolean,
  MARCH: boolean,
  FEBRUARY: boolean,
  JANUARY: boolean,
  frequancy: string,
  testField: string,
  testCode: TestCodeWithUnderline,
  num: number,
|};

export type Column = {
  Header: string,
  accessor: $Keys<TableRow>,
};

const columns: Column[] = [
  {
    Header: 'Time',
    accessor: 'time',
  },
  {
    Header: 'Date',
    accessor: 'date',
  },
  {
    Header: 'Dec',
    accessor: 'DECEMBER',
  },
  {
    Header: 'Nov',
    accessor: 'NOVEMBER',
  },
  {
    Header: 'Oct',
    accessor: 'OCTOBER',
  },
  {
    Header: 'Sep',
    accessor: 'SEPTEMBER',
  },
  {
    Header: 'Aug',
    accessor: 'AUGUST',
  },
  {
    Header: 'Jul',
    accessor: 'JULY',
  },
  {
    Header: 'Jun',
    accessor: 'JUNE',
  },
  {
    Header: 'May',
    accessor: 'MAY',
  },
  {
    Header: 'Apr',
    accessor: 'APRIL',
  },
  {
    Header: 'Mar',
    accessor: 'MARCH',
  },
  {
    Header: 'Feb',
    accessor: 'FEBRUARY',
  },
  {
    Header: 'Jan',
    accessor: 'JANUARY',
  },
  {
    Header: 'Test field',
    accessor: 'testField',
  },
  {
    Header: 'Test code',
    accessor: 'testCode',
  },
  {
    Header: 'Num',
    accessor: 'num',
  },
];

const getTimeInClientTimezone = (dayTime: string): Date => {
  const dateObj = new Date();

  return new Date(dateObj.setHours(...dayTime.split(':').map(Number)) - dateObj.getTimezoneOffset() * 60 * 1000);
};

const getRowsPart = (rows: AlertArgument[]): string => {
  return rows
    .map(
      (row) => `{
        dayTime: "${row.dayTime}"
        testCode: "${row.testCode}"
        monthDay: "${row.monthDay}"
        monthBox: [${row.monthBox.map(({ key, value }) => `{key: "${key}", value: ${String(value)}}`).join(',')}]
  }`,
    )
    .join(',');
};

const getQueryParamsFromTableData = (tableData: TableRow[]): AlertArgument[] => {
  return tableData.map((tableRow): AlertArgument => {
    const returnObject: AlertArgument = {};

    returnObject.testCode = tableRow.testCode;
    returnObject.monthDay = tableRow.date;
    returnObject.dayTime = tableRow.time.getTime();
    returnObject.monthBox = Object.keys(month).map((monthName) => ({ key: monthName, value: tableRow[monthName] }));

    return returnObject;
  });
};

const mapServerDataToTableData = (alerts: AlertFromServer[]): TableRow[] => {
  return alerts.map((alert) => {
    const checkboxPart: Record<Month, boolean> =
      alert.monthBox === null
        ? defaultMonthPart
        : alert.monthBox.reduce((acc, { key, value }) => ({ ...acc, [(key: string)]: value }), {});

    const tableRow: TableRow = (({
      ...checkboxPart,
      num: alert.dayNumber,
      date: alert.monthDay ?? '1',
      time: alert.dayTime ? getTimeInClientTimezone(alert.dayTime) : getTimeInClientTimezone('00:00'),
      testField: alert.testCodeDescription,
      testCode: alert.testCode,
    }: any): TableRow);

    return tableRow;
  });
};

const findAllAlertsQuery = () => ({
  query: ` query {
    findAllAlerts {
      alerts {
        dayNumber
        dayTime
        testCode
        monthDay
        testCodeDescription
        monthBox{
          key
          value
        }

      }
    }
  }
  `,
});

const updateAlerts = (rows: AlertArgument[]) => ({
  query: `mutation updateAlerts{
    updateAlerts(
      alertsUpdateDtoList: [${getRowsPart(rows)}]
    ) {
      testCode
    }
  }`,
});

export const RowCount = 13;
// NOTE: every cell has its own local state, there is no 1 big state
// use tableDataSnapshot to save data from every cell onChange
// react doesn't track tableDataSnapshot
let tableDataSnapshot = new Array(RowCount).fill((({}: any): TableRow));
export default () => {
  const [initialTableData, setInitialTableData] = useState<TableRow[]>(new Array(RowCount).fill((({}: any): TableRow)));

  const updateTableDataSnapshot = (rowIndex: number, columnId: number, value: any): void => {
    tableDataSnapshot = tableDataSnapshot.map((row, index) => {
      return index === rowIndex
        ? {
            ...tableDataSnapshot[rowIndex],
            [columnId]: value,
          }
        : row;
    });
  };

  const onSubmit = () => {
    api
      .fetchQuery(updateAlerts(getQueryParamsFromTableData(tableDataSnapshot)))
      .then(() => showNotification(dictionaryPhrases.alerlogUpdateSuccess, 'success'));
  };

  useAsyncEffect(async () => {
    const res: FindAllAlertsRes = await api.fetchQuery(findAllAlertsQuery());
    const { alerts } = res.findAllAlerts;

    setInitialTableData(mapServerDataToTableData(alerts));
    tableDataSnapshot = mapServerDataToTableData(alerts);
  }, []);

  return (
    <DashboardContentWrapper header={dictionary.alertLog}>
      <EmailScheduleTable
        onSubmit={onSubmit}
        columns={columns}
        tableData={initialTableData}
        updateTableDataSnapshot={updateTableDataSnapshot}
      />
    </DashboardContentWrapper>
  );
};
