// @flow
import React from 'react';
import Table from '@material-ui/core/Table';
import makeStyles from '@material-ui/core/styles/makeStyles';
// import { makeStyles } from '@material-ui/core/styles';

import { CancelButton, SaveButton } from 'components/index';

const useStyles = makeStyles({
  root: {
    margin: '25px 0 35px',
  },
});

type Props = {
  handleBackAction: () => void,
};

const Actions = ({ handleBackAction }: Props) => {
  const classes = useStyles();
  return (
    <>
      <Table className={classes.root}>
        <tbody>
          <tr>
            <td>
              <CancelButton onClick={handleBackAction} />
            </td>

            <td>
              {/* Save button has type 'submit' and should be used in form (formik) */}
              <SaveButton />
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default Actions;
