import React from 'react';

const styles = {
  wrapper: {
    border: '1px solid #3e7a5a',
    padding: '10px',
    borderRadius: '2px',
    boxShadow: 'rgb(62, 122, 90) 6px 5px 5px 2px',
  },
  logo: {
    width: '200px',
    border: '1px solid #3e7a5a',
    borderRadius: '2px',
  },
};

export default ({ logoMainContractor }) => {
  console.log('logoMainContractor', logoMainContractor);
  return (
    <div style={styles.wrapper}>
      <img
        style={styles.logo}
        src={`data:image/png;base64, ${logoMainContractor}`}
        alt="Project logo"
      />
    </div>
  );
};
