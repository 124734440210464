// @flow
/* eslint-disable no-prototype-builtins */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import type { DefaultInputProps } from 'types/DefaultInputProps';

// FIX should be in one place
const theme = createMuiTheme({
  direction: 'rtl', // Both here and <body dir="rtl">
});

const useStyles = makeStyles(() => ({
  textField: {
    width: '100%',
  },
  inputLabel: {
    position: 'absolute',
    bottom: '-15px',
    left: '0',
  },
}));

type Props = DefaultInputProps & {
  error: any,
};

const DefaultFieldText = ({ enabledFields, label, name, value, error, ...props }: Props) => {
  const classes = useStyles();
  const safeValue = value || '';
  return (
    <ThemeProvider theme={theme}>
      <div dir="rtl" className={classes.textField}>
        <TextField
          disabled={enabledFields ? !enabledFields.hasOwnProperty(name) : false}
          id={name}
          name={name}
          label={label}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={safeValue}
          error={!!error}
          InputLabelProps={{
            shrink: true,
          }}
          FormHelperTextProps={{
            classes: {
              root: classes.inputLabel,
            },
          }}
          {...props}
        />
        {/* <div className={classes.inputLabel}>{touched[name] ? errors[name] : ''}</div> */}
      </div>
    </ThemeProvider>
  );
};

export default DefaultFieldText;
