// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import routes from 'pages/routes';
import { toOffsettedTimestamp, setParams, showNotification } from 'utils/index';
import dictionary from 'constants/dictionary';
import dictionaryPhrases from 'constants/dictionary.phrases';
import { ADMIN, SUPER_ADMIN } from 'constants/global-roles';
import {
  form14StatusAliases,
  OPERATOR_SUCCESS,
  OPERATOR_SUBMIT,
  QC_SUCCESS,
  QC_SUBMIT,
  COMPLETED,
} from 'constants/formStatus';
import dependenciesForm2 from 'constants/form2Setup';
import { useRouting } from 'hooks/useRouting';
import useProjects from 'hooks/useProjects';
import useTable from 'hooks/useTable';
import useRoleContext from 'hooks/useRoleContext';
import api from 'api/index';
import { EditIcon, WatchIcon, DeleteIcon, List } from 'components/index';
import type { Column } from 'types/frontTypes';
import type { FailesSafety } from 'types/ServerTypes/FailesSafety';

const useStyles = makeStyles({
  buttons: {
    whiteSpace: 'nowrap',
    width: 120,
    display: 'flex',
    justifyContent: 'space-around',
  },
});

const deleteForm2Query = (formId) => ({
  query: `mutation deleteFailesSafety{
    deleteFailesSafety(
      formId:"${formId}"
    )
  }`,
});

const getAllForm2Query = ({ projectId, status, failesSafetyNo, startDate, finishDate, pageBy }) => ({
  query: `query{
    findAllFailesSafety(
      finder:{
        ${projectId ? `projectId: "${projectId}"` : ''}
        ${status ? `status: ${status}` : ''}
        ${failesSafetyNo ? `failesSafetyNo: ${failesSafetyNo}` : ''}
        ${startDate ? `startDate: ${toOffsettedTimestamp(startDate)}` : ''}
        ${finishDate ? `finishDate: ${toOffsettedTimestamp(finishDate)}` : ''}
      }
      pageable:{
        sort:{
          column:"failesSafetyNo"
          direction:DESC
        }
        page:{
          number:${pageBy.page}
          size: ${pageBy.rowsPerPage}
        }
      }
    ){
      forms{
        id
        updateAt
        updateBy{
          firstName
          lastName
        }
        createBy{
          firstName,
          lastName
        }
        openingDate
        failesSafetyNo
        location
        safetyFlawDetails
        status
        mainContractor
        contractNumber
        openClosedStatus
      }
      size
    }
  }`,
});
export default () => {
  const { formatRoute } = useRouting();
  const { role } = useRoleContext();
  const { projects, projectId, setProjectId } = useProjects();
  const classes = useStyles();
  const updateAvailable = {
    SUPER_ADMIN: [OPERATOR_SUCCESS, OPERATOR_SUBMIT, QC_SUCCESS, QC_SUBMIT],
    ADMIN: [OPERATOR_SUCCESS, OPERATOR_SUBMIT, QC_SUCCESS, QC_SUBMIT],
    OPERATOR: [OPERATOR_SUCCESS],
    QC: [OPERATOR_SUBMIT, QC_SUCCESS],
    FOREMAN: [QC_SUBMIT],
  }[role];

  const createLink = `${routes.form2Create.path}?${setParams({ projectId })}`;
  const isShowDelete = [ADMIN, SUPER_ADMIN].includes(role);
  const createAvailable = projects.length > 0 && dependenciesForm2[role].createAvailable;

  const {
    data,
    pagination,
    submitFilters,
    popup,
    popup: { openPopover },
  } = useTable({
    getItemsQuery: (values: any) =>
      api
        .fetchQuery(getAllForm2Query({ ...values, projectId }))
        .then(({ findAllFailesSafety: { forms, size } }) => ({ data: forms, size })),
    deleteItemQuery: (id) =>
      api.fetchQuery(deleteForm2Query(id)).then(() => {
        showNotification(dictionaryPhrases.deleteFormSuccess);
      }),
    deps: [projectId],
  });

  const columns: Column<FailesSafety>[] = [
    {
      title: '',
      key: 'action',
      render: (record) => {
        return (
          <div className={classes.buttons}>
            <Link to={formatRoute(routes.form2Page.path, { id: record.id })}>
              {updateAvailable.includes(record.status) ? <EditIcon /> : <WatchIcon />}
            </Link>
            {isShowDelete && <DeleteIcon onClick={openPopover('deleteItem', record)} />}
          </div>
        );
      },
    },
    {
      title: dictionary.step,
      key: 'step',
      render: (record) => dictionary[record.status],
    },
    {
      title: dictionary.status,
      key: 'openClosedStatus',
      render: (record) => dictionary[record.openClosedStatus],
    },
    'updateAt',
    {
      title: dictionary.updateBy,
      key: 'updateBy',
      render: (record) => `${record.updateBy.firstName} ${record.updateBy.lastName}`,
    },
    {
      title: dictionary.createBy,
      key: 'createBy',
      render: (record) => `${record.createBy.firstName} ${record.createBy.lastName}`,
    },
    'safetyFlawDetails',
    'openingDate',
    {
      title: dictionary.locationEclipce,
      key: 'location',
      render: (record) => record.location,
    },
    'contractNumber',
    'mainContractor',
    'failesSafetyNo',
  ];

  const useProjectsData = { projects, projectId, setProjectId };
  const filtering = {
    submitFilters,
    formStatusVariants: ['', OPERATOR_SUCCESS, OPERATOR_SUBMIT, QC_SUCCESS, QC_SUBMIT, COMPLETED],
  };

  return (
    <List
      data={data}
      createLink={createLink}
      columns={columns}
      pagination={pagination}
      useProjectsData={useProjectsData}
      createAvailable={createAvailable}
      popup={popup}
      filtering={filtering}
    />
  );
};
