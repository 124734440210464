import React from 'react';
import T from 'prop-types';

import { DashboardContentWrapper } from 'components/index';
import dictionary from 'constants/dictionary';
import Form from './ChangePasswordForm/ChangePasswordForm';

const ChangePassword = ({ onSubmit, initialValues, handleBackAction }) => {
  return (
    <DashboardContentWrapper header={dictionary.changePasswordHeader}>
      <Form onSubmit={onSubmit} initialValues={initialValues} handleBackAction={handleBackAction} />
    </DashboardContentWrapper>
  );
};

ChangePassword.propTypes = {
  initialValues: T.objectOf(T.string).isRequired,
  onSubmit: T.func.isRequired,
  handleBackAction: T.func.isRequired,
};

export default ChangePassword;
