import { compose, withStateHandlers } from 'recompose';

const enchance = compose(
  withStateHandlers(
    {
      dialogs: {
        isOpenEmails: false,
        isOpenCode: false,
        isOpenDelete: {},
      },
    },
    {
      handleDialogOpen: (state) => (fieldName) => ({
        ...state,
        dialogs: {
          ...state.dialogs,
          [fieldName]: true,
        },
      }),
      handleDialogClose: (state) => (fieldName) => ({
        ...state,
        dialogs: {
          ...state.dialogs,
          [fieldName]: false,
        },
      }),
    },
  ),
);

export default enchance;
