import { compose, withStateHandlers } from 'recompose';

const enchance = compose(
  withStateHandlers(
    {
      projects: [],
      projectId: '',
    },
    {
      onLoadedProjects: (state) => (projects, projectId) => ({
        ...state,
        projects,
        projectId,
      }),
      handleChangeProjectState: (state) => (stateName, value) => ({
        ...state,
        [stateName]: value,
      }),
    },
  ),
);

export default enchance;
