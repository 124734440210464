import { OPERATOR, QC, FOREMAN, ADMIN, SUPER_ADMIN } from 'constants/global-roles';
import dictionary from './dictionary';

export const FORM_TYPE = {
  form1: 'FIRST_FORM',
  form2: 'SECOND_FORM',
  form3: 'THIRD_FORM',
  form4: 'FOURTH_FORM',
};

export const FORM_HEADER = {
  form1: {
    create: dictionary.createHandlingRoadAccident,
    update: dictionary.updateHandlingRoadAccident,
  },
  form2: { create: dictionary.createSafetyTreatment, update: dictionary.updateSafetyTreatment },
  form3: {
    create: dictionary.createTreatmentOfImpairment,
    update: dictionary.updateTreatmentOfImpairment,
  },
  form4: {
    create: dictionary.createHandlingTrafficEvent,
    update: dictionary.updateHandlingTrafficEvent,
  },
  user: { create: dictionary.createUser, update: dictionary.updateUser },
  // TODO add header for project page
  project: { create: dictionary.createProject, update: dictionary.updateProject },
  userPermission: {
    create: dictionary.createUserPermission,
    update: dictionary.updateUserPermission,
  },
  contact: {
    create: dictionary.createContact,
    update: dictionary.updateContact,
  },
};

export const FORM_NAME = {
  form1: dictionary.sidebarHandlingRoadAccident,
  form2: dictionary.sidebarSafetyTreatment,
  form3: dictionary.sidebarTreatmentOfDefectsAfterMonitoring,
  form4: dictionary.sidebarHandlingTrafficEvent,
};
