import React from 'react';

import BlockWrapper from './BlockWrapper/BlockWrapper';

const ContentText = () => (
  <>
    Conditions of Use
    <br />
    <BlockWrapper>
      Welcome to our online store! MYCOMPANY and its associates provide their services to you
      subject to the following conditions. If you visit or shop within this website, you accept
      these conditions. Please read them carefully.
      <br />
      PRIVACY
      <br />
    </BlockWrapper>
    <BlockWrapper>
      Please review our Privacy Notice, which also governs your visit to our website, to understand
      our practices.
      <br />
      ELECTRONIC COMMUNICATIONS
      <br />
    </BlockWrapper>
    <BlockWrapper>
      When you visit MYCOMPANY or send e-mails to us, you are communicating with us electronically.
      You consent to receive communications from us electronically. We will communicate with you by
      e-mail or by posting notices on this site. You agree that all agreements, notices, disclosures
      and other communications that we provide to you electronically satisfy any legal requirement
      that such communications be in writing.
      <br />
      COPYRIGHT
      <br />
    </BlockWrapper>
    <BlockWrapper>
      All content included on this site, such as text, graphics, logos, button icons, images, audio
      clips, digital downloads, data compilations, and software, is the property of MYCOMPANY or its
      content suppliers and protected by international copyright laws. The compilation of all
      content on this site is the exclusive property of MYCOMPANY, with copyright authorship for
      this collection by MYCOMPANY, and protected by international copyright laws.
      <br />
      TRADE MARKS
      <br />
    </BlockWrapper>
    <BlockWrapper>
      MYCOMPANYs trademarks and trade dress may not be used in connection with any product or
      service that is not MYCOMPANYs, in any manner that is likely to cause confusion among
      customers, or in any manner that disparages or discredits MYCOMPANY. All other trademarks not
      owned by MYCOMPANY or its subsidiaries that appear on this site are the property of their
      respective owners, who may or may not be affiliated with, connected to, or sponsored by
      MYCOMPANY or its subsidiaries.
      <br />
      LICENSE AND SITE ACCESS
      <br />
    </BlockWrapper>
    <BlockWrapper>
      MYCOMPANY grants you a limited license to access and make personal use of this site and not to
      download (other than page caching) or modify it, or any portion of it, except with express
      written consent of MYCOMPANY. This license does not include any resale or commercial use of
      this site or its contents: any collection and use of any product listings, descriptions, or
      prices: any derivative use of this site or its contents: any downloading or copying of account
      information for the benefit of another merchant: or any use of data mining, robots, or similar
      data gathering and extraction tools. This site or any portion of this site may not be
      reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any
      commercial purpose without express written consent of MYCOMPANY. You may not frame or utilize
      framing techniques to enclose any trademark, logo, or other proprietary information (including
      images, text, page layout, or form) of MYCOMPANY and our associates without express written
      consent. You may not use any meta tags or any other "hidden text" utilizing MYCOMPANYs name or
      trademarks without the express written consent of MYCOMPANY. Any unauthorized use terminates
      the permission or license granted by MYCOMPANY. You are granted a limited, revocable, and
      nonexclusive right to create a hyperlink to the home page of MYCOMPANY so long as the link
      does not portray MYCOMPANY, its associates, or their products or services in a false,
      misleading, derogatory, or otherwise offensive matter. You may not use any MYCOMPANY logo or
      other proprietary graphic or trademark as part of the link without express written permission.
      <br />
      YOUR MEMBERSHIP ACCOUNT
      <br />
    </BlockWrapper>
    <BlockWrapper>
      If you use this site, you are responsible for maintaining the confidentiality of your account
      and password and for restricting access to your computer, and you agree to accept
      responsibility for all activities that occur under your account or password. If you are under
      18, you may use our website only with involvement of a parent or guardian. MYCOMPANY and its
      associates reserve the right to refuse service, terminate accounts, remove or edit content, or
      cancel orders in their sole discretion.
      <br />
      REVIEWS, COMMENTS, EMAILS, AND OTHER CONTENT
      <br />
    </BlockWrapper>
    <BlockWrapper>
      Visitors may post reviews, comments, and other content: and submit suggestions, ideas,
      comments, questions, or other information, so long as the content is not illegal, obscene,
      threatening, defamatory, invasive of privacy, infringing of intellectual property rights, or
      otherwise injurious to third parties or objectionable and does not consist of or contain
      software viruses, political campaigning, commercial solicitation, chain letters, mass
      mailings, or any form of "spam." You may not use a false e-mail address, impersonate any
      person or entity, or otherwise mislead as to the origin of a card or other content. MYCOMPANY
      reserves the right (but not the obligation) to remove or edit such content, but does not
      regularly review posted content. If you do post content or submit material, and unless we
      indicate otherwise, you grant MYCOMPANY and its associates a nonexclusive, royalty-free,
      perpetual, irrevocable, and fully sublicensable right to use, reproduce, modify, adapt,
      publish, translate, create derivative works from, distribute, and display such content
      throughout the world in any media. You grant MYCOMPANY and its associates and sublicensees the
      right to use the name that you submit in connection with such content, if they choose. You
      represent and warrant that you own or otherwise control all of the rights to the content that
      you post: that the content is accurate: that use of the content you supply does not violate
      this policy and will not cause injury to any person or entity: and that you will indemnify
      MYCOMPANY or its associates for all claims resulting from content you supply. MYCOMPANY has
      the right but not the obligation to monitor and edit or remove any activity or content.
      MYCOMPANY takes no responsibility and assumes no liability for any content posted by you or
      any third party.
      <br />
      RISK OF LOSS
      <br />
    </BlockWrapper>
    <BlockWrapper>
      All items purchased from MYCOMPANY are made pursuant to a shipment contract. This basically
      means that the risk of loss and title for such items pass to you upon our delivery to the
      carrier.
      <br />
      PRODUCT DESCRIPTIONS
      <br />
    </BlockWrapper>
    <BlockWrapper>
      MYCOMPANY and its associates attempt to be as accurate as possible. However, MYCOMPANY does
      not warrant that product descriptions or other content of this site is accurate, complete,
      reliable, current, or error-free. If a product offered by MYCOMPANY itself is not as
      described, your sole remedy is to return it in unused condition.
      <br />
    </BlockWrapper>
    <BlockWrapper>
      DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY THIS SITE IS PROVIDED BY MYCOMPANY ON AN
      "AS IS" AND "AS AVAILABLE" BASIS. MYCOMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY
      KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THIS SITE OR THE INFORMATION, CONTENT,
      MATERIALS, OR PRODUCTS INCLUDED ON THIS SITE. YOU EXPRESSLY AGREE THAT YOUR USE OF THIS SITE
      IS AT YOUR SOLE RISK. TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, MYCOMPANY DISCLAIMS
      ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
      MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. MYCOMPANY DOES NOT WARRANT THAT THIS
      SITE, ITS SERVERS, OR E-MAIL SENT FROM MYCOMPANY ARE FREE OF VIRUSES OR OTHER HARMFUL
      COMPONENTS. MYCOMPANY WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF
      THIS SITE, INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND
      CONSEQUENTIAL DAMAGES. CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR
      THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE
      ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE
      ADDITIONAL RIGHTS.
      <br />
      APPLICABLE LAW
      <br />
    </BlockWrapper>
    <BlockWrapper>
      By visiting MYCOMPANY, you agree that the laws of the state of DEFINE_STATE, DEFINE_COUNTRY,
      without regard to principles of conflict of laws, will govern these Conditions of Use and any
      dispute of any sort that might arise between you and MYCOMPANY or its associates.
      <br />
      DISPUTES
      <br />
    </BlockWrapper>
    <BlockWrapper>
      Any dispute relating in any way to your visit to MYCOMPANY or to products you purchase through
      MYCOMPANY shall be submitted to confidential arbitration in DEFINE_STATE, DEFINE_COUNTRY,
      except that, to the extent you have in any manner violated or threatened to violate MYCOMPANYs
      intellectual property rights, MYCOMPANY may seek injunctive or other appropriate relief in any
      state or federal court in the state of DEFINE_STATE, DEFINE_COUNTRY, and you consent to
      exclusive jurisdiction and venue in such courts. Arbitration under this agreement shall be
      conducted under the rules then prevailing of the American Arbitration Association. The
      arbitrators award shall be binding and may be entered as a judgment in any court of competent
      jurisdiction. To the fullest extent permitted by applicable law, no arbitration under this
      Agreement shall be joined to an arbitration involving any other party subject to this
      Agreement, whether through class arbitration proceedings or otherwise.
      <br />
      SITE POLICIES, MODIFICATION, AND SEVERABILITY
      <br />
    </BlockWrapper>
    <BlockWrapper>
      Please review our other policies, such as our Shipping and Returns policy, posted on this
      site. These policies also govern your visit to MYCOMPANY. We reserve the right to make changes
      to our site, policies, and these Conditions of Use at any time. If any of these conditions
      shall be deemed invalid, void, or for any reason unenforceable, that condition shall be deemed
      severable and shall not affect the validity and enforceability of any remaining condition.
      <br />
    </BlockWrapper>
    -----
    <br />
    Vi-Soft Online SaaS Terms and Conditions
    <br />
    PLEASE READ THE FOLLOWING CAREFULLY BEFORE ACCEPTING THESE TERMS AND REGISTERING FOR, ACCESSING
    AND/OR USING THE VI-SOFT SERVICE.
    <br />
    By selecting the box "I agree", or otherwise accessing and using the Vi-Soft Service (as defined
    below), you acknowledge that you, on your own behalf as an individual and on behalf of your
    employer or another legal entity (collectively "you" or "your"), have read and understood and
    agree to comply with: (i) the terms and conditions set forth below (the "Terms"); (ii) the terms
    of Microsoft Azure Agreement available at
    https://azure.microsoft.com/en-us/support/legal/subscription-agreement-nov-2014; (iii) the
    Oracle Websites Terms of Use available at www.oracle.com/legal/terms.html, and are entering into
    a binding legal agreement with Vi (QC) Soft Ltd. ("Vi-Soft", "us", "we" or "our"). You further
    represent and warrant that you are at least 18 years old and, if you are entering into these
    Terms on behalf of your employer or other legal entity, that you have full authority to bind
    said employer or other legal entity to these Terms. If you do not agree to comply with and be
    bound by any of the abovementioned terms, or have authority to bind your employer or other legal
    entity, please do not accept these Terms or access or use the Service. You hereby waive any
    applicable rights to require an original (non-electronic) signature or delivery or retention of
    non-electronic records, to the extent not prohibited under applicable law.
    <br />
    1. Description of the Service.
    <br />
    1.1. The license to Vi-Soft’s proprietary generic cloud software for construction and quality
    control management and monitoring (the "Service") is made available to you on a
    software-as-a-service (SaaS) basis through a web portal on our website www.visoft-eng.com (the
    "Site"), and includes: (i) the software that you access via the Site; (ii) the products,
    services, and features made available or provided to you by Vi-Soft in connection with the
    Service; and (iii) the content, text, documents, descriptions, products, graphics, photos,
    sounds, videos, interactive features, and the trademarks, service marks and logos ("Marks"),
    contained in or made available through the Service.
    <br />
    2. Subscription to the Service. Subject to your compliance with the terms of the Software
    License and Maintenance Agreement signed between you and Vi-Soft as a precondition for providing
    you the access to the Services (the “Software License Agreement”), the applicable Order Form (as
    defined below), and these Terms, and the full payment of the applicable fees associated
    therewith, Vi-Soft hereby grants you, and you accept, a non-exclusive, non-transferable,
    non-sublicensable, and fully revocable right to access and use the Service during the Term
    (defined below) for your internal purposes only. To the extent applicable, your subscription to
    the Service is limited to the number of your end users designated in the Order Form ("End
    Users"). "Order Form" means Vi-Soft’s then current order form that you entered into with
    Vi-Soft, for the purpose of purchasing the software license and registering for the Service.
    <br />
    3. Third Party License. Vi-Soft’s Services were developed on the basis of Oracle program which
    is the source of the Services. Accordingly, by accepting this Terms you acknowledged and agree
    that the license is nonexclusive nontransferable limited license, and that you agree to use the
    Services in full compliance with the Oracle Technology Network Developer License Terms for
    Oracle Database Express Edition, also available on
    http://www.oracle.com/technetwork/licenses/database-11g-express-license-459621.html.
    <br />
    4. Restrictions on Use. Unless and to such extent permitted pursuant to the Software License
    Agreement, you must not, and shall not allow a permitted End User or any third party to: (i)
    give, sell, rent, lease, timeshare, sublicense, disclose, publish, assign, market, transfer or
    distribute any portion of the Service to any third party, including, but not limited to your
    affiliates, or use the Service in any service bureau arrangement; (ii) circumvent, disable or
    otherwise interfere with security-related features of the Service or features that prevent or
    restrict use or copying of any content or that enforce limitations on use of the Service; (iii)
    reverse engineer, decompile or disassemble the Service or any components thereof; (iv) modify,
    translate, patch, alter, change or create any derivative works of the Service, or any part
    thereof; (v) use any robot, spider, scraper, or other automated means to access the Service for
    any purpose; (vi) take any action that imposes or may impose at Vi-Soft`s sole discretion, an
    unreasonable or disproportionately large load on the Vi-Soft infrastructure; (vii) interfere or
    attempt to interfere with the integrity or proper working of the Service, or any related
    activities; (viii) remove, deface, obscure, or alter Vi-Soft`s or any third party`s copyright
    notices, trademarks, or other proprietary rights affixed to or provided as part of the Service,
    or use or display logos of the Service differing from Vi-Soft’s own without Vi-Soft’s prior
    written approval; (ix) use the Service to develop a competing service or product; (x) use the
    Service to send unsolicited or unauthorized commercial communications; and/or (xi) use the
    Service in any unlawful manner or in breach of the Software License Agreement or these Terms.
    You agree to remain liable to Vi-Soft for any act or omission of an End User that would
    constitute a breach of either the Software License Agreement, or these Terms, if such acts or
    omissions were by you.
    <br />
    5. Account. In order to use the Service, you have to create an account for which you have been
    provided with username and password ("Account"). You must not allow anyone other yourself or a
    permitted End User to access and use your Account. You acknowledge and agree (i) not to exceed
    the aggregate number of authorized End Users designated in the Order Form unless you first
    notify us in writing and pay Vi-Soft the required additional subscription fees; (ii) to provide
    accurate and complete Account and login information, as shall be required for the purpose of
    establishing the Account; (iii) to keep, and ensure that End Users keep, all Account login
    details and passwords secure at all times; (iv) to remain solely responsible and liable for the
    activity that occurs in connection with your Account, including any electronic signatures and/or
    electronic approvals made by you in your Account, either by checking any acceptance box,
    uploading any scanned signatures or otherwise (v) that the login details for each End User may
    only be used by that End User, and that multiple people may not share the same login details;
    and (vi) to promptly notify Vi-Soft in writing if you become aware of any unauthorized access or
    use of your Account or the Service. You may cancel your Account at any time by sending a
    cancellation request to us at support@visoft-eng.com, in which case we will use commercially
    reasonable efforts to respond within a reasonable time.
    <br />
    6 Our Privacy Policy. You acknowledge and agree that your use of the Service, including, without
    limitation, any information transmitted to or stored by Vi-Soft, is governed by the Vi-Soft
    Privacy Policy at
    http://visoft-eng.com/ords/apex_util.get_blob?s=4950969331637=101=348539299570205454=360=1==K9bwkpdU7--BQcQDtns977gDL4o=CR
    ("Privacy Policy").
    <br />
    7. Title. Title, interest and full, exclusive ownership rights of the Service (and any parts
    thereof), and all reproductions, corrections, modifications, enhancements and improvements, and
    all related patent rights, copyrights, trade secrets, trademarks, service marks, design rights,
    related goodwill, including data related your usage thereof, and Vi-Soft`s intellectual
    property, and any rights therein not explicitly granted to you hereunder, are reserved to and
    shall remain solely and exclusively proprietary to Vi-Soft (or its affiliated companies or third
    party licensors). “Vi-Soft”, the Vi-Soft Logo, and other marks whether such were registered or
    not, are Marks of Vi-Soft or its affiliates.
    <br />
    8. Term. These Terms shall become effective on the earlier of: (i) the date that you commence
    access to or use of the Service; or (ii) the date that we receive payment of any applicable
    Service subscription fee, and shall continue until expiration of the subscription period as
    defined pursuant to the Software License Agreement (the "Initial Term"). Thereafter, pursuant to
    the terms of the Software License Agreement, and subject to your payment of any applicable
    Service subscription fees, these Terms shall automatically renew for successive subscription
    periods (each a "Renewal Term", and together with the Initial Term, the "Term"), or until these
    Terms are terminated in accordance with the section herein entitled `Termination`. If you
    continue to use the Service past any subscription period renewal date, you shall be deemed to
    have renewed these Terms for the corresponding Renewal Term.
    <br />
    9. We may offer a free, no-obligations trial period of the Service ("Trial Period"). The Trial
    Period, if any, shall come commence on the date on which you have been granted with the access
    to the Service and will conclude at the end of the trial period displayed on the Site, or sooner
    if: (i) you upgrade your Account by beginning to pay the applicable fees for the Service, or
    (ii) your use of the Service is terminated in accordance with these Terms. You acknowledge and
    agree that these Terms are applicable and binding upon you during the Trial Period and that
    Vi-Soft: (i) does not make any commitments in connection with the Service or any feature during
    the Trial Period; and (ii) may send you, subject to your opting out, communications and other
    notices about the Service to your email address. We reserve the right to modify, cancel and/or
    limit this Trial Period offer at any time.
    <br />
    10. Termination. Without derogating from your obligation to pay the subscription fees as set
    forth pursuant to the Software License Agreement and any applicable Order Form, you may
    terminate these Terms at any time by cancelling your Account. We may stop to provide the Service
    to you at any time, including but not limited to, in the event that you breach the terms of the
    Software License Agreement, or these Terms. Without derogating the generality of the foregoing,
    we may suspend your use of the Services, at any time, if: (i) we have a reasonable need to
    prevent unauthorized access to the Software and any data therein; (ii) you fail to respond to
    any claim brought by us pursuant to the Software License Agreement, these Terms, the applicable
    Order Form or the Privacy Policy; (iii) you do not pay amounts due to us pursuant to the
    applicable Order Form; or (iv) you do not comply with the terms of the Software License
    Agreement, these Terms, the applicable Order Form or our Privacy Policy.
    <br />
    11. Effect of Termination. Upon termination of the Software License Agreement, you will lose all
    access to the Service and to any Data that we may be storing on your behalf. It is your
    responsibility to download your Data prior to canceling your Account or any termination of these
    Terms. Notwithstanding the foregoing, for a period of forty-five (45) days from the effective
    date of termination of the Software License Agreement we will provide you, upon your written
    request, with the Data, or with a reasonable opportunity to download your Data, at a time
    nominated by us. We reserve the right to permanently delete from our (or from our third party
    service provider`s) servers any Data that may be contained in your Account at any time following
    said forty-five (45) days period. We do not accept any liability for any termination of the
    Service or Data that is deleted in connection thereto. Sections ‎3, ‎6, ‎10, ‎12, ‎13, ‎14, ‎18
    and ‎19, shall survive termination of these Terms.
    <br />
    12. Fees.
    <br />
    12.1. Your access to and use of the Service is subject to up-front payment in full of the
    subscription fees set forth in the applicable Order Form. You acknowledge that the subscription
    fee during any Renewal Term will, unless set forth otherwise in the Software License Agreement
    or in the Order Form, be determined by Vi-Soft’s then-current subscription fees.
    <br />
    12.2. All fees are stated are non-refundable and are exclusive of all taxes, levies, or duties,
    which are your responsibility. The payment methods are set forth pursuant to the applicable
    Order Form and the Software License Agreement.
    <br />
    13. Warranty Disclaimer. The Service is provided on an "AS IS", and "AS AVAILABLE" basis, and
    without warranties of any kind either express or implied. You assume all responsibility for the
    selection of the Service to achieve your intended results. VI-SOFT HEREBY DISCLAIMS ALL
    WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED, IMPLIED WARRANTIES OF
    MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. VI-SOFT DOES NOT
    WARRANT THAT THE SERVICE WILL BE UNINTERRUPTED, ERROR FREE, OR THAT DEFECTS WILL BE CORRECTED.
    VI-SOFT DOES NOT OFFER A WARRANTY OR MAKE ANY REPRESENTATION REGARDING ANY CONTENT, INFORMATION,
    OR RESULTS THAT YOU OBTAIN THROUGH THE SERVICE. YOUR USE OF AND RELIANCE UPON THE SERVICE
    CONTENT AND YOUR DATA AND ANY MARKETPLACE MATERIALS IS ENTIRELY AT YOUR SOLE DISCRETION AND
    RISK, AND VI-SOFT SHALL HAVE NO RESPONSIBILITY OR LIABILITY WHATSOEVER TO YOU IN CONNECTION WITH
    ANY OF THE FOREGOING. YOU AGREE THAT WE WILL NOT BE HELD RESPONSIBLE FOR ANY CONSEQUENCES THAT
    MAY RESULT FROM TECHNICAL PROBLEMS INCLUDING WITHOUT LIMITATION IN CONNECTION WITH THE INTERNET
    (SUCH AS SLOW CONNECTIONS, TRAFFIC CONGESTION OR OVERLOAD OF OUR OR OTHER SERVERS) OR ANY
    TELECOMMUNICATIONS OR INTERNET PROVIDERS. Applicable law may not allow the exclusion of certain
    warranties, so to that extent such exclusions may not apply.
    <br />
    14. Limitation of Liability. TO THE FULLEST EXTENT PERMISSIBLE BY LAW, VI-SOFT SHALL NOT BE
    LIABLE FOR ANY INDIRECT, EXEMPLARY, SPECIAL, CONSEQUENTIAL, OR INCIDENTAL DAMAGES OF ANY KIND
    (INCLUDING WITHOUT LIMITATION LOST OF PROFITS OR REVENUE) ARISING OUT OF THESE TERMS OR IN
    CONNECTION WITH THE USE OF OR INABILITY TO USE THE SERVICE, EVEN IF VI-SOFT HAS BEEN ADVISED OF
    THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL THE AGGREGATE LIABILITY OF VI-SOFT FOR ANY
    DAMAGES UNDER THESE TERMS OR IN CONNECTION WITH THE USE OF OR THE INABILITY TO USE THE SERVICE
    EXCEED THE TOTAL AMOUNT OF SUBSCRIPTION FEES PAID BY YOU FOR THE SERVICE DURING THE SIX (6)
    MONTHS PRIOR TO BRINGING THE CLAIM.
    <br />
    15. Indemnification. You agree to defend, indemnify and hold harmless Vi-Soft and our affiliates
    and our respective officers, directors, agents, consultants and employees from any third party
    claims, damages, liabilities, costs, and expenses (including reasonable attorney’s fees) arising
    from: (i) your use of the Service; and/or (ii) your breach of these Terms.
    <br />
    16. Disclosure. We reserve the right to access, read, preserve, and disclose any information
    that we obtain in connection with the Service as we reasonably believe necessary to: (i) satisfy
    any applicable law, regulation, legal process, subpoena or governmental request, (ii) enforce
    the terms of the Software License Agreement, and these Terms, including to investigate potential
    violations thereof, (iii) detect, prevent, or otherwise address fraud, security or technical
    issues, (iv) respond to your support requests, and/or (v) protect the rights, property or safety
    of Vi-Soft, its users or the public.
    <br />
    17. Assignment. These Terms, and any rights granted hereunder, may not be transferred or
    assigned by you but may be assigned by V-Soft without restriction.
    <br />
    18. Modifications. We reserve the right, at our discretion, to modify or change these Terms from
    time to time, at any time, without the need to obtain your consent or signature. Such
    modification or changes will become effective within ten (10) days following our sending a
    notice thereof to you or otherwise posting the revised Terms on the Site, and your continued use
    of the Service thereafter means that you accept those changes.
    <br />
    19. Export Control
    <br />
    The Services are subject to the export laws and regulation of the U.S. some other relevant local
    export laws and regulations which applies to the Software. You hereby agree to comply with all
    applicable laws and regulations, including the U.S. Export Administration Regulations, the
    International Traffic in Arms Regulations, and the end-user, end use and destination restriction
    issued by the U.S and other applicable governments (and including all “deemed export” and
    “deemed re-export” regulations) (“Export Laws”). You further agree that no product, material,
    data, information, program, documentation resulting from or directly related to the Services,
    will be directly or indirectly used for any purpose prohibited by the Export Laws or exported in
    violation of the Export Laws.
    <br />
    20. Governing Law and Disputes.
    <br />
    20.1. These Terms shall be governed by the laws of the State of Israel, without reference to its
    conflict of laws rules.
    <br />
    20.2. The exclusive jurisdiction and venue for all disputes hereunder shall be the courts
    located in Tel Aviv-Yaffo, and each party hereby irrevocably consents to the jurisdiction of
    such courts. Application of the United Nations Convention on Contracts for the International
    Sale of Goods and the Uniform Computer Information Transaction Act are excluded from these
    Terms. Notwithstanding the foregoing, Vi-Soft reserves the right to seek injunctive relief in
    any court of competent jurisdiction.
    <br />
    21. General. These Terms, the Software License Agreement’s terms, the applicable Order Form and
    the Privacy Policy, represent the complete agreement concerning the Service between you and
    Vi-Soft and supersede all prior agreements and representations related to the subject matter
    hereof. To the extent of any inconsistency or conflict between these Terms and an Order Form or
    the Software License Agreement, these Terms shall prevail. Section headings are provided for
    convenience only and have no substantive effect on construction. Except for your obligation to
    pay Vi-Soft, neither party shall be liable for any failure to perform due to causes beyond its
    reasonable control. Nothing herein shall be construed to create any employment relationship,
    partnership, joint venture or agency relationship or to authorize any party to enter into any
    commitment or agreement binding on the other party. If any provision of these Terms is held to
    be unenforceable, these Terms shall be construed without such provision. The failure by a party
    to exercise any right hereunder shall not operate as a waiver of such party`s right to exercise
    such right or any other right in the future.
    <br />
    22. Copyright/Trademark and Apache License
    <br />
    22.1. Copyright © 2017, Vi (QC) Soft and/or its affiliates. All rights reserved.
    <br />
    22.2. Vi-Soft is a trademark of Vi (QC) Soft and/or its affiliates. Other names appearing on the
    Site may be trademarks of their respective owners. Any unauthorized copy, distribution,
    modification, public display or performance of copyrighted works may be an infringement of the
    rights of the copyright owner. You agree that you will not use the Site and the Software to
    infringe Vi-Soft’s Intellectual Property rights or the Intellectual Property rights of others.
    You may not remove, deface, or overprint any notice of copyright, trademark, tradename, logo, or
    any other notice and/or claim for ownership from any originals or copies of Vi-Soft Software,
    any third party software, or any products or content you have access on or through the Site.
    <br />
    22.3. This Licensed under the Apache License, Version 2.0 (the “Apache License”); you may not
    use this file except in compliance with the License. You may obtain a copy of the License at
    http://www.apache.org/licenses/LICENSE-2.0.
    <br />
    22.4. Unless required by applicable law or agreed to in writing, software distributed under the
    License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
    express or implied. See the License for the specific language governing permissions and
    limitations under the License.
    <br />
  </>
);

ContentText.propTypes = {};

export default ContentText;
