export default {
  deleteModalHeader: 'Do you really want to delete element?',
  resetPasswordSuccess: 'New generated password has been successfully sended to user email!',
  changePassword: 'Your password has been successfully changed!',
  sendEmailSuccess: 'The emails has been successfully sended!',

  createUserSuccess: 'User has been successfully created!',
  updateUserSuccess: 'User has been successfully updated!',
  deleteUserSuccess: 'User has been successfully deleted!',
  recoveryUserSuccess: 'User has been successfully recovered!',

  createUserPermissionSuccess: 'User permission has been successfully created!',
  updateUserPermissionSuccess: 'User permission has been successfully updated!',
  deleteUserPermissionSuccess: 'User permission has been successfully deleted!',

  createProjectSuccess: 'Project has been successfully created!',
  updateProjectSuccess: 'Project has been successfully upfated!',
  deleteProjectSuccess: 'Project has been successfully deleted!',

  createContactSuccess: 'Contact has been successfully created!',
  updateContactSuccess: 'Contact has been successfully updated!',
  deleteContactSuccess: 'Contact has been successfully deleted!',

  createFormSuccess: 'The form has been successfully created!',
  updateFormSuccess: 'The form has been successfully updated!',
  deleteFormSuccess: 'The form has been successfully deleted!',
  signFormSuccess: 'The form has been successfully signed!',
  attachFilesWarning: 'Please attach required files',
  descriptionsWarning: ' Files without description are not allowed. Please add description to each file.',
  emailMessagePlaceholder: 'הקלד את ההודעה לשליחה',
  emptySignCodeWarning: 'Empty code is not allowed',
  signCodePlaceholder: 'Type your code',

  rollBackFormSuccess: 'The form has been successfully rolled back to previous state!',

  alerlogUpdateSuccess: 'Alert log table has been successfully updated!',
};
