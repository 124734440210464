import { compose, withHandlers, withState, withStateHandlers } from 'recompose';
import { createInputFile } from 'utils/index';

const enchance = compose(
  withState('newFiles', 'setNewFiles', []),
  withStateHandlers(
    {
      imageBefore: null,
      imageAfter: null,
    },
    {
      handleImagesChange: (state) => (event, fieldName) => {
        const newFieldState = {
          ...state[fieldName],
          name: event.target.files[0].name,
          value: event.target.files[0],
        };
        return { ...state, [fieldName]: newFieldState };
      },
      setImagesState: () => (newFieldsState) => newFieldsState,
    },
  ),
  withHandlers({
    handleAddNewFile: (props) => () => {
      props.setNewFiles(props.newFiles.concat(createInputFile('', props.newFiles.length)));
    },
    handleNewDeleteFile: (props) => (index) => {
      props.setNewFiles([...props.newFiles.slice(0, index), ...props.newFiles.slice(index + 1)]);
    },
    handleNewFileChange: (props) => (event, index) => {
      const newFilesCopy = [...props.newFiles];
      newFilesCopy[index].nameWithExtension = event.target.files[0].name;
      newFilesCopy[index].value = event.target.files[0];

      props.setNewFiles(newFilesCopy);
    },
    handleNewFileDescriptionChange: (props) => (event, index) => {
      const newFilesCopy = [...props.newFiles];
      newFilesCopy[index].description = event.target.value;

      props.setNewFiles(newFilesCopy);
    },
  }),
);

export default enchance;
