// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import routes from 'pages/routes';
import { showNotification } from 'utils/index';
import dictionary from 'constants/dictionary';
import dictionaryPhrases from 'constants/dictionary.phrases';
import { useRouting } from 'hooks/useRouting';
import useTable from 'hooks/useTable';
import api from 'api/index';
import { Link } from 'react-router-dom';
import { EditIcon, DeleteIcon, List } from 'components/index';
import type { Column } from 'types/frontTypes';
import type { Project } from 'types/ServerTypes/Project';

const deleteProjectQuery = (id) => ({
  query: `mutation deleteProject{
		deleteProject(
			id:"${id}"
			)
    }`,
});

const getAllProjectsQuery = () => ({
  query: `query findAllProject{
    findAllProject{
      id
      number
      createdAt
      name
      updateBy{
        firstName,
        lastName
      }
      updateAt
      deleted
    }
  }`,
});

const useStyles = makeStyles({
  buttons: {
    whiteSpace: 'nowrap',
    width: 120,
    display: 'flex',
    justifyContent: 'space-around',
  },
});

export default () => {
  const { formatRoute } = useRouting();
  const classes = useStyles();

  const createLink = routes.projectCreate.path;
  const createAvailable = true;

  const {
    data,
    pagination,
    popup,
    popup: { openPopover },
  } = useTable({
    getItemsQuery: () =>
      api
        .fetchQuery(getAllProjectsQuery())
        .then(({ findAllProject }) => ({ data: findAllProject, size: findAllProject.length })),
    deleteItemQuery: (id) =>
      api.fetchQuery(deleteProjectQuery(id)).then(() => {
        showNotification(dictionaryPhrases.deleteProjectSuccess);
      }),
    isServerPagination: false,
  });

  const columns: Column<Project>[] = [
    {
      title: '',
      key: 'action',
      render: (record) => {
        return (
          <div className={classes.buttons}>
            <Link to={formatRoute(routes.projectPage.path, { id: record.id })}>
              <EditIcon />
            </Link>
            <DeleteIcon onClick={openPopover('deleteItem', record)} />
          </div>
        );
      },
    },
    'number',
    'name',
    'updateAt',
    {
      title: dictionary.updateBy,
      key: 'updateBy',
      render: (record) => `${record.updateBy.firstName} ${record.updateBy.lastName}`,
    },
  ];

  const useProjectsData = null;
  const filtering = null;

  return (
    <List
      data={data}
      createLink={createLink}
      createAvailable={createAvailable}
      columns={columns}
      pagination={pagination}
      popup={popup}
      useProjectsData={useProjectsData}
      filtering={filtering}
    />
  );
};
