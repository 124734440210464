import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    width: '100%',
    marginTop: 0,
    marginBottom: '40px',
    overflowX: 'auto',
  },
  table: {
    borderCollapse: 'collapse',
  },
  tr: {
    border: '1px solid #e0e0e0 !important',
  },
  ceil: {
    padding: 0,
    border: '1px solid #e0e0e0',
    textAlign: 'center',
  },
  inputFileCeil: {
    width: '270px',
    textAlign: 'center',
  },
  deleteCeil: {
    width: '50px',
    padding: '10px 0 !important',
    textAlign: 'center !important',
  },
  deleteIcon: {
    cursor: 'pointer',
  },
  fileLink: {
    color: 'blue',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));
