import { compose, withHandlers, withState } from 'recompose';
import { withRouter } from 'react-router-dom';

import api from 'api/index';
import routes from 'pages/routes';
import { showNotification } from 'utils';
import TermsAndConditions from './TermsAndConditions';

const setAgreementQuery = () => ({
  query: `mutation setAgreement{
    setAgreement
  }`,
});

const enchance = compose(
  withRouter,
  withState('agreementValue', 'setAgreementValue', 'notAgree'),
  withHandlers({
    handleCancelAgreement: (props) => () => {
      showNotification('You should accept terms and conditions to continue');
      props.handleCloseTerms();
      api.removeAllMetaData();
    },
    handleApplyAgreement: (props) => () => {
      api
        .fetchQuery(setAgreementQuery())
        .then(() => {
          props.handleCloseTerms();
          props.history.push(routes.dashboard.path);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    handleChangeAgreement: (props) => ({ target }) => {
      props.setAgreementValue(target.value);
    },
  }),
);

export default enchance(TermsAndConditions);
