import React from 'react';
import T from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  blockWrapper: {
    display: 'block',
    marginTop: '10px',
    marginBottom: '10px',
  },
});

const BlockWrapper = ({ children, classes }) => (
  <span className={classes.blockWrapper}>{children.map((child) => child)}</span>
);

BlockWrapper.propTypes = {
  children: T.node.isRequired,
  classes: T.objectOf(T.any).isRequired,
};

export default withStyles(styles)(BlockWrapper);
