// @flow
import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import type { MenuTab } from 'constants/initSideBar';

const useStyles = makeStyles({
  sidebarMenuHref: {
    display: 'block',
    padding: '12px 0 13px 0',
    textDecoration: 'none',
    fontSize: '12px',
    color: '#000',
    textAlign: 'right',

    '&:hover': {
      color: 'white',
      backgroundColor: '#00ac66',
      marginLeft: '-10px',
      paddingRight: '10px',
    },
  },
  sidebar: {
    width: '20%',
    maxWidth: '275px',
    padding: '0 24px',
    overflow: 'hidden',
    textAlign: 'right',
  },
  active: {
    color: 'white !important',
    backgroundColor: '#00ac66',
    marginLeft: '-10px',
    paddingRight: '10px',
  },
  sidebarListMenu: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
  sidebarListItem: {
    padding: '30px 0 10px',
    borderTop: '1px solid #ededed',
  },
  aloneListItem: {
    padding: '10px 0 10px',
    borderTop: '1px solid #ededed',
  },
  title: {
    display: 'block',
    textTransform: 'uppercase',
    fontSize: '13px',
    margin: '0 0 10px',
    letterSpacing: '.15em',
    textAlign: 'right',
  },
  sidebarMenu: {
    lineHeight: '1.3',
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
});

type Props = {
  menu: MenuTab[],
};

const SideBar = ({ menu }: Props) => {
  const classes = useStyles();
  return (
    <aside className={classes.sidebar}>
      <ul className={classes.sidebarListMenu}>
        {menu.map((menuGroup, groupIndex) => {
          if (menuGroup.hasAccess) {
            return (
              <li // eslint-disable-next-line react/no-array-index-key
                key={groupIndex}
                className={classNames(menuGroup.name ? classes.sidebarListItem : classes.aloneListItem)}
              >
                {menuGroup.name && <strong className={classes.title}>{menuGroup.name}</strong>}
                <ul className={classes.sidebarMenu}>
                  {menuGroup.tabs.map((tab, tabIndex) => {
                    if (tab.hasAccess) {
                      return (
                        // eslint-disable-next-line react/no-array-index-key
                        <li key={tabIndex}>
                          <NavLink className={classes.sidebarMenuHref} activeClassName={classes.active} to={tab.route}>
                            {tab.name}
                          </NavLink>
                        </li>
                      );
                    }

                    return null;
                  })}
                </ul>
              </li>
            );
          }

          return null;
        })}
      </ul>
    </aside>
  );
};

export default SideBar;
