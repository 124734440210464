// @flow
import { useState } from 'react';
import { createInputFile } from 'utils/index';

// withStateHandlers(
//   {
//     imageBefore: null,
//     imageAfter: null,
//   },
//   {
//     handleImagesChange: (state) => (event, fieldName) => {
//       const newFieldState = {
//         ...state[fieldName],
//         name: event.target.files[0].name,
//         value: event.target.files[0],
//       };
//       return { ...state, [fieldName]: newFieldState };
//     },
//     setImagesState: () => (newFieldsState) => newFieldsState,
//   },
// ),

type NewFile = {
  nameWithExtension: string,
  value: File,
  description: string,
};

export default () => {
  const [newFiles, setNewFiles] = useState<NewFile[]>([]);

  const handleAddNewFile = (): void => {
    setNewFiles(newFiles.concat(createInputFile('', newFiles.length)));
  };
  const handleNewDeleteFile = (index: number): void => {
    setNewFiles([...newFiles.slice(0, index), ...newFiles.slice(index + 1)]);
  };
  const handleNewFileChange = (
    {
      target: {
        files: [file],
      },
    }: SyntheticInputEvent<HTMLInputElement>,
    index: number,
  ) => {
    const newFilesCopy = [...newFiles];
    newFilesCopy[index].nameWithExtension = file.name;
    newFilesCopy[index].value = file;

    setNewFiles(newFilesCopy);
  };
  const handleNewFileDescriptionChange = (event: SyntheticInputEvent<HTMLInputElement>, index: number) => {
    const newFilesCopy = [...newFiles];
    newFilesCopy[index].description = event.target.value;

    setNewFiles(newFilesCopy);
  };

  return {
    newFiles,
    handleAddNewFile,
    handleNewDeleteFile,
    handleNewFileChange,
    handleNewFileDescriptionChange,
  };
};
