// @flow
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import type { FieldProps } from 'formik';

const useStyles = makeStyles(() => ({
  buttonLable: {
    width: '200px',
    height: '56px',
    borderRadius: '4px',
    padding: '0 10px',
    background: '#fff',
    border: '1px solid #c4c4c4',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'black',
    cursor: 'pointer',
    '&:hover': {
      border: '1px solid rgb(33, 33, 33)',
    },
    textAlign: 'center',
    margin: '16px 0 8px 0',
  },
  inputFile: {
    display: 'none',
    size: '60',
  },
}));

type Props = FieldProps<any> & {
  label: string,
};

const File = ({ field, form, form: { setFieldValue }, label, ...props }: Props) => {
  const classes = useStyles();
  const { name: fieldName } = field;
  // NOTE: needed to defend from base64 initial value
  const fileName = typeof field.value === 'object' ? `${label} ${field.value.name}` : `${label} Choose file`;
  return (
    <React.Fragment key={fieldName}>
      <label id="id" htmlFor={fieldName} className={classes.buttonLable}>
        {fileName}
        <input
          type="file"
          name={fieldName}
          id={fieldName}
          className={classes.inputFile}
          onChange={(e) => {
            if (e.target.files.length > 0) {
              setFieldValue(fieldName, e.target.files[0]);
            }
          }}
          {...props}
        />
      </label>
    </React.Fragment>
  );
};

export default File;
