import { QC_SUCCESS, QC_SUBMIT, COMPLETED } from 'constants/formStatus';
import { ADMIN, SUPER_ADMIN, QC, FOREMAN, OPERATOR } from 'constants/global-roles';
import {
  initialValuesCreate,
  initialValuesUpdate,
  schemaAll,
} from 'pages/TreatmentOfImpaired/TreatmentOfImpairedForm/TreatmentOfImpairedForm.schema';

const adminEnabled = {
  [QC_SUCCESS]: {
    enabledFields: initialValuesUpdate,
    validationSchema: schemaAll,
    canAddFiles: true,
    showEmails: true,
    showSign: true,
    showSubmit: true,
    showRollBack: false,
    // rollBackQuery: () => {},
  },
  [QC_SUBMIT]: {
    enabledFields: {},
    validationSchema: null,
    canAddFiles: false,
    showEmails: true,
    showSign: true,
    showSubmit: false,
    showRollBack: true,
    // rollBackQuery: rollBackForm3ToQcQuery,
  },
  [COMPLETED]: {
    enabledFields: {},
    validationSchema: null,
    canAddFiles: false,
    showEmails: true,
    showSign: false,
    showSubmit: false,
    showRollBack: true,
    // rollBackQuery: rollBackForm3ToForemanQuery,
  },
};

const qcEnabled = {
  [QC_SUCCESS]: {
    enabledFields: initialValuesUpdate,
    validationSchema: schemaAll,
    canAddFiles: true,
    showEmails: true,
    showSign: true,
    showSubmit: true,
    showRollBack: false,
    // rollBackQuery: () => {},
  },
  [QC_SUBMIT]: {
    enabledFields: {},
    validationSchema: null,
    canAddFiles: false,
    showEmails: true,
    showSign: false,
    showSubmit: false,
    showRollBack: false,
    // rollBackQuery: () => {},
  },
  [COMPLETED]: {
    enabledFields: {},
    validationSchema: null,
    canAddFiles: false,
    showEmails: true,
    showSign: false,
    showSubmit: false,
    showRollBack: false,
    // rollBackQuery: () => {},
  },
};

const foremanEnabled = {
  [QC_SUCCESS]: {
    enabledFields: {},
    validationSchema: null,
    canAddFiles: false,
    showEmails: true,
    showSign: false,
    showSubmit: false,
    showRollBack: false,
    // rollBackQuery: () => {},
  },
  [QC_SUBMIT]: {
    enabledFields: {},
    validationSchema: null,
    canAddFiles: false,
    showEmails: true,
    showSign: true,
    showSubmit: false,
    showRollBack: true,
    // rollBackQuery: rollBackForm3ToQcQuery,
  },
  [COMPLETED]: {
    enabledFields: {},
    validationSchema: null,
    canAddFiles: false,
    showEmails: true,
    showSign: false,
    showSubmit: false,
    showRollBack: false,
    // rollBackQuery: () => {},
  },
};

export default {
  [SUPER_ADMIN]: {
    createAvailable: true,
    dependencies: adminEnabled,
    enabledFields: initialValuesCreate,
    validationSchema: schemaAll,
  },
  [ADMIN]: {
    createAvailable: true,
    dependencies: adminEnabled,
    enabledFields: initialValuesCreate,
    validationSchema: schemaAll,
  },
  [OPERATOR]: {
    createAvailable: false,
    dependencies: {}, // operator isn't included in 3 form
    enabledFields: {},
    validationSchema: null,
  },
  [QC]: {
    createAvailable: true,
    dependencies: qcEnabled,
    enabledFields: initialValuesCreate,
    validationSchema: schemaAll,
  },
  [FOREMAN]: {
    createAvailable: false,
    dependencies: foremanEnabled,
    enabledFields: {},
    validationSchema: schemaAll,
  },
};
