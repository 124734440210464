/* eslint-disable import/no-cycle */
// @flow
import React from 'react';
import styled from 'styled-components';
import { useTable, usePagination } from 'react-table';
import { SaveButton, DefaultTimePickerField } from 'components/index';

import { RowCount } from './EmailSchedule';
import type { Column, TableRow } from './EmailSchedule';

const Styles = (styled.div: any)`
  overflow-x: auto;
  padding: 0;
  flex-grow: 1;
  padding-top: 10px;

  .shortInputs {
    width: 55px;
  }

  .longInputs {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  table {
    border-spacing: 0;
    border: 1px solid black;
    margin: 0 auto;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }

      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
        text-align: right;
      }

      input[type="checkbox"] {
        width: 16px;
        height: 16px;
        margin: 0 auto;
        display: block;
      }

    }
  }
`;

const DefaultCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateTableDataSnapshot,
  type,
  disabled,
  className,
  isShowTitle,
}: any) => {
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onBlur = () => {
    updateTableDataSnapshot(index, id, value);
  };

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <input
      type={type}
      title={isShowTitle && value}
      disabled={disabled}
      className={className}
      value={value || ''}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};
const CheckboxCell = ({ value: initialValue, row: { index }, column: { id }, updateTableDataSnapshot }: any) => {
  const [value, setValue] = React.useState(initialValue);

  const onChange = () => {
    setValue(!value);
  };

  const onBlur = () => {
    updateTableDataSnapshot(index, id, value);
  };

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return <input type="checkbox" checked={value || false} value={value || false} onChange={onChange} onBlur={onBlur} />;
};

const TimeCell = ({ value: initialValue, row: { index }, column: { id }, updateTableDataSnapshot }: any) => {
  const [value, setValue] = React.useState(initialValue);

  const onChange = (newValue) => {
    updateTableDataSnapshot(index, id, newValue);
    setValue(newValue);
  };

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return <DefaultTimePickerField value={value} onChange={onChange} />;
};

const defaultColumn = {
  DefaultCell,
  CheckboxCell,
  TimeCell,
};

type Props = {
  onSubmit: () => void,
  columns: Column[],
  tableData: TableRow[],
  updateTableDataSnapshot: (number, number, any) => void,
};

export default ({ onSubmit, columns, tableData, updateTableDataSnapshot }: Props) => {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } = useTable(
    {
      columns,
      data: tableData,
      defaultColumn,
      autoResetPage: false,
      updateTableDataSnapshot,
      initialState: { pageSize: RowCount },
    },
    usePagination,
  );

  return (
    <>
      <SaveButton onClick={onSubmit} style={{ alignSelf: 'flex-end' }} />
      <Styles>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page &&
              page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    <td {...row.cells[0].getCellProps()}>{row.cells[0].render('TimeCell')}</td>
                    <td {...row.cells[1].getCellProps()}>
                      {row.cells[1].render('DefaultCell', {
                        type: 'number',
                        disabled: false,
                        className: 'shortInputs',
                      })}
                    </td>
                    <td {...row.cells[2].getCellProps()}>{row.cells[2].render('CheckboxCell')}</td>
                    <td {...row.cells[3].getCellProps()}>{row.cells[3].render('CheckboxCell')}</td>
                    <td {...row.cells[4].getCellProps()}>{row.cells[4].render('CheckboxCell')}</td>
                    <td {...row.cells[5].getCellProps()}>{row.cells[5].render('CheckboxCell')}</td>
                    <td {...row.cells[6].getCellProps()}>{row.cells[6].render('CheckboxCell')}</td>
                    <td {...row.cells[7].getCellProps()}>{row.cells[7].render('CheckboxCell')}</td>
                    <td {...row.cells[8].getCellProps()}>{row.cells[8].render('CheckboxCell')}</td>
                    <td {...row.cells[9].getCellProps()}>{row.cells[9].render('CheckboxCell')}</td>
                    <td {...row.cells[10].getCellProps()}>{row.cells[10].render('CheckboxCell')}</td>
                    <td {...row.cells[11].getCellProps()}>{row.cells[11].render('CheckboxCell')}</td>
                    <td {...row.cells[12].getCellProps()}>{row.cells[12].render('CheckboxCell')}</td>
                    <td {...row.cells[13].getCellProps()}>{row.cells[13].render('CheckboxCell')}</td>
                    <td {...row.cells[14].getCellProps()}>
                      {row.cells[14].render('DefaultCell', {
                        isShowTitle: true,
                        disabled: true,
                        className: 'longInputs',
                      })}
                    </td>
                    <td {...row.cells[15].getCellProps()}>
                      {row.cells[15].render('DefaultCell', { disabled: true, className: 'shortInputs' })}
                    </td>
                    <td {...row.cells[16].getCellProps()}>
                      {row.cells[16].render('DefaultCell', { disabled: true, className: 'shortInputs' })}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </Styles>
    </>
  );
};
