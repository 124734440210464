// @flow
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { formatRoute } from 'react-router-named-routes';
import type { Record, IRouteProps } from 'types/frontTypes';

const useRoutingHook = () => {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch<IRouteProps>();

  const getParams = (params: string[]): Record<string, string | null> => {
    const searchParams = new URLSearchParams(location.search);

    return params.reduce((acc: Record<string, string | null>, name) => {
      acc[name] = searchParams.get(name);

      return acc;
    }, {});
  };

  const setParams = (params: Record<string, string>) => {
    const newSearchParams = new URLSearchParams();

    Object.entries(params).forEach(([name, value]) => {
      newSearchParams.set(name, ((value: any): string));
    });

    return newSearchParams.toString();
  };

  const redirectTo = (path: string, state?: Record<string, any>) => () => {
    history.push(path, state);
  };

  return {
    getParams,
    setParams,
    redirectTo,
    formatRoute,
    match,
    location,
    history,
  };
};

export const useRouting = useRoutingHook;

export default useRoutingHook;
