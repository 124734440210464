import React, { useEffect } from 'react';
import T from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Formik, Field, useFormikContext } from 'formik';
import Table from '@material-ui/core/Table';
import MenuItem from '@material-ui/core/MenuItem';
import { differenceInCalendarDays } from 'date-fns';

import {
  SelectField,
  TextField,
  DatePickerField,
  TimePickerField,
  FormAddedFiles,
  FormNewFiles,
  FormActions,
} from 'components/index';
import dictionary from 'constants/dictionary';

const styles = () => ({
  form: {
    width: '100%',
    textAlign: 'right',
    overflowX: 'hidden',
  },
});

const sideMap = ['מסלול דרום', 'מסלול צפון', 'שני הכיוונים'];

const telephoneReportingOperationsTeamMap = ['בוצע', 'לא בוצע'];

const dependenciesFlawDetailsFromTestCode = {
  '': [],
  P1_DM: ['סדיקת אליגטור', '   בורות'],
  P1_CR: [' סדיקה אורכית', ' סדיקה רוחבית'],
  P4: [
    'ירידה בהתנגדות להחלקה של פני המיסעה כתוצאה מליטוש אגרגטים',
    'סגירת מירקם התערובת העליונה',
    'זיהום על ידי גורמים שונים ',
  ],
  M1: ['העלמות סימן צבע', 'קילוף והזעה', 'לכלוך או שמן המכסים סמני דרך', 'מדרגות חסרות בפסי הרעדה'],
  M2: ['פגיעה בנראות סימני הדרך', 'שינוי צבע הסימן', 'ליטוש ', 'איבוד טקסורה'],
  M3: ['סמנים רופפים', 'חסרים או ניזוקים'],
  G1: ['לכלוך', 'פסולת', 'חול', 'אבנים', 'גרפיטי', 'מדבקות', 'צמחיה לא רצויה'],
  G2: [
    'לכלוך מסוגים שונים לרבות ניירות',
    'אריזות ריקות',
    'ניילונים',
    'ענפים וצמחיה מתה',
    'דברים שונים שהושלכו מכלי רכב',
    'שלטים',
    'מדבקות',
    'גרפיטי',
    'צמחיה החוסמת מעבר או מגבילה מרחק ראות',
  ],
  G4: [
    'צמחיה לא מפותחת או לא מתפתחת',
    'צמחיה מתה',
    'שטח עם צמחייה לא רצויה',
    'שרידי גזם בלתי מפונה',
    'עצים בלתי תמוכים',
    'מחלות או מזיקים בצמחים',
    'מערכת השקייה פגועה וקרועה',
    'ראשי מערכת השקייה פרוצים',
  ],
  G5: ['חרוץ מדרונות עי נגר עילי', 'גלישות מקומיות', 'הפרת יציבות המדרונות', 'נזקים לאמצעים למניעת ארוזיה במדרונות'],
  P9: ['חריצה משמעותית של פני הדרך', 'בורות בדרך', 'שלוליות מים'],
  D2: ['תעלות ניקוז סתומות', 'תעלות לא מנוקזות'],
  D3: ['סתימות במתקני ניקוז', 'כניסות למתקני ניקוז (לרבות קולטנים)', 'סחף במעבירי מים'],
};

const safetyFlawDetailsMap = (key) => dependenciesFlawDetailsFromTestCode[key];

const testCodeMap = [
  {
    key: 'P1_DM',
    value: 'P1-DM ( נזקי שטח הדורשים טיפול על ידי הטלאות )',
    durationRequiredForTreatment: 60,
  },
  {
    key: 'P1_CR',
    value: `P1-CR ( סדקים למיניהם ברוחב העולה על 3 מ''מ, הדורשים טיפול באמצעות איטום )`,
    durationRequiredForTreatment: 60,
  },
  {
    key: 'P4',
    value: 'P4 ( התנגדות להחלקה של פני המיסעה )',
    durationRequiredForTreatment: 60,
  },
  {
    key: 'M1',
    value: 'M1( העלמות או שחיקה של סימני צבע )',
    durationRequiredForTreatment: 60,
  },
  {
    key: 'M2',
    value: 'M2 ( נראות והתנגדות להחלקה של סימני צבע )',
    durationRequiredForTreatment: 60,
  },
  {
    key: 'M3',
    value: 'M3 (שלמות סמנים מחזירי אור "עיני חתול" למיניהם)',
    durationRequiredForTreatment: 60,
  },
  {
    key: 'G1',
    value: `G1( נקיון המיסעה, משטחים להולכי רגל, שולי כביש סלולים, שטחי מפרדה סלולים, לרבות
  כלל מרכיבי הדרך הכלולים בהם )`,
    durationRequiredForTreatment: 60,
  },
  {
    key: 'G2',
    value: `G2( נקיון רצועת הדרך הקרובה , לרבות כלל מרכיבי הדרך הכלולים בה ולמעט השטחים
    הכלולים בסעיף G1 )`,
    durationRequiredForTreatment: 60,
  },
  {
    key: 'G4',
    value: `G4( טיפול בשטחים מגוננים)`,
    durationRequiredForTreatment: 60,
  },
  {
    key: 'G5',
    value: `G5(שלמות מדרונות )`,
    durationRequiredForTreatment: 60,
  },
  {
    key: 'P9',
    value: `P9(מצב דרכים חקלאיות )`,
    durationRequiredForTreatment: 60,
  },
  {
    key: 'D2',
    value: `D2(תעלות פתוחות)`,
    durationRequiredForTreatment: 60,
  },
  {
    key: 'D3',
    value: 'D3(סתימות במתקני ניקוז למיניהם)',
    durationRequiredForTreatment: 60,
  },
];

const responsiblePersonMap = ['מנהל פרויקט', 'קבלן', 'מתכנן'];

const onTestCodeChange = (setFormikState, oldFormikValues, errors, touched) => (event) => {
  setFormikState({
    errors,
    touched,
    values: {
      ...oldFormikValues,
      testCode: event.target.value,
    },
  });
};

const CalcDependentFields = () => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue(
      'durationRequiredForTreatment',
      (testCodeMap.find((testCode) => testCode.key === values.testCode) || {}).durationRequiredForTreatment || 0,
    );
  }, [values.testCode]);

  useEffect(() => {
    setFieldValue(
      'actualTreatmentTime',
      differenceInCalendarDays(values.treatmentDateEnd || new Date(), values.eventHappeningDate || new Date()),
    );
  }, [values.eventHappeningDate, values.treatmentDateEnd]);

  useEffect(() => {
    setFieldValue(
      'deviationTime',
      differenceInCalendarDays(
        values.treatmentDateEnd ? new Date(values.treatmentDateEnd) : new Date(),
        values.eventHappeningDate || new Date(),
      ) - values.durationRequiredForTreatment || 0,
    );
  }, [values.eventHappeningDate, values.durationRequiredForTreatment, values.treatmentDateEnd]);
  return null;
};

const Form3 = ({
  classes,
  onSubmit,
  initialValues,
  handleBackAction,
  newFiles,
  showFiles,
  handleAddNewFile,
  handleDeleteFile,
  handleViewFile,
  addedFiles,
  canAddFiles,
  handleNewFileChange,
  handleNewFileDescriptionChange,
  handleNewDeleteFile,
  showSubmit,
  showSign,
  handleSignForm,
  code,
  handleCodeStateChange,
  validationSchema,
  imageBefore,
  imageAfter,
  handleImagesChange,
  enabledFields,
  showRollBack,
  handleRollBack,
}) => {
  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ handleSubmit, values, setFormikState, errors, touched }) => {
        return (
          <form onSubmit={handleSubmit} className={classes.form}>
            <Table>
              <tbody>
                <tr>
                  <td width="25%" />
                  <td width="25%" />
                  <td width="25%">
                    <Field
                      component={TimePickerField}
                      name="eventHappeningTime"
                      label={dictionary.monitoringReportTime}
                      enabledFields={enabledFields}
                    />
                  </td>
                  <td width="25%">
                    <Field
                      component={DatePickerField}
                      name="eventHappeningDate"
                      label={dictionary.monitoringReportDate}
                      enabledFields={enabledFields}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>

            <Table>
              <tbody>
                <tr>
                  <td width="40%" align-items="rigth">
                    <Field
                      component={TextField}
                      name="element"
                      label={dictionary.element}
                      enabledFields={enabledFields}
                    />
                  </td>

                  <td width="35%">
                    <Field
                      component={TextField}
                      name="location"
                      label={dictionary.locationMonitoringBySegments}
                      enabledFields={enabledFields}
                    />
                  </td>

                  <td width="25%">
                    <Field component={SelectField} name="side" label={dictionary.side} enabledFields={enabledFields}>
                      {sideMap.map((value, index) => (
                        <MenuItem key={index} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Field>
                  </td>
                </tr>
              </tbody>
            </Table>

            <Table>
              <tbody>
                <tr>
                  <td width="25%">
                    <Field
                      component={TextField}
                      name="durationRequiredForTreatment"
                      label={dictionary.durationRequiredForTreatment}
                      disabled
                      type="number"
                    />
                  </td>
                  <td width="25%">
                    <Field
                      component={SelectField}
                      name="safetyFlawDetails"
                      label={dictionary.safetyFlawDetails}
                      enabledFields={enabledFields}
                    >
                      {safetyFlawDetailsMap(values.testCode).map((value, index) => (
                        <MenuItem key={index} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Field>
                  </td>

                  <td width="25%">
                    <Field
                      component={SelectField}
                      name="testCode"
                      label={dictionary.testCode}
                      enabledFields={enabledFields}
                      onChange={onTestCodeChange(setFormikState, values, errors, touched)}
                    >
                      {testCodeMap.map((value, index) => (
                        <MenuItem key={index} value={value.key}>
                          {value.value}
                        </MenuItem>
                      ))}
                    </Field>
                  </td>

                  <td width="25%">
                    <Field
                      component={DatePickerField}
                      name="monitoringDate"
                      label={dictionary.monitoringDate}
                      enabledFields={enabledFields}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>

            <Table>
              <tbody>
                <tr>
                  <td width="25%">
                    <Field
                      component={TextField}
                      name="actualTreatmentTime"
                      label={dictionary.actualTreatmentTime}
                      disabled
                      type="number"
                    />
                  </td>
                  <td width="25%">
                    <Field
                      component={TextField}
                      name="deviationTime"
                      label={dictionary.deviationTime}
                      disabled
                      type="number"
                    />
                  </td>
                  <td width="25%">
                    <Field
                      component={SelectField}
                      name="telephoneReportingOperationsTeam"
                      label={dictionary.telephoneReportToTheCallCenterAboutSafetyDeficiencies}
                      enabledFields={enabledFields}
                    >
                      {telephoneReportingOperationsTeamMap.map((value, index) => (
                        <MenuItem key={index} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Field>
                  </td>
                  <td width="25%">
                    <Field
                      component={TextField}
                      name="reportNumber"
                      label={dictionary.reportNumber}
                      enabledFields={enabledFields}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>

            <Field
              component={TextField}
              name="correctiveAction"
              label={dictionary.correctiveAction}
              enabledFields={enabledFields}
            />

            <Table>
              <tbody>
                <tr>
                  <td width="25%" />
                  <td width="25%" />
                  <td width="25%" />
                  <td width="25%">
                    <Field
                      component={SelectField}
                      name="responsiblePerson"
                      label={dictionary.responsiblePerson}
                      enabledFields={enabledFields}
                    >
                      {responsiblePersonMap.map((value, index) => (
                        <MenuItem key={index} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Field>
                  </td>
                </tr>
              </tbody>
            </Table>

            <Field
              component={TextField}
              name="descriptionOfPerformedCorrectiveAction"
              label={dictionary.descriptionOfPerformedCorrectiveAction}
              enabledFields={enabledFields}
            />

            <Table>
              <tbody>
                <tr>
                  <td width="25%" />
                  <td width="25%">
                    <Field
                      component={DatePickerField}
                      name="repairDateOfDefects"
                      label={dictionary.dateOfDefectsReport}
                      enabledFields={enabledFields}
                    />
                  </td>
                  <td width="25%">
                    <Field
                      component={TimePickerField}
                      name="endOfTreatmentTime"
                      label={dictionary.endOfTreatmentTime}
                      enabledFields={enabledFields}
                    />
                  </td>
                  <td width="25%">
                    <Field
                      component={DatePickerField}
                      name="treatmentDateEnd"
                      label={dictionary.dateOfRepairOfDefects}
                      enabledFields={enabledFields}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>

            <Field
              component={TextField}
              name="remarks"
              label={dictionary.remarks}
              multiline
              enabledFields={enabledFields}
            />

            {showFiles && (
              <FormAddedFiles
                addedFiles={addedFiles}
                filesTableHandler={handleViewFile}
                deleteFileHandler={handleDeleteFile}
                canAddFiles={canAddFiles}
              />
            )}

            <FormNewFiles
              newFiles={newFiles}
              onNewFileChange={handleNewFileChange}
              onNewFileDescChange={handleNewFileDescriptionChange}
              deleteNewFileHandler={handleNewDeleteFile}
              onAddFile={handleAddNewFile}
              canAddFiles={canAddFiles}
              imageBefore={imageBefore}
              imageAfter={imageAfter}
              handleImagesChange={handleImagesChange}
            />

            <FormActions
              newGeneration
              showSubmit={showSubmit}
              showSign={showSign}
              onSubmit={onSubmit}
              handleBackAction={handleBackAction}
              handleSignForm={handleSignForm}
              code={code}
              handleCodeStateChange={handleCodeStateChange}
              showRollBack={showRollBack}
              handleRollBack={handleRollBack}
            />
            <CalcDependentFields />
          </form>
        );
      }}
    </Formik>
  );
};

Form3.propTypes = {
  newFiles: T.arrayOf(T.any).isRequired,
  showFiles: T.bool.isRequired,
  handleAddNewFile: T.func.isRequired,
  handleDeleteFile: T.func,
  handleViewFile: T.func,
  addedFiles: T.arrayOf(T.any),
  canAddFiles: T.bool.isRequired,
  handleNewFileChange: T.func.isRequired,
  handleNewFileDescriptionChange: T.func.isRequired,
  handleNewDeleteFile: T.func.isRequired,
  showSubmit: T.bool.isRequired,
  showSign: T.bool,
  validationSchema: T.objectOf(T.any),
  imageBefore: T.objectOf(T.any),
  imageAfter: T.objectOf(T.any),
  handleImagesChange: T.func.isRequired,
  handleSignForm: T.func,
  code: T.objectOf(T.any),
  handleCodeStateChange: T.func,
  classes: T.objectOf(T.string).isRequired,
  initialValues: T.objectOf(T.any).isRequired,
  onSubmit: T.func.isRequired,
  handleBackAction: T.func.isRequired,
  enabledFields: T.objectOf(T.any),
  showRollBack: T.bool.isRequired,
  handleRollBack: T.func.isRequired,
};

Form3.defaultProps = {
  imageBefore: null,
  imageAfter: null,
  showSign: false,
  addedFiles: [],
  handleViewFile: null,
  handleDeleteFile: null,
  handleSignForm: null,
  handleCodeStateChange: null,
  code: {},
  validationSchema: null,
  enabledFields: null, // NOTE: if !!enabledFields === false => all fields are enabled (for create page)
};

export default withStyles(styles)(Form3);
