/* eslint-disable max-len */
// @flow
import jwtDecode from 'jwt-decode';
import fileTypes from 'constants/fileTypes';
import lifeTime from 'constants/lifeTime';
import errorAliases from 'constants/errorAliases';
import { showNotification, formatString } from 'utils/index';
import routes from 'pages/routes';

import Localstore from './Localstore';

type Input = { name: string, value: string | Blob };

type Token = {
  create: string,
  role: any,
};

const HOST = ((process.env.REACT_APP_API_HOST: any): string);

const logoutQuery = () => ({
  query: `query{
    logout
  }`,
});

const loginQuery = (email, password) => ({
  query: `query{
    login(
      email:"${email}"
      password:"${password}"
    )
  }`,
});

export default class Api {
  token: string;

  _token: string;

  expirationTime: number;

  _expirationTime: number;

  localstore: Localstore;

  baseURL: string;

  constructor() {
    this.localstore = new Localstore();
    this.baseURL = HOST;
    this.token = String(this.localstore.getItemFromLocalstore('token') || '');
    this.expirationTime = Number(this.localstore.getItemFromLocalstore('expirationTime') || 0);
  }

  get token() {
    return this._token;
  }

  set token(value: string) {
    this._token = value;
    this.localstore.setItemToLocalstore('token', value);
  }

  get expirationTime() {
    return this._expirationTime;
  }

  set expirationTime(value: number) {
    this._expirationTime = value;
    this.localstore.setItemToLocalstore('expirationTime', value);
  }

  get isLoggedIn() {
    return !!this.token;
  }

  removeAllMetaData() {
    this.token = '';
    this.expirationTime = 0;
  }

  async login(email: string, password: string) {
    const res = await this.fetchQuery(loginQuery(email, password));
    const token = res.login;
    const { role, create } = jwtDecode<Token>(token);

    this.token = token;
    this.expirationTime = new Date(create).getTime() + lifeTime;
    return role;
  }

  async logout() {
    this.removeAllMetaData();
    await this.fetchQuery(logoutQuery());
  }

  openReport(files: ?any, formType: string, formId: string, type: string) {
    const fileType = fileTypes[type] ? fileTypes[type] : fileTypes.default;
    const url = new URL(`/api/getReport/${formType}/${formId}/${fileType}/${this.token}`, this.baseURL);

    const searchParams = new URLSearchParams();
    if (files && files.length > 0) {
      const idArr = files.map((file) => file.id);
      searchParams.set('necessaryDocIds', `${idArr}`);
    } else {
      searchParams.set('necessaryDocIds', '[]');
    }
    url.search = searchParams.toString();

    window.open(url);
  }

  openFile(formType: string, formId: string, fileId: string) {
    const url = new URL(`/api/getFileById/${formType}/${formId}/${fileId}/${this.token}`, this.baseURL);

    window.open(url);
  }

  openLicenseFile(userId: string) {
    const url = new URL(`/api/getLicenseByUserId/${userId}/${this.token}`, this.baseURL);
    window.open(url);
  }

  async isLicenseFileExist(id: string) {
    let response = await fetch(`${this.baseURL}/api/getLicenseByUserId/${id}/${this.token}`);
    response = await response.text();

    if (response.includes('Viewed User confirmed license path is null')) {
      return false;
    }
    return true;
  }

  getFormData(inputs: Array<Input>): FormData {
    const formData = new FormData();

    inputs.forEach((input) => {
      formData.append(input.name, input.value);
    });

    return formData;
  }

  fetchFormData(formData: FormData, url: string) {
    return fetch(url, {
      method: 'POST',
      body: formData,
    });
  }

  sendAddFilesForm(formType: string, formId: string, files: Array<any>) {
    if (!files.length) return Promise.resolve([]);
    // NOTE this need to not send empty form
    if (files.every((file) => !file || !file.value)) return Promise.resolve([]);

    const inputs = [];

    inputs.push({ name: 'formType', value: formType });

    files.forEach((file) => {
      if (!file || !file.value) {
        return;
      }

      inputs.push({ name: 'file', value: file.value });
      inputs.push({ name: 'description', value: file.description });
    });

    inputs.push({ name: 'formId', value: formId });

    const formData = this.getFormData(inputs);
    const url = `${this.baseURL}/api/addFile/${this.token}`;
    return this.fetchFormData(formData, url);
  }

  sendCodeToUser(inputs: Array<Input>) {
    return this.fetchFormData(this.getFormData(inputs), `${this.baseURL}/api/addConfirmedLicenseFile/${this.token}`);
  }

  // TODO: need to check all error branches
  async errorHandling(errorRespose: Response) {
    // NOTE if token is out of date - clear storage, go to login page and show error
    if (errorRespose.status === 401 && errorRespose.statusText) {
      this.removeAllMetaData();
      showNotification(errorRespose.statusText, 'warning');
      setTimeout(() => {
        document.location.href = routes.login.path;
      }, 1000);
      return Promise.reject(errorRespose.statusText);
    }

    // $FlowFixMe
    if ('message' in errorRespose && typeof errorRespose.message === 'string') {
      showNotification(((errorRespose: any): { message: string }).message);
      return Promise.reject(((errorRespose: any): { message: string }).message);
    }

    // NOTE is some cases back send to front res with status 200 and res contain array of errors
    if (Array.isArray(errorRespose)) {
      return Promise.reject(
        errorRespose.map((error) => {
          const splittedError = error.message.split(':')[1].trim(); // NOTE: get rid of 'Exception while fetching data'
          const ErrorMessage = errorAliases[splittedError] ? errorAliases[splittedError] : splittedError;
          showNotification(ErrorMessage, 'error');

          return ErrorMessage;
        }),
      );
    }

    // NOTE clone the response, because we can use response methods only once and after that body will locked
    const cloneResponse = errorRespose.clone();

    // NOTE is some cases back send to front json or text, so we should switch between that methods
    const errorMessage =
      (await cloneResponse.json().then(({ message }) => message)) ||
      (await errorRespose.text().then((message) => message));

    showNotification(errorMessage, 'error');
    return Promise.reject(errorMessage);
  }

  async responseHandling(res: Response) {
    // NOTE catch not 2xx code statuses
    if (!res.ok) {
      return Promise.reject(res);
    }

    this.expirationTime = Date.now() + lifeTime;

    const { data, errors } = await res.json();

    // NOTE: there are may be 200 status and graphql errors inside response body
    if (errors) {
      return Promise.reject(errors);
    }

    return data;
  }

  formatQuery(query: string): string {
    // NOTE: find all string inputs in query to  to shield hebrew " and make \n from 'end of line'
    // ([ ${cyrillic}${hebrew}\d\w'"-]*\n)* - for finding lines with cyrillic, hebrew, latin or decimals with \n at the end
    // [ ${cyrillic}${hebrew}\d\w'"-]* - same as above but without 'end of line'
    // example: " פריסת-שלו
    // abc b"ca
    // абв"
    // will become: " פריסת-שלו\nabc b\"ca\nабв"
    const latin = '\\w';
    const cyrillic = '\u0400-\u04ff';
    const hebrew = '\u0590-\u05ff';
    const re = new RegExp(
      `(?<=")([ ${cyrillic}${hebrew}${latin}\\d'"-]*\n)*[ ${cyrillic}${hebrew}${latin}\\d'"-]*(?=")`,
      'g',
    );

    return query.replace(re, formatString);
  }

  fetchQuery(queryObj: { query: string }) {
    const formattedQuery = {
      query: this.formatQuery(queryObj.query),
    };

    return fetch(`${this.baseURL}/graphql`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: this.token ? `Bearer ${this.token}` : '',
      }),
      body: JSON.stringify(formattedQuery),
    })
      .then((res) => this.responseHandling(res))
      .catch((res) => this.errorHandling(res));
  }
}
