// @flow
import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Formik, Field } from 'formik';
import Table from '@material-ui/core/Table';
import MenuItem from '@material-ui/core/MenuItem';

import { SelectField, TextField, File, CheckBoxField, Actions, ChangePasswordButton } from 'components/index';
import dictionary from 'constants/dictionary';

const styles = (theme) => ({
  form: {
    width: '300px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
  },
  fileLink: {
    color: 'blue',
    textDecoration: 'underline',
    cursor: 'pointer',
    marginTop: '14px',
    marginBottom: '10px',
  },
});

type Props = {|
  onSubmit: any,
  initialValues: any,
  handleBackAction: any,
  globalRoleSelectValiants: any,
  validationSchema: any,
  showEmail: any,
  isShowLicenseLink: any,
  handleClickFile: any,
  showChangePassword: any,
  handleChangePassword: any,
  classes: any,
|};

const UserForm = ({
  classes,
  onSubmit,
  initialValues,
  handleBackAction,
  globalRoleSelectValiants,
  validationSchema,
  showEmail,
  isShowLicenseLink,
  handleClickFile,
  showChangePassword,
  handleChangePassword,
}: Props) => {
  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ handleSubmit, values }) => {
        console.log(values);
        return (
          <form onSubmit={handleSubmit} className={classes.form}>
            {showEmail && <Field component={TextField} name="email" label={dictionary.email} type="email" />}

            <Field component={TextField} name="firstName" label={dictionary.firstName} />

            <Field component={TextField} name="lastName" label={dictionary.lastName} />

            <Field component={TextField} name="phone" label={dictionary.phone} type="phone" />

            <Field component={SelectField} name="globalRole" label={dictionary.globalRole}>
              {globalRoleSelectValiants.map((role) => (
                <MenuItem key={role} value={role}>
                  {dictionary[role]}
                </MenuItem>
              ))}
            </Field>

            <Table>
              <tbody>
                <tr>
                  <td width="50%">
                    <Field component={File} name="fileForCode" label="fileForCode" />
                  </td>

                  <td width="50%">
                    <Field component={CheckBoxField} name="contact" label={dictionary.contact} />
                  </td>
                </tr>
              </tbody>
            </Table>

            {showChangePassword && <ChangePasswordButton onClick={handleChangePassword} />}

            {isShowLicenseLink && (
              <a className={classes.fileLink} onClick={handleClickFile}>
                License File
              </a>
            )}

            <Actions handleBackAction={handleBackAction} />
          </form>
        );
      }}
    </Formik>
  );
};

export default withStyles(styles)(UserForm);
