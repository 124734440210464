import React from 'react';
import T from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Formik, Field } from 'formik';
import MenuItem from '@material-ui/core/MenuItem';

import { SelectField, CheckBoxField, Actions } from 'components/index';
import dictionary from 'constants/dictionary';
import validationSchema from './ProjectUserForm.schema';

const styles = () => ({
  form: {
    width: '30%',
    maxWidth: '300px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
  },
});

const UserForm = ({ classes, onSubmit, initialValues, handleBackAction, users, projects }) => {
  console.log('users', users);
  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit} className={classes.form}>
            <Field component={SelectField} name="userId" label={dictionary.userId}>
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {`${user.firstName} ${user.lastName}`}
                </MenuItem>
              ))}
            </Field>

            <Field component={SelectField} name="projectId" label={dictionary.projectId}>
              {projects.map((project) => (
                <MenuItem key={project.id} value={project.id}>
                  {project.name}
                </MenuItem>
              ))}
            </Field>

            <Field component={CheckBoxField} name="defaultContact" label={dictionary.defaultContact} />

            <Actions handleBackAction={handleBackAction} />
          </form>
        );
      }}
    </Formik>
  );
};

UserForm.propTypes = {
  classes: T.objectOf(T.string).isRequired,
  initialValues: T.objectOf(T.string).isRequired,
  onSubmit: T.func.isRequired,
  handleBackAction: T.func.isRequired,
  users: T.arrayOf(T.object).isRequired,
  projects: T.arrayOf(T.object).isRequired,
};

UserForm.defaultProps = {};

export default withStyles(styles)(UserForm);
