// @flow
import type { Record } from 'types/utility';

const globalRoles = Object.freeze({
  ADMIN: 'ADMIN',
  SUPER_ADMIN: 'SUPER_ADMIN',
  QC: 'QC',
  OPERATOR: 'OPERATOR',
  FOREMAN: 'FOREMAN',
});

export type Role = $Keys<typeof globalRoles>;

export const RoleObjectEnum: Record<Role, Role> = {
  ADMIN: 'ADMIN',
  SUPER_ADMIN: 'SUPER_ADMIN',
  QC: 'QC',
  OPERATOR: 'OPERATOR',
  FOREMAN: 'FOREMAN',
};
