// @flow
import * as React from 'react';
import { useRouting } from 'hooks/useRouting';
import Exit from '@material-ui/icons/ExitToApp';
import { Link } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';

import routes from 'pages/routes';
import api from 'api/index';
import Logo from 'img/NewLogo.png';
import dictionary from 'constants/dictionary';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    fontSize: '2.2em',
  },
  header: {
    textAlign: 'right',
    background: '#3e7a5a',
    width: '80%',
    flexGrow: '1',
    padding: '20px 15px',
    boxShadow: '0px 5px 14px -7px',
    zIndex: '99',
    display: 'flex',
    justifyContent: 'space-between',
  },
  exit: {
    height: '40px',
    width: '40px',
    transform: 'scale(-1, 1)',
    cursor: 'pointer',
    fill: '#fff',
  },
  logo: {
    margin: 0,
    color: 'white',
  },
  logoImage: {
    width: '20%',
    maxWidth: '275px',
    paddingRight: '24px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
});

const Header = () => {
  const { history } = useRouting();
  const classes = useStyles();
  const handleLogout = () => {
    api
      .logout()
      .then(() => {
        history.push(routes.login.path);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Exit className={classes.exit} onClick={handleLogout} />
        <p className={classes.logo}>{dictionary.headerTitle}</p>
      </div>
      <div className={classes.logoImage}>
        <Link to={routes.dashboard.path}>
          <img style={{ width: '115px' }} src={Logo} alt="Visoft logo" />
        </Link>
      </div>
    </div>
  );
};

export default Header;
