import { compose, withHandlers } from 'recompose';
import { connect } from 'formik';

import { withDialogHandler, withRole } from 'hoc';
import { OPERATOR, ADMIN, SUPER_ADMIN } from 'constants/global-roles';
import { OPERATOR_SUCCESS } from 'constants/formStatus';
import Component from './FormActions';

const enchance = compose(
  connect,
  withRole,
  withDialogHandler,
  withHandlers({
    handleClickSign: (props) => () => {
      if (
        [OPERATOR, ADMIN, SUPER_ADMIN].includes(props.roleContext.role) &&
        props.formik.values.status === OPERATOR_SUCCESS
      ) {
        props.handleSignForm(props.formik.values);
      } else {
        props.handleDialogOpen('isOpenCode');
      }
    },
  }),
);

export default enchance(Component);
