import { compose, withHandlers, renameProps, withState } from 'recompose';

import { showNotification, toBase64 } from 'utils/index';
import api from 'api/index';
import routes from 'pages/routes';
import defaultImage from 'img/defaultImage';
import dictionaryPhrases from 'constants/dictionary.phrases';

const enchance = compose(
  withState('isFetching', 'setIsFetching', false),
  withHandlers({
    handleCreate: (props) => (values) => {
      props.setIsFetching(true);

      const createItem = () => {
        if (routes.users.path === props.match.path) {
          return api.fetchQuery(props.createListItemQuery(values)).then((res) => {
            if (values.fileForCode) {
              const inputs = [
                { name: 'userId', value: res.createUser.id },
                { name: 'file', value: values.fileForCode },
              ];

              return api.sendCodeToUser(inputs);
            }

            return res;
          });
        }

        if (
          [routes.forms1.path, routes.forms2.path, routes.forms3.path, routes.forms4.path].includes(props.match.path)
        ) {
          if (props.newFiles.some((file) => file.value && !file.description)) {
            showNotification(dictionaryPhrases.descriptionsWarning, 'warning');

            return Promise.reject();
          }
          return api.fetchQuery(props.createListItemQuery({ ...values, projectId: props.projectId })).then((res) => {
            return api.sendAddFilesForm(props.formType, res[props.createListItemResponseName].id, props.newFiles);
          });
        }

        if (routes.projects.path === props.match.path) {
          const promisedLogoValue = values.logoMainContractor
            ? toBase64(values.logoMainContractor)
            : Promise.resolve(defaultImage);

          return promisedLogoValue.then((logoMainContractor) =>
            api.fetchQuery(props.createListItemQuery({ ...values, logoMainContractor })),
          );
        }

        if (routes.contacts.path === props.match.path || routes.projectUsers.path === props.match.path) {
          return api.fetchQuery(props.createListItemQuery(values));
        }

        return Promise.reject();
      };

      createItem()
        .then(() => showNotification(props.createSuccessMessage, 'success'))
        .finally(() => {
          props.setIsFetching(false);
        })
        .then(() => {
          props.toggleIsCreate();
        })
        .catch((err) => {
          console.error(err);
        });
    },
  }),
  renameProps({
    toggleIsCreate: 'handleBackAction',
    handleCreate: 'onSubmit',
  }),
);

export default enchance;
