import { compose, lifecycle, withState, withStateHandlers } from 'recompose';

import { withLogProps, withRole } from 'hoc/index';
import api from 'api/index';
import { FORM_NAME } from 'constants/formSetup';
import routes from 'pages/routes';
import { statusColors, statusOrder } from 'constants/formStatus';
import dictionary from 'constants/dictionary';
import Component from './HomeDashboard';

const getAllFormsQuery = () => ({
  query: `query {
    findAllForms {
      handlingTrafficAccidents {
        forms {
          status
          projectId {
            name
            deleted
          }
        }
      }
      failesSafeties {
        forms {
          status
          projectId {
            name
            deleted
          }
        }
      }
      treatmentOfImpairedByMonitoringReports {
        forms {
          status
          projectId {
            name
            deleted
          }
        }
      }
      handlingTrafficEvents {
        forms {
          status
          projectId {
            name
            deleted
          }
        }
      }
    }
  }`,
});

const getColorsByStatus = (labels) => labels.map((label) => statusColors[label]);

const getChartState = ({ labels = [], data = [] } = {}) => ({
  labels,
  datasets: [
    {
      data,
      backgroundColor: getColorsByStatus(labels),
      hoverBackgroundColor: getColorsByStatus(labels),
    },
  ],
});

const groupByStatus = (arr) =>
  arr
    .filter((elem) => !elem.projectId.deleted)
    .reduce((acc, elem) => {
      acc[elem.status] = acc[elem.status] ? [...acc[elem.status], elem] : [elem];

      return acc;
    }, {});

const reorderStatuses = (object) =>
  statusOrder
    .map((status) => {
      const findedElem = Object.entries(object).find((elem) => elem[0] === status);

      return findedElem ? { status, data: findedElem[1] } : { status, data: [] };
    })
    .map((elem) => {
      return { status: elem.status, numberOfData: elem.data.length };
    })
    .reduce(
      (acc, elem) => {
        acc.labels = [...acc.labels, dictionary[elem.status]];
        acc.data = [...acc.data, elem.numberOfData];

        return acc;
      },
      { labels: [], data: [] },
    );

const getTotals = (arr) => arr.reduce((acc, elem) => acc + elem, 0);

const enchance = compose(
  withRole,
  withState('isFetching', 'setIsFetching', false),
  withStateHandlers(
    {
      charts: {
        chartForm4: null,
        chartForm1: null,
        chartForm2: null,
        chartForm3: null,
      },
    },
    {
      onLoadedForms: (state) => ({ chartForm1, chartForm2, chartForm3, chartForm4 }) => ({
        ...state,
        charts: {
          ...state.charts,
          chartForm1,
          chartForm2,
          chartForm3,
          chartForm4,
        },
      }),
    },
  ),
  withLogProps,
  lifecycle({
    componentDidMount() {
      this.props.setIsFetching(true);

      api
        .fetchQuery(getAllFormsQuery())
        .then(
          ({
            findAllForms: {
              handlingTrafficAccidents: { forms: forms1 },
              failesSafeties: { forms: forms2 },
              treatmentOfImpairedByMonitoringReports: { forms: forms3 },
              handlingTrafficEvents: { forms: forms4 },
            },
          }) => {
            // NOTE safe set forms to [] instead null
            const { data: dataForm1 } = reorderStatuses(groupByStatus(forms1 || []));
            const { labels: labelsForm2, data: dataForm2 } = reorderStatuses(groupByStatus(forms2 || []));
            const { labels: labelsForm3, data: dataForm3 } = reorderStatuses(groupByStatus(forms3 || []));
            const { data: dataForm4 } = reorderStatuses(groupByStatus(forms4 || []));

            console.log(this.props.roleContext.role);
            if (this.props.roleContext.role === 'OPERATOR') {
              console.log(555);
            }

            const hasAccess = (route) => route.access.includes(this.props.roleContext.role);

            this.props.onLoadedForms({
              chartForm1: hasAccess(routes.forms1) && {
                header: FORM_NAME.form1,
                data: getChartState({
                  labels: ['TOTAL'],
                  data: dataForm1,
                }),
                total: getTotals(dataForm1),
              },
              chartForm2: hasAccess(routes.forms2) && {
                header: FORM_NAME.form2,
                data: getChartState({
                  labels: labelsForm2,
                  data: dataForm2,
                }),
                total: getTotals(dataForm2),
              },
              chartForm3: hasAccess(routes.forms3) && {
                header: FORM_NAME.form3,
                data: getChartState({
                  labels: labelsForm3,
                  data: dataForm3,
                }),
                total: getTotals(dataForm3),
              },
              chartForm4: hasAccess(routes.forms4) && {
                header: FORM_NAME.form4,
                data: getChartState({
                  labels: ['TOTAL'],
                  data: dataForm4,
                }),
                total: getTotals(dataForm4),
              },
            });
          },
        )
        .catch((err) => {
          console.error(err);
        })
        .finally(() => this.props.setIsFetching(false));
    },
  }),
);

export default enchance(Component);
