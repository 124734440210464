// @flow
import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import type { FieldProps } from 'formik';

import type { Record } from 'types/utility';

// FIX should be in one place
const theme = createMuiTheme({
  direction: 'rtl', // Both here and <body dir="rtl">
});

const useStyles = makeStyles(() => ({
  textField: {
    width: '100%',
    // maxWidth: '320px',
    maxWidth: '500px',
  },
  inputLabel: {
    position: 'absolute',
    bottom: '-15px',
    left: '0',
  },
}));

type Props = FieldProps<any> & {
  enabledFields: Record<string, boolean>,
  label: string,
  children: React.Node,
};

const SelectField = ({
  enabledFields,
  label,
  form: { touched, errors },
  field,
  field: { name, value },
  children,
  ...props
}: Props) => {
  const classes = useStyles();
  const safeValue = value || '';

  return (
    <ThemeProvider theme={theme}>
      <div dir="rtl" className={classes.textField}>
        <TextField
          select
          disabled={enabledFields ? !enabledFields.hasOwnProperty(name) : false}
          id={name}
          name={name}
          label={label}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          helperText={touched[name] ? errors[name] : ''}
          error={errors[name] && touched[name]}
          SelectProps={{
            ...field,
            ...props,
            value: safeValue,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          FormHelperTextProps={{
            classes: {
              root: classes.inputLabel,
            },
          }}
        >
          {children}
        </TextField>
      </div>
    </ThemeProvider>
  );
};

export default SelectField;
