// @flow
import React from 'react';

import { showNotification } from 'utils/index';
import { useRouting } from 'hooks/useRouting';

type Props = {
  createItemQuery: (values: any) => Promise<any>,
};

export default <Values = any>({ createItemQuery }: Props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { history } = useRouting();

  const handleCreateItem = (values: Values) => {
    setIsLoading(true);
    return createItemQuery(values)
      .then((res) => {
        history.goBack();
        return res;
      })
      .finally(() => setIsLoading(false));
  };

  const handleBackAction = history.goBack;

  return { isLoading, handleCreateItem, handleBackAction };
};
