import { compose, withStateHandlers } from 'recompose';

const enchance = compose(
  withStateHandlers(
    {
      users: [],
    },
    {
      handleChangeUserState: (state) => (stateName, value) => ({
        ...state,
        [stateName]: value,
      }),
    },
  ),
);

export default enchance;
