// @flow
/* eslint-disable no-prototype-builtins */
import React from 'react';
import { TimePicker } from '@material-ui/pickers';
import makeStyles from '@material-ui/core/styles/makeStyles';

import type { DefaultInputProps } from 'types/DefaultInputProps';

const useStyles = makeStyles({
  root: {
    width: '60px',
  },
});

type Props = DefaultInputProps;

const TimePickerField = ({ value, ...props }: Props) => {
  const classes = useStyles();
  return (
    <TimePicker
      {...props}
      cancelLabel="ביטול"
      okLabel="עדכן"
      value={value}
      inputVariant="outlined"
      ampm={false}
      classes={{
        root: classes.root,
      }}
    />
  );
};

export default TimePickerField;
