// @flow
import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DeleteIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles(() => ({
  wrapperCircle: {
    color: '#d77e7e',
    fontSize: '14px',
    width: '30px',
    height: '30px',
    background: '#d5d5d5',
    borderRadius: '50%',
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      border: '1px solid #aaa',
      cursor: 'pointer',
    },
  },
  delete: {
    width: '15px',
    fill: '#aaa',
  },
}));

const Icon = (props: any) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapperCircle}>
      <DeleteIcon className={classes.delete} {...props} />
    </div>
  );
};

export default Icon;
