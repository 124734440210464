// @flow
import type { Record } from 'types/utility';

export default (params: Record<string, string>) => {
  const newSearchParams = new URLSearchParams();

  Object.entries(params).forEach(([name, value]) => newSearchParams.set(name, ((value: any): string)));

  return newSearchParams.toString();
};
