// @flow
import * as React from 'react';
import { useReducer } from 'react';

import type { Record } from 'types/utility';

export type Popover = {
  name: string,
  anchor: HTMLButtonElement,
  content: () => React.Node,
  record: Record<string, any>,
};
export type Modal = {
  name: string,
  title: string,
  content: () => React.Node,
  record: Record<string, any>,
};

export type PopoverOptions = {
  content: $PropertyType<Popover, 'content'>,
};

export type ModalOptions = {
  content: $PropertyType<Modal, 'content'>,
  title: $PropertyType<Modal, 'title'>,
  maxWidth: $PropertyType<Modal, 'maxWidth'>,
};

type State = {
  popover: Popover | null,
  modal: Modal | null,
};

export const usePopup = () => {
  const [{ popover, modal }, setPopup] = useReducer<State, $Shape<State>>(
    (state: State, newState: $Shape<State>): State => ({ ...state, ...newState }),
    {
      popover: null,
      modal: null,
    },
  );

  const closePopup = () => setPopup({ modal: null, popover: null });

  const submitPopup = (callback: Function) => (values: any) => {
    callback(values);
    closePopup();
  };

  return {
    popover,
    modal,
    closePopup,
    setPopup,
    submitPopup,
  };
};
