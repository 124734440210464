// @flow
import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import MenuItem from '@material-ui/core/MenuItem';

import { GreenButton } from 'components/index';
import { useRouting } from 'hooks/useRouting';
import dictionary from 'constants/dictionary';
import type { PageBy } from 'types/Pageby';
import Pagination from './Pagination/Pagination';

const useStyles = makeStyles(() => ({
  toolPanel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '15px 30px',
    marginTop: '-15px',
    fontSize: '0.8125rem',
  },
  select: {
    width: '200px',
    textAlign: 'center',
    margin: 0,
  },
  link: {
    textDecoration: 'none',
  },
}));

const theme = createMuiTheme({
  direction: 'rtl', // Both here and <body dir="rtl">
});

type Props = {|
  useProjectsData: ?{
    projects: any[],
    projectId: string,
    setProjectId: (string) => void,
  },
  createAvailable: boolean,
  createLink: string,
  rows: number,
  pagination: {
    pageBy: PageBy,
    changePage: (page: number) => void,
  },
|};

const TableToolbar = ({ createAvailable, createLink, rows, pagination, useProjectsData }: Props) => {
  const classes = useStyles();
  const { redirectTo } = useRouting();

  return (
    <div className={classes.toolPanel}>
      <Pagination rows={rows} pagination={pagination} />

      {useProjectsData && (
        <ThemeProvider theme={theme}>
          <div dir="rtl" className={classes.textField}>
            <TextField
              select
              label={dictionary.projectId}
              className={classes.select}
              value={useProjectsData.projectId}
              margin="normal"
              variant="outlined"
              onChange={(e: SyntheticInputEvent<HTMLInputElement>) => useProjectsData.setProjectId(e.target.value)}
              // inputProps={{
              //   name: selectName,
              //   id: selectName,
              // }}
            >
              {useProjectsData.projects.map((v) => (
                <MenuItem key={v.id} className={classes.selectVariants} value={v.id}>
                  {v.name}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </ThemeProvider>
      )}

      {createAvailable && createLink && <GreenButton onClick={redirectTo(createLink)}>{dictionary.create}</GreenButton>}
    </div>
  );
};

export default TableToolbar;
