import React from 'react';
import T from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Formik, Field } from 'formik';
import Table from '@material-ui/core/Table';
import MenuItem from '@material-ui/core/MenuItem';

import {
  SelectField,
  TextField,
  DatePickerField,
  TimePickerField,
  FormAddedFiles,
  FormNewFiles,
  FormActions,
} from 'components/index';
import dictionary from 'constants/dictionary';

const styles = () => ({
  form: {
    width: '100%',
    textAlign: 'right',
    overflowX: 'hidden',
  },
});

const sideMap = ['מסלול דרום', 'מסלול צפון', 'שני הכיוונים'];

const telephoneReportingForProjectManagementMap = ['בוצע', 'לא בוצע'];
const telephoneReportingOperationsTeamMap = ['בוצע', 'לא בוצע'];

export const safetyFlawDetailsOptions = [
  'בורות בכביש או בשולי הדרך. כל שקע שעומקו עולה על 10 \'מ"מ יחשב כליקוי בטיחות',
  'סימוני דרך שנמחקו או הטשטשו במידה שאינה מאפשרת את זיהויים על ידי המשתמשים בדרך',
  'שלוליות , מים עומדים במיסעה או בשולי המיסעה, הצפות',
  'פסולת מוצקה, חול, או חומרים נוזלים מסוגים שונים שנשפכו על המיסעה',
  'עמודי תאורה פגועים שיש סכנה ליציבותם',
  'עצים או צמחיה אחרת המהווים סיכון למשתמשים בדרך בגלל מיקומם',
  'חוסר יציבות של מדרונות הסמוכים לשולי הדרך או למיסעה, באופן שעלול לסכן את המשתמשים בדרך ( לדוגמא, כתוצאה מדרדרת ).',
  'תמרור או שילוט חסרים , פגועים, מוסתרים או מלוכלכים במידה  המונעת מציבור הנוסעים את המסר של אותו אלמנט',
  'ליקויים בקטעים המיועדים להולכי רגל , כמו לדוגמא הפרש גובה של למעלה מ-15 מ"מ במשטח סלול או מרוצף, כתוצאה משקיעה מקומית או אחרת.',
  'קטע מעקה בטיחות שנפגע בארוע תאונתי או בדרך אחרת. כל סטייה העולה על 5 ס"מ במיקום המעקה, או פגיעה ביציבות אחד מעמודי המעקה נחשבת כליקוי בטיחות',
  'שני עמודי תאורה או יותר ( או תורן תאורה אחד או יותר ) שאינם מתפקדים באזור שבתחום צומת או מחלף או אזור סיכון אחר (ועד למרחק של 50 מטר מתחום זה)',
  'יותר משני עמודי תאורה עומקים שאינם מתפקדים, בקטעים אחרים של הדרך',
  'תקלות במערכות חשמליות המהוות סכנה למשתמשים  בדרך או לאחרים',
  'תקלות במערכות בקרה העלולות להוות סכנה למשתמשים בדרך או לאחרים',
  'אחר',
];

const onSafetyFlawChange = (e, setFieldValue) => {
  setFieldValue('safetyFlawDetails', e.target.value);
  if (e.target.value !== dictionary.otherSafetyFlaw) {
    setFieldValue('otherSafetyFlaw', '');
  }
};

const responsiblePersonMap = ['מנהל פרויקט', 'קבלן', 'מתכנן'];
const telephoneReportingToTheOperationsTeamForTerminationOfTreatmentMap = ['בוצע', 'לא בוצע'];

const Form2 = ({
  classes,
  onSubmit,
  initialValues,
  handleBackAction,
  newFiles,
  showFiles,
  handleAddNewFile,
  handleDeleteFile,
  handleViewFile,
  addedFiles,
  canAddFiles,
  handleNewFileChange,
  handleNewFileDescriptionChange,
  handleNewDeleteFile,
  showSubmit,
  showSign,
  handleSignForm,
  code,
  handleCodeStateChange,
  validationSchema,
  imageBefore,
  imageAfter,
  handleImagesChange,
  enabledFields,
  showRollBack,
  handleRollBack,
}) => {
  console.log('enabledFields', enabledFields);
  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ handleSubmit, values, setFieldValue }) => {
        return (
          <form onSubmit={handleSubmit} className={classes.form}>
            <Table>
              <tbody>
                <tr>
                  <td width="25%" />
                  <td width="25%" />
                  <td width="25%">
                    <Field
                      component={TimePickerField}
                      name="eventHappeningTime"
                      label={dictionary.eventHappeningTime}
                      enabledFields={enabledFields}
                    />
                  </td>
                  <td width="25%">
                    <Field
                      component={DatePickerField}
                      name="eventHappeningDate"
                      label={dictionary.eventHappeningDate}
                      enabledFields={enabledFields}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
            <Table>
              <tbody>
                <tr>
                  <td width="40%" align-items="rigth">
                    <Field
                      component={TextField}
                      name="element"
                      label={dictionary.element}
                      enabledFields={enabledFields}
                    />
                  </td>

                  <td width="35%">
                    <Field
                      component={TextField}
                      name="location"
                      label={dictionary.locationEclipce}
                      enabledFields={enabledFields}
                    />
                  </td>

                  <td width="25%">
                    <Field component={SelectField} name="side" label={dictionary.side} enabledFields={enabledFields}>
                      {sideMap.map((value, index) => (
                        <MenuItem key={index} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Field>
                  </td>
                </tr>
              </tbody>
            </Table>
            <Table>
              <tbody>
                <tr>
                  <td width="30%">
                    <Field
                      component={SelectField}
                      name="telephoneReportingForProjectManagement"
                      label={dictionary.telephoneReportingForProjectManagement}
                      enabledFields={enabledFields}
                    >
                      {telephoneReportingForProjectManagementMap.map((value, index) => (
                        <MenuItem key={index} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Field>
                  </td>

                  <td width="30%">
                    <Field
                      component={SelectField}
                      name="telephoneReportingOperationsTeam"
                      label={dictionary.telephoneReportingOperationsTeam}
                      enabledFields={enabledFields}
                    >
                      {telephoneReportingOperationsTeamMap.map((value, index) => (
                        <MenuItem key={index} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Field>
                  </td>
                  <td width="40%">
                    <Field
                      component={SelectField}
                      name="safetyFlawDetails"
                      label={dictionary.safetyFlawDetails}
                      enabledFields={enabledFields}
                      onChange={(e) => onSafetyFlawChange(e, setFieldValue)}
                    >
                      {safetyFlawDetailsOptions.map((value, index) => (
                        <MenuItem key={index} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Field>
                  </td>
                </tr>
              </tbody>
            </Table>
            {values.safetyFlawDetails === dictionary.otherSafetyFlaw && (
              <Field
                component={TextField}
                name="otherSafetyFlaw"
                label={dictionary.otherSafetyFlaw}
                enabledFields={enabledFields}
              />
            )}
            <Field
              component={TextField}
              name="correctiveAction"
              label={dictionary.correctiveAction}
              enabledFields={enabledFields}
            />
            <Table>
              <tbody>
                <tr>
                  <td width="25%" />
                  <td width="25%" />
                  <td width="25%" />
                  <td width="25%">
                    <Field
                      component={SelectField}
                      name="responsiblePerson"
                      label={dictionary.responsiblePerson}
                      enabledFields={enabledFields}
                    >
                      {responsiblePersonMap.map((value, index) => (
                        <MenuItem key={index} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Field>
                  </td>
                </tr>
              </tbody>
            </Table>
            <Field
              component={TextField}
              name="descriptionOfPerformedCorrectiveAction"
              label={dictionary.descriptionOfPerformedCorrectiveAction}
              enabledFields={enabledFields}
            />
            <Table>
              <tbody>
                <tr>
                  <td width="25%" />
                  <td width="25%" />
                  <td width="25%">
                    <Field
                      component={TimePickerField}
                      name="endOfTreatmentTime"
                      label={dictionary.endOfTreatmentTime}
                      enabledFields={enabledFields}
                    />
                  </td>
                  <td width="25%">
                    <Field
                      component={DatePickerField}
                      name="treatmentDateEnd"
                      label={dictionary.treatmentDateEnd}
                      enabledFields={enabledFields}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
            <Table>
              <tbody>
                <tr>
                  <td width="25%" />
                  <td width="25%" />
                  <td width="25%" />
                  <td width="25%">
                    <Field
                      component={SelectField}
                      name="telephoneReportingToTheOperationsTeamForTerminationOfTreatment"
                      label={dictionary.telephoneReportingToTheOperationsTeamForTerminationOfTreatment}
                      enabledFields={enabledFields}
                    >
                      {telephoneReportingToTheOperationsTeamForTerminationOfTreatmentMap.map((value, index) => (
                        <MenuItem key={index} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Field>
                  </td>
                </tr>
              </tbody>
            </Table>
            <Field
              component={TextField}
              name="remarks"
              label={dictionary.remarks}
              multiline
              enabledFields={enabledFields}
            />
            {showFiles && (
              <FormAddedFiles
                addedFiles={addedFiles}
                filesTableHandler={handleViewFile}
                deleteFileHandler={handleDeleteFile}
                canAddFiles={canAddFiles}
              />
            )}
            <FormNewFiles
              newFiles={newFiles}
              onNewFileChange={handleNewFileChange}
              onNewFileDescChange={handleNewFileDescriptionChange}
              deleteNewFileHandler={handleNewDeleteFile}
              onAddFile={handleAddNewFile}
              canAddFiles={canAddFiles}
              imageBefore={imageBefore}
              imageAfter={imageAfter}
              handleImagesChange={handleImagesChange}
            />
            <FormActions
              newGeneration
              showSubmit={showSubmit}
              showSign={showSign}
              onSubmit={onSubmit}
              handleBackAction={handleBackAction}
              handleSignForm={handleSignForm}
              code={code}
              handleCodeStateChange={handleCodeStateChange}
              initialValues={initialValues}
              showRollBack={showRollBack}
              handleRollBack={handleRollBack}
            />
          </form>
        );
      }}
    </Formik>
  );
};

Form2.propTypes = {
  newFiles: T.arrayOf(T.any).isRequired,
  showFiles: T.bool.isRequired,
  handleAddNewFile: T.func.isRequired,
  handleDeleteFile: T.func,
  handleViewFile: T.func,
  addedFiles: T.arrayOf(T.any),
  canAddFiles: T.bool.isRequired,
  handleNewFileChange: T.func.isRequired,
  handleNewFileDescriptionChange: T.func.isRequired,
  handleNewDeleteFile: T.func.isRequired,
  showSubmit: T.bool.isRequired,
  showSign: T.bool,
  validationSchema: T.objectOf(T.any),
  imageBefore: T.objectOf(T.any),
  imageAfter: T.objectOf(T.any),
  handleImagesChange: T.func.isRequired,
  handleSignForm: T.func,
  code: T.objectOf(T.any),
  handleCodeStateChange: T.func,
  currentItem: T.objectOf(T.any),
  classes: T.objectOf(T.string).isRequired,
  initialValues: T.objectOf(T.any),
  onSubmit: T.func.isRequired,
  handleBackAction: T.func.isRequired,
  enabledFields: T.objectOf(T.any),
  showRollBack: T.bool.isRequired,
  handleRollBack: T.func.isRequired,
};

Form2.defaultProps = {
  imageBefore: null,
  imageAfter: null,
  showSign: false,
  addedFiles: [],
  handleViewFile: null,
  handleDeleteFile: null,
  handleSignForm: null,
  handleCodeStateChange: null,
  code: {},
  currentItem: {},
  validationSchema: null,
  initialValues: null,
  enabledFields: null, // NOTE: if !!enabledFields === false => all fields are enabled (for create page)
};

export default withStyles(styles)(Form2);
