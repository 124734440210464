// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import routes from 'pages/routes';
import { toOffsettedTimestamp, setParams, showNotification } from 'utils/index';
import dictionary from 'constants/dictionary';
import dictionaryPhrases from 'constants/dictionary.phrases';
import { ADMIN, SUPER_ADMIN } from 'constants/global-roles';
import { form14StatusAliases } from 'constants/formStatus';
import dependenciesForm1 from 'constants/form1Setup';
import { useRouting } from 'hooks/useRouting';
import useProjects from 'hooks/useProjects';
import useTable from 'hooks/useTable';
import useRoleContext from 'hooks/useRoleContext';
import api from 'api/index';
import { EditIcon, WatchIcon, DeleteIcon, List } from 'components/index';
import type { Column } from 'types/frontTypes';
import type { TrafficAccident } from 'types/ServerTypes/TrafficAccident';

const useStyles = makeStyles({
  buttons: {
    whiteSpace: 'nowrap',
    width: 120,
    display: 'flex',
    justifyContent: 'space-around',
  },
});

const deleteForm1Query = (formId) => ({
  query: `mutation deleteHandlingTrafficAccident{
    deleteHandlingTrafficAccident(
      formId:"${formId}"
    )
  }`,
});

const getAllForm1Query = ({ projectId, status, failesSafetyNo, startDate, finishDate, pageBy }) => ({
  query: `query{
    findAllHandlingTrafficAccident(
      finder:{
        ${projectId ? `projectId: "${projectId}"` : ''}
        ${status ? `status: ${status}` : ''}
        ${failesSafetyNo ? `failesSafetyNo: ${failesSafetyNo}` : ''}
        ${startDate ? `startDate: ${toOffsettedTimestamp(startDate)}` : ''}
        ${finishDate ? `finishDate: ${toOffsettedTimestamp(finishDate)}` : ''}
      }
      pageable:{
        sort:{
          column:"failesSafetyNo"
          direction:DESC
        }
        page:{
          number:${pageBy.page}
          size: ${pageBy.rowsPerPage}
        }
      }
    ){
      forms{
        id
        updateAt
        updateBy{
          firstName,
          lastName
        }
        createBy{
          firstName,
          lastName
        }
        failesSafetyNo
        openingDate
        location
        datailOfTheAccident
        status
        mainContractor
        contractNumber
      }
      size
    }
  }`,
});

export default () => {
  const { formatRoute } = useRouting();
  const { role } = useRoleContext();
  const { projects, projectId, setProjectId } = useProjects();
  const classes = useStyles();

  const createLink = `${routes.form1Create.path}?${setParams({ projectId })}`;
  const isShowDelete = [ADMIN, SUPER_ADMIN].includes(role);
  const createAvailable = projects.length > 0 && dependenciesForm1[role].createAvailable;

  const {
    data,
    pagination,
    submitFilters,
    popup,
    popup: { openPopover },
  } = useTable({
    getItemsQuery: (values: any) =>
      api
        .fetchQuery(getAllForm1Query({ ...values, projectId }))
        .then(({ findAllHandlingTrafficAccident: { forms, size } }) => ({ data: forms, size })),
    deleteItemQuery: (id) =>
      api.fetchQuery(deleteForm1Query(id)).then(() => {
        showNotification(dictionaryPhrases.deleteFormSuccess);
      }),
    deps: [projectId],
  });

  const columns: Column<TrafficAccident>[] = [
    {
      title: '',
      key: 'action',
      render: (record) => {
        return (
          <div className={classes.buttons}>
            <Link to={formatRoute(routes.form1Page.path, { id: record.id })}>
              {record.status === 'OPERATOR_SUCCESS' ? <EditIcon /> : <WatchIcon />}
            </Link>
            {isShowDelete && <DeleteIcon onClick={openPopover('deleteItem', record)} />}
          </div>
        );
      },
    },
    {
      title: 'סטאטוס',
      key: 'openClosed',
      render: (record) => dictionary[form14StatusAliases[record.status]],
    },
    'updateAt',
    {
      title: dictionary.updateBy,
      key: 'updateBy',
      render: (record) => `${record.updateBy.firstName} ${record.updateBy.lastName}`,
    },
    {
      title: dictionary.createBy,
      key: 'createBy',
      render: (record) => `${record.createBy.firstName} ${record.createBy.lastName}`,
    },
    'datailOfTheAccident',
    'openingDate',
    'location',
    'contractNumber',
    'mainContractor',
    'failesSafetyNo',
  ];

  const useProjectsData = { projects, projectId, setProjectId };
  const filtering = {
    submitFilters,
    formStatusVariants: null,
  };

  return (
    <List
      data={data}
      createLink={createLink}
      columns={columns}
      pagination={pagination}
      useProjectsData={useProjectsData}
      createAvailable={createAvailable}
      popup={popup}
      filtering={filtering}
    />
  );
};
