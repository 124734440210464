// @flow
import * as React from 'react';

import { toOffsettedTimestamp, showNotification } from 'utils/index';
import { FORM_HEADER, FORM_TYPE } from 'constants/formSetup';
import dependenciesForm2 from 'constants/form2Setup';
import dictionary from 'constants/dictionary';
import dictionaryPhrases from 'constants/dictionary.phrases';
import useCreateForm from 'hooks/useCreateForm';
import useNewFiles from 'hooks/useNewFiles';
import { useRouting } from 'hooks/useRouting';
import useRoleContext from 'hooks/useRoleContext';
import { DashboardContentWrapper } from 'components/index';
import api from 'api/index';

import Form from 'pages/FailesSafety/FailesSafetyForm/FailesSafetyForm';
import { initialValuesOperator } from 'pages/FailesSafety/FailesSafetyForm/FailesSafety.schema';

const createForm2Query = ({
  projectId,
  side,
  location,
  element,
  safetyFlawDetails,
  otherSafetyFlaw,
  telephoneReportingForProjectManagement,
  telephoneReportingOperationsTeam,
  remarks,
  eventHappeningTime,
  eventHappeningDate,
  imageBefore = dictionary.no,
  imageAfter = dictionary.no,
}) => {
  return {
    query: `mutation createFailesSafety {
      createFailesSafety(
        projectId: "${projectId}"
        side: "${side}"
        location: "${location}"
        element: "${element}"
         ${
           safetyFlawDetails === dictionary.otherSafetyFlaw
             ? `safetyFlawDetails: "${otherSafetyFlaw}"`
             : `safetyFlawDetails: "${safetyFlawDetails}"`
         }
        telephoneReportingForProjectManagement: "${telephoneReportingForProjectManagement}"
        telephoneReportingOperationsTeam: "${telephoneReportingOperationsTeam}"
        remarks: "${remarks}"
        eventHappeningTime: "${toOffsettedTimestamp(eventHappeningTime)}"
        eventHappeningDate: "${toOffsettedTimestamp(eventHappeningDate)}"
        imageBefore: "${imageBefore}"
        imageAfter: "${imageAfter}"
      ) {
        id
        projectId {
          id
        }
      }
    }`,
  };
};

export default () => {
  const { getParams } = useRouting();
  const { projectId } = getParams(['projectId']);
  const { role } = useRoleContext();
  const {
    newFiles,
    handleAddNewFile,
    handleNewDeleteFile,
    handleNewFileChange,
    handleNewFileDescriptionChange,
  } = useNewFiles();
  const { handleCreateItem, handleBackAction } = useCreateForm({
    createItemQuery: (values) =>
      api.fetchQuery(createForm2Query(values)).then(() => showNotification(dictionaryPhrases.createFormSuccess)),
    newFiles,
    formType: FORM_TYPE.form2,
    createListItemResponseName: 'createFailesSafety',
    projectId: projectId || '',
  });

  return (
    <DashboardContentWrapper header={FORM_HEADER.form2.create}>
      <Form
        handleBackAction={handleBackAction}
        initialValues={initialValuesOperator}
        onSubmit={handleCreateItem}
        canAddFiles
        showSubmit
        showFiles={false}
        validationSchema={dependenciesForm2[role].validationSchema}
        enabledFields={dependenciesForm2[role].enabledFields}
        handleAddNewFile={handleAddNewFile}
        handleNewDeleteFile={handleNewDeleteFile}
        handleNewFileChange={handleNewFileChange}
        handleNewFileDescriptionChange={handleNewFileDescriptionChange}
        newFiles={newFiles}
      />
    </DashboardContentWrapper>
  );
};
