import * as Yup from 'yup';

export const validationSchemaCreate = Yup.object().shape({
  email: Yup.string().email('Email is invalid').required('Required'),
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  phone: Yup.string().required('Required'),
  globalRole: Yup.string().required('Required'),
  fileForCode: Yup.mixed(),
  contact: Yup.boolean(),
});

export const initialValuesCreate = {
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  globalRole: '',
  fileForCode: undefined,
  contact: false,
};
export const validationSchemaUpdate = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  phone: Yup.string().required('Required'),
  globalRole: Yup.string().required('Required'),
  fileForCode: Yup.mixed(),
  contact: Yup.boolean(),
});

export const initialValuesUpdate = {
  firstName: '',
  lastName: '',
  phone: '',
  globalRole: '',
  fileForCode: undefined,
  contact: false,
};
