import * as Yup from 'yup';
import dictionary from 'constants/dictionary';

export const schemaOperator = Yup.object().shape({
  eventHappeningDate: Yup.date().nullable(),
  eventHappeningTime: Yup.date().nullable(),
  element: Yup.string().required('Required'),
  location: Yup.string().required('Required'),
  side: Yup.string().required('Required'),
  telephoneReportingForProjectManagement: Yup.string().required('Required'),
  telephoneReportingOperationsTeam: Yup.string().required('Required'),
  safetyFlawDetails: Yup.string().required('Required'),
  otherSafetyFlaw: Yup.string().when('safetyFlawDetails', {
    is: dictionary.otherSafetyFlaw,
    then: Yup.string().required('Required'),
    otherwise: Yup.string(),
  }),
  remarks: Yup.string().required('Required'),
});

export const initialValuesOperator = {
  eventHappeningDate: new Date(),
  eventHappeningTime: new Date(),
  element: '',
  location: '',
  side: '',
  telephoneReportingForProjectManagement: '',
  telephoneReportingOperationsTeam: '',
  safetyFlawDetails: '',
  otherSafetyFlaw: '',
  remarks: '',
  endOfTreatmentTime: null,
  treatmentDateEnd: null,
};

export const createEnabledFields = {
  eventHappeningDate: new Date(),
  eventHappeningTime: new Date(),
  element: '',
  location: '',
  side: '',
  telephoneReportingForProjectManagement: '',
  telephoneReportingOperationsTeam: '',
  safetyFlawDetails: '',
  otherSafetyFlaw: '',
  remarks: '',
};

export const initialValuesOperatorSuccess = {
  element: '',
  location: '',
  side: '',
  telephoneReportingForProjectManagement: '',
  telephoneReportingOperationsTeam: '',
  safetyFlawDetails: '',
  otherSafetyFlaw: '',
  // testCode: '',
  remarks: '',
};

export const schemaQc = Yup.object().shape({
  correctiveAction: Yup.string()
    .nullable()
    .required('Required'),
  responsiblePerson: Yup.string()
    .nullable()
    .required('Required'),
  descriptionOfPerformedCorrectiveAction: Yup.string()
    .nullable()
    .required('Required'),
  endOfTreatmentTime: Yup.date()
    .nullable()
    .required('Required'),
  treatmentDateEnd: Yup.date()
    .nullable()
    .required('Required'),
  telephoneReportingToTheOperationsTeamForTerminationOfTreatment: Yup.string()
    .nullable()
    .required('Required'),
});

export const initialValuesQc = {
  correctiveAction: '',
  responsiblePerson: '',
  descriptionOfPerformedCorrectiveAction: '',
  endOfTreatmentTime: null,
  treatmentDateEnd: null,
  telephoneReportingToTheOperationsTeamForTerminationOfTreatment: '',
};
