// @flow
import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

interface Props {
  handleCancel: (event: any) => void;
  handleConfirm: (event: SyntheticEvent<*>) => void;
}

const ConfirmContent = ({ handleCancel, handleConfirm }: Props) => {
  return (
    <>
      <Box my={1} display="flex" justifyContent="center">
        <Typography>Delete</Typography>
      </Box>

      <Button color="primary" variant="text" onClick={handleCancel}>
        Cancel
      </Button>

      <Button color="primary" variant="contained" onClick={handleConfirm}>
        Confirm
      </Button>
    </>
  );
};

export default ConfirmContent;
