import React from 'react';
import T from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';

import ContentText from './ContentText/ContentText';

const styles = (theme) => ({
  agreementGroup: {
    flexDirection: 'row',
  },
  buttonGroup: {
    alignSelf: 'flex-end',

    '& > button': {
      marginRight: '15px',
    },

    '& button:last-child': {
      marginRight: 0,
    },
  },
  actionsWrapper: {
    flexDirection: 'column',
  },
});

const TermsAndConditions = ({
  isOpenTerms,
  handleCancelAgreement,
  handleApplyAgreement,
  agreementValue,
  handleChangeAgreement,
  classes,
}) => (
  <Dialog
    maxWidth="xl"
    open={isOpenTerms}
    onClose={handleCancelAgreement}
    scroll="body"
    aria-labelledby="scroll-dialog-title"
  >
    <DialogTitle id="scroll-dialog-title">
      Please read down to the end to accept Terms and Conditions
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        <ContentText />
      </DialogContentText>
    </DialogContent>

    <DialogActions className={classes.actionsWrapper}>
      <FormControl component="fieldset">
        <RadioGroup
          name="agreement"
          className={classes.agreementGroup}
          value={agreementValue}
          onChange={handleChangeAgreement}
        >
          <FormControlLabel value="agree" control={<Radio />} label="Agree" />
          <FormControlLabel value="notAgree" control={<Radio />} label="Not Agree" />
        </RadioGroup>
      </FormControl>

      <div className={classes.buttonGroup}>
        <Button onClick={handleCancelAgreement} variant="contained" color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleApplyAgreement}
          variant="contained"
          color="primary"
          disabled={agreementValue === 'notAgree'}
        >
          Apply
        </Button>
      </div>
    </DialogActions>
  </Dialog>
);

TermsAndConditions.propTypes = {
  isOpenTerms: T.bool.isRequired,
  handleCancelAgreement: T.func.isRequired,
  handleApplyAgreement: T.func.isRequired,
  agreementValue: T.string.isRequired,
  handleChangeAgreement: T.func.isRequired,
  classes: T.objectOf(T.any).isRequired,
};

export default withStyles(styles)(TermsAndConditions);
