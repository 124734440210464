// @flow
import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import { Formik, Field } from 'formik';

import { SelectField, TextField, BlueButton, DatePickerField } from 'components/index';
import dictionary from 'constants/dictionary';
import type { AnyObject } from 'types/frontTypes';
import validationSchema, { initialValues } from './AdditionalFilters.schema';

const useStyles = makeStyles((theme) => ({
  background: {
    background: 'inherit',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  form: {
    width: '100%',
  },
  formActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px 0 0 0',
  },
  formActionsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: '20%',
  },
}));

type Props = {
  filtering: {
    submitFilters: (values: AnyObject) => void,
    formStatusVariants: ?(string[]),
  },
};

const AdditionalFilters = ({ filtering: { submitFilters, formStatusVariants } }: Props) => {
  const classes = useStyles();

  return (
    <div>
      <ExpansionPanel className={classes.background}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <div>
            <Typography className={classes.heading}>{dictionary.additionalFilters}</Typography>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={submitFilters}
            validationSchema={validationSchema}
          >
            {({ handleSubmit, setValues }) => {
              return (
                <form onSubmit={handleSubmit} className={classes.form}>
                  {/* <div className={classes.formFieldsWrapper}> */}
                  <Table>
                    <tbody>
                      <tr>
                        <td width="25%">
                          {formStatusVariants && (
                            <Field component={SelectField} name="status" label={dictionary.status}>
                              {formStatusVariants.map((value, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <MenuItem key={index} value={value}>
                                  {dictionary[value]}
                                </MenuItem>
                              ))}
                            </Field>
                          )}
                        </td>
                        <td width="25%">
                          <Field component={TextField} name="failesSafetyNo" label={dictionary.failesSafetyNo} />
                        </td>
                        <td width="25%">
                          <Field component={DatePickerField} name="startDate" label={dictionary.fromDate} />
                        </td>
                        <td width="25%">
                          <Field component={DatePickerField} name="finishDate" label={dictionary.toDate} />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  {/* </div> */}
                  <div className={classes.formActions}>
                    <div className={classes.formActionsWrapper}>
                      <BlueButton onClick={() => setValues(initialValues)}>{dictionary.clear}</BlueButton>
                      <BlueButton type="submit">{dictionary.filter}</BlueButton>
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

export default AdditionalFilters;
