import { compose, withHandlers, withProps, withStateHandlers } from 'recompose';
import { getImagesInfo, getFilesFromImagesInfo } from 'utils/index';

import api from 'api/index';
import { FORM_NAME, FORM_TYPE } from 'constants/formSetup';
import routes from 'pages/routes';

const deleteFileForm1Query = (fileId, formId) => ({
  query: `mutation {
    deleteFile(
      formId:"${formId}"
      fileId:"${fileId}"
    ) {
      id
    }
  }`,
});

const deleteFileForm2Query = (fileId, formId) => ({
  query: `mutation {
    deleteFileFailesSafety(
      formId: "${formId}"
      fileId: "${fileId}"
    ) {
      id
    }
  }`,
});

const deleteFileForm3Query = (fileId, formId) => ({
  query: `mutation {
    deleteFileTreatmentOfImpairedByMonitoringReport(
      formId: "${formId}"
      fileId: "${fileId}"
    ) {
      id
    }
  }`,
});

const deleteFileForm4Query = (fileId, formId) => ({
  query: `mutation {
    deleteFileHandlingTrafficEvent(
      formId: "${formId}"
      fileId: "${fileId}"
    ) {
      id
    }
  }`,
});

const enchance = compose(
  withStateHandlers(
    {
      addedFiles: [],
    },
    {
      // NOTE: it is neccessary to get props withNewFiles falls down there
      // NOTE: it is like observer pattern, newFiles depend on attached files;
      setAddedFiles: (state, props) => (newAddedFilesArray, canAddFiles) => {
        if (canAddFiles && [routes.form2Page.path, routes.form3Page.path].includes(props.match.path)) {
          props.setImagesState(getFilesFromImagesInfo(getImagesInfo(newAddedFilesArray)));
        }

        return { addedFiles: newAddedFilesArray };
      },
    },
  ),
  withProps((props) => {
    const config = {
      [routes.form1Page.path]: {
        formName: FORM_NAME.form1,
        formType: FORM_TYPE.form1,
        deleteFileQuery: (fileId, formId) => deleteFileForm1Query(fileId, formId),
      },
      [routes.form2Page.path]: {
        formName: FORM_NAME.form2,
        formType: FORM_TYPE.form2,
        deleteFileQuery: (fileId, formId) => deleteFileForm2Query(fileId, formId),
      },
      [routes.form3Page.path]: {
        formName: FORM_NAME.form3,
        formType: FORM_TYPE.form3,
        deleteFileQuery: (fileId, formId) => deleteFileForm3Query(fileId, formId),
      },
      [routes.form4Page.path]: {
        formName: FORM_NAME.form4,
        formType: FORM_TYPE.form4,
        deleteFileQuery: (fileId, formId) => deleteFileForm4Query(fileId, formId),
      },
    };

    return { ...config[props.match.path] };
  }),
  withHandlers({
    handleViewFile: (props) => ({ id: fileId }) => {
      api.openFile(props.formType, props.match.params.id, fileId);
    },
  }),
);

export default enchance;
