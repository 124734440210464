/* eslint-disable consistent-return */
import { compose, withProps } from 'recompose';

import { toOffsettedTimestamp, showNotification, fromUTC } from 'utils/index';
import { OPERATOR_SUCCESS, OPERATOR_SUBMIT, QC_SUCCESS, QC_SUBMIT } from 'constants/formStatus';
import { FORM_TYPE, FORM_HEADER } from 'constants/formSetup';
import enabledDependencyForm2 from 'constants/form2Setup';
import dictionary from 'constants/dictionary';
import { withLogProps, withFormPage } from 'hoc/index';
import dictionaryPhrases from 'constants/dictionary.phrases';

import Form2Content, { safetyFlawDetailsOptions } from '../FailesSafetyForm/FailesSafetyForm';
import Component from './FormPage';

export const rollBackForm2Query = (formId) => ({
  query: `mutation {
    revertStatusForm(
      formId: "${formId}"
      formType: SECOND_FORM
    )
  }`,
});

const getForm2Query = (id) => ({
  query: `query {
    findAllFailesSafety(
      finder:{
        id:"${id}"
      }
      ) {
        forms{
          id
          projectId{
            id
            name
          }
          projectName
          contractNumber
          failesSafetyNo
          openingName
          openingDate
          openingHour
          element
          location
          side
          telephoneReportingForProjectManagement
          telephoneReportingOperationsTeam
          safetyFlawDetails
          imageBefore
          correctiveAction
          responsiblePerson
          descriptionOfPerformedCorrectiveAction
          telephoneReportingToTheOperationsTeamForTerminationOfTreatment
          imageAfter
          endOfTreatmentTime
          treatmentDateEnd
          remarks
          status
          eventHappeningTime
          eventHappeningDate
          openClosedStatus
          finishDate
          documents{
            id
            type
            nameWithExtension
            description
          }
        }
      size
    }
  }`,
});

const updateForm2OperatorQuery = (props) => {
  const {
    formId,
    side,
    location,
    element,
    telephoneReportingForProjectManagement,
    telephoneReportingOperationsTeam,
    remarks,
    imageBefore,
    imageAfter,
    /*
      it is neseccery for case when safetyFlawDetails wasn't changed (so it doesn't exist in updated fields),
      and we need to check its value
    */
    newFormFields,
  } = props;

  return {
    query: `mutation {
      updateFormOperatorFailesSafety(
        formId: "${formId}"
        ${imageBefore ? `imageBefore: "${imageBefore}"` : ''}
        ${imageAfter ? `imageAfter: "${imageAfter}"` : ''}
        ${side ? `side: "${side}"` : ''}
        ${location ? `location: "${location}"` : ''}
        ${element ? `element: "${element}"` : ''}
        ${`safetyFlawDetails: ${
          newFormFields.safetyFlawDetails === dictionary.otherSafetyFlaw
            ? `"${newFormFields.otherSafetyFlaw}"`
            : `"${newFormFields.safetyFlawDetails}"`
        }`}
        ${
          telephoneReportingForProjectManagement
            ? `telephoneReportingForProjectManagement: "${telephoneReportingForProjectManagement}"`
            : ''
        }
        ${
          telephoneReportingOperationsTeam
            ? `telephoneReportingOperationsTeam: "${telephoneReportingOperationsTeam}"`
            : ''
        }
        ${remarks ? `remarks: "${remarks}"` : ''}
      ) {
        id
      }
    }`,
  };
};

const updateForm2QCQuery = ({
  formId,
  imageBefore,
  correctiveAction,
  responsiblePerson,
  descriptionOfPerformedCorrectiveAction,
  endOfTreatmentTime,
  treatmentDateEnd,
  telephoneReportingToTheOperationsTeamForTerminationOfTreatment,
  imageAfter,
}) => {
  console.log('updateFormQCFailesSafety', treatmentDateEnd, endOfTreatmentTime);
  return {
    query: `mutation {
      updateFormQCFailesSafety(
        formId: "${formId}"
        ${imageBefore ? `imageBefore: "${imageBefore}"` : ''}
        ${correctiveAction ? `correctiveAction: "${correctiveAction}"` : ''}
        ${responsiblePerson ? `responsiblePerson: "${responsiblePerson}"` : ''}
        ${
          descriptionOfPerformedCorrectiveAction
            ? `descriptionOfPerformedCorrectiveAction: "${descriptionOfPerformedCorrectiveAction}"`
            : ''
        }
        ${treatmentDateEnd ? `treatmentDateEnd: "${toOffsettedTimestamp(treatmentDateEnd)}"` : ''}
        ${endOfTreatmentTime ? `endOfTreatmentTime: "${toOffsettedTimestamp(endOfTreatmentTime)}"` : ''}
        ${
          telephoneReportingToTheOperationsTeamForTerminationOfTreatment
            ? `telephoneReportingToTheOperationsTeamForTerminationOfTreatment: "${telephoneReportingToTheOperationsTeamForTerminationOfTreatment}"`
            : ''
        }
        ${imageAfter ? `imageAfter: "${imageAfter}"` : ''}
      ) {
        id
      }
    }`,
  };
};

const submitOperatorStatusForm2Query = (id) => ({
  query: `mutation {
    submitOperatorStatusFailesSafety(
      failesSafetyId: "${id}"
    ) {
      id
    }
  }`,
});

const submitQCStatusForm2Query = (id, confirmationCode) => ({
  query: `mutation {
    submitQCStatusFailesSafety(
      failesSafetyId: "${id}"
      confirmationCode: "${confirmationCode}"
    ) {
      id
    }
  }`,
});

const submitForemanStatusForm2Query = (id, confirmationCode) => ({
  query: `mutation {
    submitForemanStatusFailesSafety(
      failesSafetyId: "${id}"
      confirmationCode: "${confirmationCode}"
      finishDate: ${Date.now()}
      endTime: ${Date.now()}
    ) {
      id
    }
  }`,
});

const enchance = compose(
  // NOTE this is config, so should be before main logic
  withProps(() => {
    return {
      headerUpdate: FORM_HEADER.form2.update,
      formType: FORM_TYPE.form2,
      dependencies: enabledDependencyForm2,
      showFiles: true,
      getCurrentPageQuery: (id) => getForm2Query(id),
      getCurrentPageResponseName: 'findAllFailesSafety',
      formContent: Form2Content,
      rollBackFormQuery: (formId) => rollBackForm2Query(formId),
      manuallyUpdateInitialValues: (values) => {
        return {
          ...values,
          treatmentDateEnd: values.treatmentDateEnd && new Date(values.treatmentDateEnd),
          endOfTreatmentTime:
            values.treatmentDateEnd &&
            values.endOfTreatmentTime &&
            fromUTC(`${values.treatmentDateEnd} ${values.endOfTreatmentTime}`),
          eventHappeningDate: new Date(values.eventHappeningDate),
          eventHappeningTime: fromUTC(`${values.eventHappeningDate} ${values.eventHappeningTime}`),
          safetyFlawDetails: safetyFlawDetailsOptions.includes(values.safetyFlawDetails)
            ? values.safetyFlawDetails
            : dictionary.otherSafetyFlaw,
          otherSafetyFlaw: safetyFlawDetailsOptions.includes(values.safetyFlawDetails) ? '' : values.safetyFlawDetails,
        };
      },
      updateCurrentPageQuery: (status) =>
        ({
          [OPERATOR_SUCCESS]: (values) => updateForm2OperatorQuery(values),
          [OPERATOR_SUBMIT]: (values) => updateForm2QCQuery(values),
          [QC_SUCCESS]: (values) => updateForm2QCQuery(values),
        }[status]),
      signCurrentPageQuery: (props) =>
        ({
          [OPERATOR_SUCCESS]: (values) => {
            props.setIsFetching(true);

            return props.handleUpdateAndSign(values, submitOperatorStatusForm2Query);
          },
          [OPERATOR_SUBMIT]: (values) => {
            if (!props.hasFilesDescriptions()) {
              showNotification(dictionaryPhrases.attachFilesWarning, 'warning');

              return Promise.reject();
            }

            props.setIsFetching(true);
            return props.handleUpdateAndSign(values, submitQCStatusForm2Query);
          },
          [QC_SUCCESS]: (values) => {
            if (!props.hasFilesDescriptions()) {
              showNotification(dictionaryPhrases.attachFilesWarning, 'warning');

              return Promise.reject();
            }

            props.setIsFetching(true);
            return props.handleUpdateAndSign(values, submitQCStatusForm2Query);
          },
          [QC_SUBMIT]: () => {
            props.setIsFetching(true);

            return props.handleSign(submitForemanStatusForm2Query);
          },
        }[props.initialValues.status]),
    };
  }),
  withFormPage,
  withLogProps,
);

export default enchance(Component);
