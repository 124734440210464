// @flow

type Contact = { isChecked: boolean, email: string };
type Valueble = { value: string };

export default (
  defaultContacts: Contact[],
  subject: Valueble = { value: ' ' },
  emailText: Valueble = { value: ' ' },
  withReport: boolean = true,
) => {
  if (!defaultContacts) {
    return '';
  }

  const emails = defaultContacts.filter((contact) => contact.isChecked).map((contact) => contact.email);

  let emailPart;

  if (emails.length > 0) {
    emailPart = `
      email: {
        subject: "${subject.value}"
        text: "${emailText.value}"
        emails: ${JSON.stringify(emails)}
        withReport: ${String(withReport)}
      }
    `;
  } else {
    emailPart = '';
  }

  return emailPart;
};
