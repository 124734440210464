import * as Yup from 'yup';

const Schema = Yup.object().shape({
  projectId: Yup.string().required('Required'),
  email: Yup.string().email('Email is invalid').required('Required'),
  name: Yup.string().required('Required'),
  position: Yup.string().required('Required'),
  enable: Yup.boolean(),
  sendSaftyErrorAlertContact: Yup.boolean(),
});

export const initialValues = {
  projectId: '',
  email: '',
  name: '',
  position: '',
  enable: true,
  sendSaftyErrorAlertContact: true,
};

export default Schema;
