import { lifecycle, compose, withProps, withState, withHandlers } from 'recompose';
import { withRouter } from 'react-router';

import { showNotification } from 'utils/index';
import api from 'api/index';
import { FORM_HEADER } from 'constants/formSetup';
import { withLogProps, withRole, withPartialUpdatingAdmins, withProjects, withUsers } from 'hoc/index';
import routes from 'pages/routes';

import { initialValues } from '../ProjectUserForm/ProjectUserForm.schema';
import Component from './ProjectUserPage';

const getCurrentUserPermissionQuery = (userPermissionId) => ({
  query: `query updateProjectUser{
    findAllProjectUser(finder:{id:"${userPermissionId}"}){
      projectId{
        id
      }
      userId{
        id
      }
      defaultContact
    }
  }`,
});

const getAllProjectsQuery = () => ({
  query: `query findAllProject{
    findAllProject{
      name
      id
      deleted
    }
  }`,
});

const getAllUsersQuery = () => ({
  query: `query findAllUser{
    findAllUser{
      id
      firstName
      lastName
      globalRole
      deleted
    }
  }`,
});

const updateProjectUserQuery = ({ id, projectId, userId, defaultContact }) => ({
  query: `mutation {
  updateProjectUser(
    id: "${id}"
    projectId: "${projectId}"
    userId: "${userId}"
    ${defaultContact !== undefined ? `defaultContact: ${defaultContact}` : ''}
    ){
      id
    }
  }
`,
});

const enchance = compose(
  withRouter,
  withRole,
  withUsers,
  withProjects,
  withPartialUpdatingAdmins,
  withState('isFetching', 'setIsFetching', false),
  withState('initialValues', 'setInitialValues', initialValues),
  withHandlers({
    handleUpdate: (props) => (values) => {
      props.setIsFetching(true);

      props
        .makePartialUpdate({
          // NOTE: projectId and userId always should be in query
          initialValues: { ...props.initialValues, projectId: '', userId: '' },
          values,
          queryCreator: updateProjectUserQuery,
          id: props.match.params.id,
        })
        .then(() => showNotification('User permission has been successfully updated!', 'success'))
        .then(() => {
          props.history.push(routes.projectUsers.path);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => props.setIsFetching(false));
    },
  }),
  withProps((props) => {
    return {
      headerUpdate: FORM_HEADER.userPermission.update,
      onSubmit: props.handleUpdate,
      handleSubmit: props.handleUpdate,
      handleBackAction: () => props.history.goBack(),
    };
  }),
  withLogProps,
  lifecycle({
    componentDidMount() {
      this.props.setIsFetching(true);

      const query1 = api.fetchQuery(getCurrentUserPermissionQuery(this.props.match.params.id)).then((res) => {
        const { projectId, userId, defaultContact } = res.findAllProjectUser[0];
        this.props.setInitialValues({
          projectId: projectId.id,
          userId: userId.id,
          defaultContact,
        });
      });

      const query2 = api.fetchQuery(getAllProjectsQuery()).then((res) => {
        this.props.handleChangeProjectState(
          'projects',
          res.findAllProject.filter((user) => !user.deleted),
        );
      });

      const query3 = api.fetchQuery(getAllUsersQuery()).then((res) => {
        this.props.handleChangeUserState(
          'users',
          res.findAllUser.filter((user) => !user.deleted),
        );
      });

      Promise.all([query1, query2, query3])
        .catch((err) => {
          console.error(err);
        })
        .finally(() => this.props.setIsFetching(false));
    },
  }),
);

export default enchance(Component);
