import * as Yup from 'yup';

export const schemaAll = Yup.object().shape({
  eventHappeningDate: Yup.date().nullable(),
  eventHappeningTime: Yup.date().nullable(),
  element: Yup.string().required('Required'),
  location: Yup.string().required('Required'),
  reportingFactor: Yup.string().required('Required'),
  side: Yup.string().required('Required'),
  datailOfTheAccident: Yup.string().required('Required'),
  mixedCars: Yup.string().required('Required'),
  callCenterReportingOperationsTeam: Yup.string().required('Required'),
  telephoneReportingForProjectManagement: Yup.string().required('Required'),
  telephoneReportingAndEmergencyPowers: Yup.string().required('Required'),
  reportByTelephoneThePolice: Yup.string().required('Required'),
  specialPatrolUnitStaffInfo: Yup.string().required('Required'),
  remarks: Yup.string(),
  treatmentDateEnd: Yup.date()
    .nullable()
    .required('Required'),
  endOfTreatmentTime: Yup.date()
    .nullable()
    .required('Required'),
});

export const initialValuesCreate = {
  eventHappeningDate: new Date(),
  eventHappeningTime: new Date(),
  element: '',
  location: '',
  reportingFactor: '',
  side: '',
  datailOfTheAccident: '',
  mixedCars: '',
  callCenterReportingOperationsTeam: '',
  telephoneReportingForProjectManagement: '',
  telephoneReportingAndEmergencyPowers: '',
  reportByTelephoneThePolice: '',
  remarks: '',
  specialPatrolUnitStaffInfo: '',
  endOfTreatmentTime: new Date(),
  treatmentDateEnd: new Date(),
};

export const initialValuesUpdate = {
  element: '',
  location: '',
  reportingFactor: '',
  side: '',
  datailOfTheAccident: '',
  mixedCars: '',
  callCenterReportingOperationsTeam: '',
  telephoneReportingForProjectManagement: '',
  telephoneReportingAndEmergencyPowers: '',
  reportByTelephoneThePolice: '',
  remarks: '',
  specialPatrolUnitStaffInfo: '',
  endOfTreatmentTime: new Date(),
  treatmentDateEnd: new Date(),
};
