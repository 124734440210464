// @flow
import { useEffect, useReducer } from 'react';
import flow from 'lodash/flow';

import type { AnyObject } from 'types/frontTypes';
import { objectDifference, showNotification } from 'utils/index';

import { useRouting } from './useRouting';

type State = {|
  isLoading: boolean,
  pageValues: AnyObject,
|};

type Props = {|
  initialValues: AnyObject,
  getPageQuery: () => Promise<AnyObject>,
  updatePageQuery: (values: any) => Promise<mixed>,
  deps?: mixed[],
  isPartialUpdate?: boolean,
  redirectRoute?: string,
  notification?: string,
|};

export default ({
  updatePageQuery,
  getPageQuery,
  initialValues,
  deps = [],
  isPartialUpdate = false,
  redirectRoute,
  notification,
}: Props) => {
  const { match, history } = useRouting();
  const [state, setState] = useReducer(
    (oldState: State, newState: $Shape<State>): State => ({ ...oldState, ...newState }),
    {
      isLoading: true,
      pageValues: initialValues,
    },
  );

  const setIsLoading = (isLoading: boolean): void => setState({ isLoading });

  const loadData = () => {
    return getPageQuery()
      .then((res) => {
        setState({
          isLoading: false,
          pageValues: {
            ...initialValues,
            ...flow(Object.entries, (array) => array.filter(([, value]) => value !== null), Object.fromEntries)(res),
          },
        });
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const updatePage = (values: AnyObject) => {
    setIsLoading(true);

    return updatePageQuery(isPartialUpdate ? objectDifference(state.pageValues, values) : values)
      .then(() => (redirectRoute ? history.push(redirectRoute) : loadData()))
      .then(() => (notification ? showNotification(notification, 'success') : null))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    loadData();
  }, [...deps, match.params.id]);

  return {
    isLoading: state.isLoading,
    setIsLoading,
    pageValues: state.pageValues,
    updatePage,
    backAction: history.goBack,
    loadPage: loadData,
  };
};
