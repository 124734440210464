import * as Yup from 'yup';

const Schema = Yup.object().shape({
  projectId: Yup.string().required('Required'),
  formType: Yup.string().required('Required'),
  fromDate: Yup.date().nullable(),
  toDate: Yup.date()
    .nullable()
    .min(Yup.ref('fromDate'), 'This date should be greater or equal to first date'),
});

export const initialValues = {
  projectId: '',
  formType: '',
  fromDate: null,
  toDate: null,
};

export default Schema;
