import React from 'react';
import T from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withStyles from '@material-ui/core/styles/withStyles';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { DefaultSelectField, DefaultTextField, DefaultCheckBoxField, BlueButton } from 'components';
import dictionary from 'constants/dictionary';
import dictionaryPhrases from 'constants/dictionary.phrases';
import { SUPER_ADMIN, ADMIN } from 'constants/global-roles';

const styles = () => ({
  topBlock: {
    display: 'flex',
    marginBottom: '10px',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyСontent: 'flex-start',
    alignItems: 'center',
    width: '50%',
    padding: '0 10px',
  },
  formLegend: {
    marginBottom: '10px',
  },
  bottomBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  textMessage: {
    width: '100%',
    direction: 'rtl',
  },
  checkbox: {
    padding: 0,
    alignSelf: 'start',
  },
  DialogActionsWrapper: {
    width: '98%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonContainer: {
    '& > button': {
      marginRight: '10px',
    },
  },
  select: {
    width: '290px',
    textAlign: 'center',
    display: 'block',
  },
  selectVariants: {
    textTransform: 'capitalize',
  },
});

const ChooseEmails = ({
  classes,
  dialogs,
  handleDialogClose,
  selectedEmail,
  onSelectChange,
  emails,
  handleAddEmail,
  defaultContacts,
  contacts,
  handleChangeEmail,
  handleSendEmail,
  handleChangeEmailFields,
  emailFields: { emailText },
  handleWithReportToggle,
  withReport,
  ...props
}) => {
  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={dialogs.isOpenEmails}
      onClose={() => handleDialogClose('isOpenEmails')}
      aria-labelledby="scroll-dialog-title"
    >
      <DialogTitle id="scroll-dialog-title">{dictionary.sendEmail}</DialogTitle>

      <DialogContent>
        <div className={classes.topBlock}>
          <div className={classes.column}>
            <FormControl component="fieldset">
              <FormLabel component="legend">{dictionary.addEmails}</FormLabel>
              <FormGroup>
                <DefaultSelectField className={classes.select} value={selectedEmail} onChange={onSelectChange}>
                  {emails.map((contact) => (
                    <MenuItem key={contact.email} className={classes.selectVariants} value={contact}>
                      {`${dictionary[contact.roleOnProject]}: ${contact.firstName} ${contact.lastName}`}
                    </MenuItem>
                  ))}
                </DefaultSelectField>
                <BlueButton onClick={handleAddEmail}>{dictionary.add}</BlueButton>
              </FormGroup>
            </FormControl>
          </div>

          <div className={classes.column}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend" className={classes.formLegend}>
                {dictionary.selectedEmails}
              </FormLabel>
              <FormGroup>
                {defaultContacts.map((contact, index) => (
                  <FormControlLabel
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    control={
                      <Checkbox
                        checked={contact.isChecked}
                        value={contact.email}
                        className={classes.checkbox}
                        onChange={handleChangeEmail}
                        color="default"
                      />
                    }
                    label={`${dictionary[contact.roleOnProject]}: ${contact.firstName} ${contact.lastName}`}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </div>

          <div className={classes.column}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend" className={classes.formLegend}>
                {dictionary.contacts}
              </FormLabel>
              <FormGroup>
                {contacts.map((contact) => (
                  <FormControlLabel
                    key={contact.id}
                    control={
                      <Checkbox
                        checked={contact.isChecked}
                        value={contact.email}
                        className={classes.checkbox}
                        onChange={handleChangeEmail}
                        color="default"
                      />
                    }
                    label={contact.email}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </div>
        </div>

        <div className={classes.bottomBlock}>
          <InputLabel htmlFor="message-text" className={classes.label}>
            {dictionary.message}
          </InputLabel>
          <DefaultTextField
            className={classes.textMessage}
            name="emailText"
            placeholder={dictionaryPhrases.emailMessagePlaceholder}
            multiline
            rowsMax="4"
            rows="4"
            value={emailText.value}
            onChange={(event) => handleChangeEmailFields('emailText', event.target.value)}
          />
        </div>
      </DialogContent>

      <DialogActions>
        <div className={classes.DialogActionsWrapper}>
          <DefaultCheckBoxField label={dictionary.withReport} onChange={handleWithReportToggle} value={withReport} />
          <div className={classes.buttonContainer}>
            <BlueButton
              onClick={handleSendEmail}
              disabled={
                !(defaultContacts.some((contact) => contact.isChecked) || contacts.some((contact) => contact.isChecked))
              }
            >
              {dictionary.sendEmail}
            </BlueButton>
            <BlueButton
              onClick={() =>
                props.setStateDialogs({ dialogs: { ...props.dialogs, isOpenEmails: false, closeDialogCallback: null } })
              }
            >
              {dictionary.close}
            </BlueButton>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
};

ChooseEmails.propTypes = {
  dialogs: T.objectOf(T.any).isRequired,
  handleDialogClose: T.func.isRequired,
  classes: T.objectOf(T.any).isRequired,
  selectedEmail: T.shape({
    defaultContact: T.bool,
    email: T.string,
    firstName: T.string,
    isChecked: T.bool,
    lastName: T.string,
    roleOnProject: T.string,
  }),
  onSelectChange: T.func.isRequired,
  emails: T.arrayOf(
    T.shape({
      defaultContact: T.bool,
      email: T.string,
      firstName: T.string,
      isChecked: T.bool,
      lastName: T.string,
      roleOnProject: T.string,
    }),
  ).isRequired,
  defaultContacts: T.arrayOf(T.any).isRequired,
  contacts: T.arrayOf(T.any).isRequired,
  handleAddEmail: T.func.isRequired,
  handleSendEmail: T.func.isRequired,
  handleChangeEmail: T.func.isRequired,
  handleChangeEmailFields: T.func.isRequired,
  emailFields: T.objectOf(T.objectOf(T.any)).isRequired,
  handleWithReportToggle: T.func.isRequired,
  withReport: T.bool.isRequired,
};

ChooseEmails.defaultProps = {
  selectedEmail: {},
};

export default withStyles(styles)(ChooseEmails);
