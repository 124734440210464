import React, { useState } from 'react';
import T from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Formik, Field } from 'formik';
import Button from '@material-ui/core/Button';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

import { TextField } from 'components/index';
import dictionary from 'constants/dictionary';
import validationSchema, { initialValues } from './LoginForm.schema';

const styles = () => ({
  signButton: {
    width: '100%',
    padding: '14px 28px',
    marginTop: '20px',
    borderRadius: '5px',
    border: '1px solid transparent',
    color: 'white',
    backgroundColor: '#52a962',
    boxShadow: '0 3px 13px rgba(82, 169, 98, 0.8)',
    cursor: 'pointer',
    transition: 'all 0.3s',

    '&:hover': {
      backgroundColor: '#62b371',
    },
  },
});

const LoginForm = ({ classes, onSubmit }) => {
  const [isShowPassword, setIsShowPassword] = useState(false);

  const showPassword = () => {
    setIsShowPassword(!isShowPassword);
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Field component={TextField} name="email" label={dictionary.email} />

            <Field
              component={TextField}
              name="password"
              label={dictionary.password}
              type={isShowPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={showPassword}>
                      {isShowPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            {/* //FIX union with shared button */}
            <Button
              className={classes.signButton}
              type="submit"
              variant="contained"
              color="primary"
            >
              {dictionary.sigIn}
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

LoginForm.propTypes = {
  classes: T.objectOf(T.string).isRequired,
  onSubmit: T.func.isRequired,
};

export default withStyles(styles)(LoginForm);
