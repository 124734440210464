import React from 'react';
import T from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Formik, Field } from 'formik';
import MenuItem from '@material-ui/core/MenuItem';

import { SelectField, TextField, Actions, CheckBoxField } from 'components/index';
import dictionary from 'constants/dictionary';
import validationSchema from './ContactForm.schema';

const styles = (theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '300px',
    margin: '0 auto',
  },
  clientCheckBox: {
    alignSelf: 'flex-end',
  },
});

const ContactForm = ({ classes, projects, onSubmit, initialValues, handleBackAction }) => {
  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit} className={classes.form}>
            <Field component={SelectField} name="projectId" label={dictionary.projectId}>
              {projects.map((value) => (
                <MenuItem key={value.id} value={value.id}>
                  {value.name}
                </MenuItem>
              ))}
            </Field>

            <Field component={TextField} name="email" label={dictionary.email} />

            <Field component={TextField} name="name" label={dictionary.firstName} />

            <Field component={TextField} name="position" label={dictionary.position} />

            <Field component={CheckBoxField} name="enable" label="Enable" className={classes.clientCheckBox} />

            <Field
              component={CheckBoxField}
              name="sendSaftyErrorAlertContact"
              label="safety error alert contact"
              className={classes.clientCheckBox}
            />

            <Actions handleBackAction={handleBackAction} />
          </form>
        );
      }}
    </Formik>
  );
};

ContactForm.propTypes = {
  classes: T.objectOf(T.string).isRequired,
  initialValues: T.objectOf(T.any).isRequired,
  projects: T.arrayOf(T.objectOf(T.any)).isRequired,
  onSubmit: T.func.isRequired,
  handleBackAction: T.func.isRequired,
};

export default withStyles(styles)(ContactForm);
