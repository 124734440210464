import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Clear';
import Paper from '@material-ui/core/Paper';
import T from 'prop-types';

import useStyles from './useStyles';
import dictionary from 'constants/dictionary';

const FormAddedFiles = ({ addedFiles, filesTableHandler, deleteFileHandler, canAddFiles }) => {
  const classes = useStyles();
  return (
    addedFiles &&
    addedFiles.length > 0 && (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.ceil}>{dictionary.file}</TableCell>
              <TableCell className={classes.ceil}>{dictionary.description}</TableCell>
              <TableCell className={classes.ceil} />
            </TableRow>
          </TableHead>
          <TableBody>
            {addedFiles.map((file, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <TableRow key={index} className={classes.tr}>
                <TableCell className={`${classes.ceil}, ${classes.inputFileCeil}`}>
                  <a className={classes.fileLink} onClick={(e) => filesTableHandler(file)}>
                    {file.nameWithExtension}
                  </a>
                </TableCell>
                <TableCell className={classes.ceil}>{file.description}</TableCell>

                <TableCell className={`${classes.ceil} ${classes.deleteCeil}`}>
                  {canAddFiles && (
                    <DeleteIcon
                      className={classes.deleteIcon}
                      onClick={() => deleteFileHandler(file.id, index)}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    )
  );
};

FormAddedFiles.propTypes = {
  addedFiles: T.arrayOf(
    T.shape({
      id: T.string,
      type: T.string,
      nameWithExtension: T.string,
      description: T.string,
      value: T.objectOf(T.any),
    }),
  ),
  filesTableHandler: T.func.isRequired,
  deleteFileHandler: T.func.isRequired,
  canAddFiles: T.bool.isRequired,
};

FormAddedFiles.defaultProps = {};

export default FormAddedFiles;
