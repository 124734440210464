// @flow
import * as React from 'react';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';

import type { Record } from 'types/utility';
import type { ButtonType } from './index';

type styleObj = {
  background: string,
  hoverBackground: string,
};

const buttonColorConfig: Record<ButtonType, styleObj> = {
  green: {
    background: '#52a962',
    hoverBackground: '#3e7a5a',
  },
  blue: {
    background: 'rgb(63, 81, 181)',
    hoverBackground: 'rgb(47, 61, 139)',
  },
  default: {
    background: 'rgb(63, 81, 181)',
    hoverBackground: 'rgb(47, 61, 139)',
  },
};

const useStyles = makeStyles({
  root: {
    border: '1px solid #e0e0e0',
    color: 'white',
    padding: '10px 28px',
    textTransform: 'capitalize',
    backgroundColor: (props) =>
      props.color in buttonColorConfig
        ? buttonColorConfig[props.color].background
        : buttonColorConfig.default.background,
    '&:hover': {
      backgroundColor: (props) =>
        props.color in buttonColorConfig
          ? buttonColorConfig[props.color].hoverBackground
          : buttonColorConfig.default.hoverBackground,
    },
  },
  disabled: {
    border: '1px solid #e0e0e0',
    color: 'white',
    padding: '10px 28px',
    textTransform: 'capitalize',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      cursor: 'initial',
    },
  },
});

export type DefaultButtonProps = {
  children: React.Node,
  color: ButtonType,
  style?: Object,
  disabled?: boolean,
};

export type ButtonProps = $Diff<DefaultButtonProps, { color: ButtonType }>;

const DefaultButton = ({ color, style, disabled, children, ...props }: DefaultButtonProps) => {
  const classes = useStyles({ color });
  return (
    <Button {...props} className={disabled ? classes.disabled : classes.root} style={style}>
      {children}
    </Button>
  );
};

export default DefaultButton;
