import React from 'react';
import T from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Formik, Field } from 'formik';
import Table from '@material-ui/core/Table';
import MenuItem from '@material-ui/core/MenuItem';

import {
  SelectField,
  TextField,
  DatePickerField,
  TimePickerField,
  FormAddedFiles,
  FormNewFiles,
  FormActions,
} from 'components/index';
import dictionary from 'constants/dictionary';

const styles = (theme) => ({
  form: {
    width: '100%',
    textAlign: 'right',
    overflowX: 'hidden',
  },
});

const sideMap = ['מסלול דרום', 'מסלול צפון', 'שני הכיוונים'];

const callCenterReportingOperationsTeamMap = ['בוצע', 'לא בוצע'];

const telephoneReportingForProjectManagementMap = ['בוצע', 'לא בוצע'];

const telephoneReportingAndEmergencyPowersMap = ['בוצע', 'לא בוצע'];

const reportByTelephoneThePoliceMap = ['בוצע', 'לא בוצע'];

const Form4 = ({
  classes,
  onSubmit,
  initialValues,
  handleBackAction,
  newFiles,
  showFiles,
  handleAddNewFile,
  handleDeleteFile,
  handleViewFile,
  addedFiles,
  canAddFiles,
  handleNewFileChange,
  handleNewFileDescriptionChange,
  handleNewDeleteFile,
  showSubmit,
  showSign,
  validationSchema,
  imageBefore,
  imageAfter,
  handleImagesChange,
  enabledFields,
  handleSignForm,
}) => {
  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit} className={classes.form}>
            <Table>
              <tbody>
                <tr>
                  <td width="25%" />
                  <td width="25%">
                    <Field
                      component={TextField}
                      enabledFields={enabledFields}
                      name="reportingFactor"
                      label={dictionary.reportingFactor}
                    />
                  </td>
                  <td width="25%">
                    <Field
                      component={TimePickerField}
                      name="eventHappeningTime"
                      label={dictionary.eventHappeningTime}
                      enabledFields={enabledFields}
                    />
                  </td>
                  <td width="25%">
                    <Field
                      component={DatePickerField}
                      name="eventHappeningDate"
                      label={dictionary.eventHappeningDate}
                      enabledFields={enabledFields}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>

            <Table>
              <tbody>
                <tr>
                  <td width="40%" align-items="rigth">
                    <Field
                      component={TextField}
                      name="element"
                      label={dictionary.element}
                      enabledFields={enabledFields}
                    />
                  </td>

                  <td width="35%">
                    <Field
                      component={TextField}
                      name="location"
                      label={dictionary.eventLocation}
                      enabledFields={enabledFields}
                    />
                  </td>

                  <td width="25%">
                    <Field component={SelectField} name="side" label={dictionary.side} enabledFields={enabledFields}>
                      {sideMap.map((value) => (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Field>
                  </td>
                </tr>
              </tbody>
            </Table>

            <Field
              component={TextField}
              name="datailOfTheAccident"
              label={dictionary.detailsOfTheEvent}
              enabledFields={enabledFields}
            />

            <Field component={TextField} name="mixedCars" label={dictionary.mixedCars} enabledFields={enabledFields} />

            <Table>
              <tbody>
                <tr>
                  <td width="25%">
                    <Field
                      component={SelectField}
                      name="callCenterReportingOperationsTeam"
                      label={dictionary.callCenterReportingOperationsTeam}
                      enabledFields={enabledFields}
                    >
                      {callCenterReportingOperationsTeamMap.map((value) => (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Field>
                  </td>

                  <td width="25%">
                    <Field
                      component={SelectField}
                      name="telephoneReportingForProjectManagement"
                      label={dictionary.telephoneReportingForProjectManagement}
                      enabledFields={enabledFields}
                    >
                      {telephoneReportingForProjectManagementMap.map((value) => (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Field>
                  </td>

                  <td width="25%">
                    <Field
                      component={SelectField}
                      name="telephoneReportingAndEmergencyPowers"
                      label={dictionary.telephoneReportingAndEmergencyPowers}
                      enabledFields={enabledFields}
                    >
                      {telephoneReportingAndEmergencyPowersMap.map((value) => (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Field>
                  </td>

                  <td width="25%">
                    <Field
                      component={SelectField}
                      name="reportByTelephoneThePolice"
                      label={dictionary.reportByTelephoneThePolice}
                      enabledFields={enabledFields}
                    >
                      {reportByTelephoneThePoliceMap.map((value) => (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Field>
                  </td>
                </tr>
              </tbody>
            </Table>

            <Table>
              <tbody>
                <tr>
                  <td width="25%" />
                  <td width="25%" />
                  <td width="25%">
                    <Field
                      component={TimePickerField}
                      name="endOfTreatmentTime"
                      label={dictionary.endOfTreatmentTime}
                      enabledFields={enabledFields}
                    />
                  </td>
                  <td width="25%">
                    <Field
                      component={DatePickerField}
                      name="treatmentDateEnd"
                      label={dictionary.treatmentDateEnd}
                      enabledFields={enabledFields}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>

            <Field
              component={TextField}
              name="remarks"
              label={dictionary.remarks}
              multiline
              enabledFields={enabledFields}
            />

            <Field
              component={TextField}
              name="specialPatrolUnitStaffInfo"
              label={dictionary.specialPatrolUnitStaffInfoL}
              enabledFields={enabledFields}
            />

            {showFiles && (
              <FormAddedFiles
                addedFiles={addedFiles}
                filesTableHandler={handleViewFile}
                deleteFileHandler={handleDeleteFile}
                canAddFiles={canAddFiles}
              />
            )}

            <FormNewFiles
              newFiles={newFiles}
              onNewFileChange={handleNewFileChange}
              onNewFileDescChange={handleNewFileDescriptionChange}
              deleteNewFileHandler={handleNewDeleteFile}
              onAddFile={handleAddNewFile}
              canAddFiles={canAddFiles}
              imageBefore={imageBefore}
              imageAfter={imageAfter}
              handleImagesChange={handleImagesChange}
            />

            <FormActions
              newGeneration
              showSubmit={showSubmit}
              showSign={showSign}
              onSubmit={onSubmit}
              signText="סגור אירוע"
              handleBackAction={handleBackAction}
              handleSignForm={handleSignForm}
            />
          </form>
        );
      }}
    </Formik>
  );
};

Form4.propTypes = {
  newFiles: T.arrayOf(T.any).isRequired,
  showFiles: T.bool.isRequired,
  handleAddNewFile: T.func.isRequired,
  handleDeleteFile: T.func,
  handleViewFile: T.func,
  addedFiles: T.arrayOf(T.any),
  canAddFiles: T.bool.isRequired,
  handleNewFileChange: T.func.isRequired,
  handleNewFileDescriptionChange: T.func.isRequired,
  handleNewDeleteFile: T.func.isRequired,
  showSubmit: T.bool.isRequired,
  showSign: T.bool,
  validationSchema: T.objectOf(T.any),
  imageBefore: T.arrayOf(T.any),
  imageAfter: T.arrayOf(T.any),
  handleImagesChange: T.func.isRequired,
  classes: T.objectOf(T.string).isRequired,
  initialValues: T.objectOf(T.any).isRequired,
  onSubmit: T.func.isRequired,
  handleBackAction: T.func.isRequired,
  enabledFields: T.objectOf(T.any),
  handleSignForm: T.func.isRequired,
};

Form4.defaultProps = {
  imageBefore: null,
  imageAfter: null,
  showSign: false,
  addedFiles: [],
  handleViewFile: null,
  handleDeleteFile: null,
  validationSchema: null,
  enabledFields: null, // NOTE: if !!enabledFields === false => all fields are enabled (for create page)
};

export default withStyles(styles)(Form4);
