// @flow
import React from 'react';
import Modal from '@material-ui/core/Modal';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(() => ({
  root: {
    width: '80%',
    overflowX: 'auto',
    fontSize: '0.8125rem',
    background: '#f6f6f6',
  },
  modal: {
    width: '100vw',
    height: '100vh',
  },
  backdrop: {
    position: 'absolute',
    transition: '200ms',
    opacity: '1',
  },
  progressWrapper: {
    dispaly: 'flex',
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    top: '48%',
    left: '48%',
    color: 'rgb(0, 172, 102)',
  },
}));

const Loading = () => {
  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open
      className={classes.modal}
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
        invisible: false,
      }}
    >
      <div className={classes.progressWrapper}>
        <CircularProgress className={classes.progress} />
      </div>
    </Modal>
  );
};

export default Loading;
