// @flow
import * as React from 'react';
import { useEffect, useReducer } from 'react';

import { useRouting } from 'hooks/useRouting';
import usePagination from 'hooks/usePagination';
import type { Record } from 'types/utility';
import { ConfirmContent } from 'components/index';
import type { AnyObject, PageBy } from 'types/frontTypes';
import { usePopup, type PopoverOptions } from 'hooks/usePopup';

type Props = {
  getItemsQuery: (values?: any) => Promise<{ data: any[], size?: number }>,
  deleteItemQuery?: (id: string) => Promise<any>,
  recoveryItemQuery?: (id: string) => Promise<any>,
  deps?: any[],
  isServerPagination?: boolean,
};
type State = {
  isLoading: boolean,
  tableItems: any[],
  rows: number,
};

export type PopoverNameList = 'deleteItem';

const getTableItems = ({
  isServerPagination,
  data,
  size,
  pageBy: { page, rowsPerPage },
}: {
  isServerPagination: boolean,
  data: any[],
  size: number,
  pageBy: PageBy,
}): any[] =>
  // NOTE: slice fetched data if server doesn't allow pagination (admin pages dont allow pageable part)
  isServerPagination
    ? data
    : data.slice(size > 0 ? (page - 1) * rowsPerPage : 0, size > 0 ? (page - 1) * rowsPerPage + rowsPerPage : 0);

export default ({ getItemsQuery, deleteItemQuery, recoveryItemQuery, deps = [], isServerPagination = true }: Props) => {
  const { match, location } = useRouting();
  const { modal, popover, setPopup, closePopup, submitPopup } = usePopup();
  const [state, setState] = useReducer(
    (oldState: State, newState: $Shape<State>): State => ({ ...oldState, ...newState }),
    {
      isLoading: false,
      tableItems: [],
      rows: 0,
    },
  );
  const { pageBy, changePage } = usePagination({ data: state.tableItems });

  const loadItems = (values: Record<string, any> = {}) => {
    setState({ isLoading: true });

    return getItemsQuery({ ...values, pageBy })
      .then(({ data, size = 0 }) =>
        setState({
          tableItems: getTableItems({ data, isServerPagination, pageBy, size }),
          rows: size,
          isLoading: false,
        }),
      )
      .catch((err) => {
        setState({ isLoading: false });
      });
  };

  useEffect(() => {
    loadItems();
  }, [pageBy, location.pathname, ...deps]);

  const deleteItem = (id: string) => () => deleteItemQuery && deleteItemQuery(id).then(loadItems);

  const recoveryItem = (id: string) => () => recoveryItemQuery && recoveryItemQuery(id).then(loadItems);

  const openPopover = (name: PopoverNameList, record: Record<string, any>) => (
    event: SyntheticMouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    const options: Record<PopoverNameList, PopoverOptions> = {
      deleteItem: {
        content: () => <ConfirmContent handleCancel={closePopup} handleConfirm={submitPopup(deleteItem(record.id))} />,
      },
    };

    setPopup({ popover: { ...options[name], name, anchor: event.currentTarget, record } });
  };

  const submitFilters = (values?: AnyObject) => {
    loadItems(values);
  };

  return {
    data: { ...state, loadItems },
    popup: {
      modal,
      popover,
      openPopover,
      closePopup,
    },
    pagination: {
      pageBy,
      changePage,
    },
    submitFilters,
    deleteItem,
    recoveryItem,
  };
};
