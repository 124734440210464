import React from 'react';
import T from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';

// NOTE this agreements list gathering dynamically by node.js
import agreements from 'temp/agreements';

const styles = (theme) => ({
  root: {
    '& a': {
      color: 'blue',
    },
  },
});

const Agreements = ({ classes }) => (
  <Table className={classes.root}>
    <TableHead>
      <TableRow>
        <TableCell>File Name</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {agreements.map((agreement, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <TableRow key={index}>
          <TableCell component="th" scope="row">
            {agreement.name}
          </TableCell>
          <TableCell align="right">
            <a href={agreement.url}>Download</a>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

Agreements.propTypes = {
  classes: T.objectOf(T.any).isRequired,
};

export default withStyles(styles)(Agreements);
