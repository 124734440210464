/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
// @flow
import _transform from 'lodash/transform';
import _isEqual from 'lodash/isEqual';
import type { Record } from 'types/utility';

// NOTE: iterate through Object1 and save not equal fields from Object2
export default (object1: Record<string, any>, object2: Record<string, any>) => {
  return _transform(object1, (result, value, key) => {
    if (!object2.hasOwnProperty(key)) return;

    if (!_isEqual(value, object2[key])) {
      result[key] = object2[key];
    }
  });
};
