import { compose, withHandlers, withStateHandlers, lifecycle } from 'recompose';
import { withRouter } from 'react-router-dom';

import { showNotification, addExtendedTextMessage, getEmailPart } from 'utils/index';
import api from 'api/index';
import { withRole, withLogProps } from 'hoc/index';
import { contactRelations } from 'constants/global-roles';
import dictionaryPhrases from 'constants/dictionary.phrases';
import Component from './ChooseEmails';

const getEmailsByProjectQuery = (id) => ({
  query: `query getEmailsByProject{
    getEmailsByProject(
      projectId:"${id}"
    ) {
      emails {
        firstName
        lastName
        email
        defaultContact
        roleOnProject
      }
      contacts {
        id
        name
        email
        position
        enable
        projectId {
          id
        }
      }
    }
  }`,
});

const sendEmailsQuery = ({ formId, formType, subject, emailText, defaultContacts, withReport }) => ({
  query: `mutation {
  	sendEmail(
      formId: "${formId}"
      formType: ${formType},
      ${getEmailPart(defaultContacts, subject, emailText, withReport)}
    )
  }`,
});

const enchance = compose(
  withRouter,
  withRole,
  withStateHandlers(
    {
      emails: [],
      defaultContacts: [],
      contacts: [],
      selectedEmail: {},
      withReport: true,

      emailFields: {
        subject: { value: '' },
        emailText: { value: '' },
      },
    },
    {
      onLoadedEmails: (state) => ({ emails, defaultContacts, contacts, selectedEmail }) => ({
        ...state,
        emails,
        defaultContacts,
        contacts,
        selectedEmail,
      }),
      handleChangeEmailsState: (state) => (stateName, value) => ({
        ...state,
        [stateName]: value,
      }),
      handleChangeEmailFields: (state) => (fieldName, value) => {
        return {
          ...state,
          emailFields: {
            ...state.emailFields,
            [fieldName]: {
              ...state.emailFields[fieldName],
              value,
            },
          },
        };
      },
    },
  ),
  withHandlers({
    handleSendEmail: (props) => () => {
      if (
        props.defaultContacts.every((contact) => !contact.isChecked) &&
        props.contacts.every((contact) => !contact.isChecked)
      ) {
        return;
      }
      const options = {
        formId: props.formId,
        formType: props.formType,
        subject: addExtendedTextMessage(
          `${props.projectName} - ${props.formName} - ${props.failesSafetyNo}`,
          props.emailFields.subject,
        ),
        emailText: addExtendedTextMessage(
          // eslint-disable-next-line max-len
          `Project: ${props.projectName}\nForm: ${props.formName}\nNumber: ${props.failesSafetyNo}\n\nLink: ${window.location.origin}\n\n`,
          props.emailFields.emailText,
        ),
        defaultContacts: [...props.defaultContacts, ...props.contacts],
        withReport: props.withReport,
      };

      props.setIsFetching(true);

      // NOTE: in form2 we postpone signing form before sending email
      if (props.dialogs.closeDialogCallback) {
        props.dialogs
          .closeDialogCallback()
          .then(() => props.setStateDialogs({ dialogs: { ...props.dialogs, closeDialogCallback: null } }));
      }

      api
        .fetchQuery(sendEmailsQuery(options))
        .then(() => {
          showNotification(dictionaryPhrases.sendEmailSuccess, 'success');

          props.handleDialogClose('isOpenEmails');
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          props.setIsFetching(false);
        });
    },
    handleAddEmail: (props) => () => {
      if (props.emails.length === 0) {
        return;
      }

      const filteredEmails = props.emails.filter((email) => email !== props.selectedEmail);

      props.handleChangeEmailsState('emails', filteredEmails);
      props.handleChangeEmailsState('defaultContacts', [
        ...props.defaultContacts,
        { ...props.selectedEmail, isChecked: true },
      ]);
      props.handleChangeEmailsState('selectedEmail', filteredEmails[0]);
    },
    onSelectChange: (props) => (event) => {
      props.handleChangeEmailsState('selectedEmail', event.target.value);
    },
    handleChangeEmail: (props) => (event) => {
      props.handleChangeEmailsState(
        'defaultContacts',
        props.defaultContacts.map((contact) => {
          if (contact.email === event.target.value) return { ...contact, isChecked: !contact.isChecked };

          return contact;
        }),
      );
      props.handleChangeEmailsState(
        'contacts',
        props.contacts.map((contact) => {
          if (contact.email === event.target.value) return { ...contact, isChecked: !contact.isChecked };

          return contact;
        }),
      );
    },
    handleWithReportToggle: (props) => (event) => {
      console.log(event.target.value);
      props.handleChangeEmailsState('withReport', !props.withReport);
    },
  }),
  withLogProps,
  lifecycle({
    componentDidMount() {
      this.props.setIsFetching(true);

      api
        .fetchQuery(getEmailsByProjectQuery(this.props.projectId))
        .then((res) => {
          const { emails, contacts } = res.getEmailsByProject;
          const defEmails = [];
          const restEmails = [];
          console.log('componentDidMount contacts', contacts);

          emails
            .map((email) => ({ ...email, isChecked: false }))
            .forEach((email) => {
              if (email.defaultContact) {
                return defEmails.push(email);
              }

              restEmails.push({ ...email });

              return null;
            });

          const defaultContacts = defEmails.filter((email) =>
            contactRelations[this.props.roleContext.role]
              ? contactRelations[this.props.roleContext.role](email.roleOnProject)
              : contactRelations.default,
          );

          this.props.onLoadedEmails({
            emails: restEmails,
            defaultContacts,
            selectedEmail: restEmails[0],
            contacts: contacts.filter((contact) => contact.enable).map((contact) => ({ ...contact, isChecked: false })),
          });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.props.setIsFetching(false);
        });
    },
  }),
);

export default enchance(Component);
