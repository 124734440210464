import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Clear';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import T from 'prop-types';

import { AttachButton } from 'components/index';
import { acceptFiles } from 'constants/accept';
import dictionary from 'constants/dictionary';
import useStyles from './useStyles';

const localStyles = makeStyles({
  textArea: {
    padding: '0 14px',
    direction: 'rtl',
    width: '100%',
    display: 'block',
    border: 'none',
    resize: 'none',
    backgroundColor: 'white',
    font: 'inherit',
  },
  attachButtonWrapper: {
    padding: '5px',
  },
});

const NewFileRow = ({ file, onFileChange, onDescriptionChange, onFileDelete }) => {
  const classes = useStyles();
  const localClasses = localStyles();
  return (
    <TableRow className={classes.tr} key={file.id}>
      <TableCell className={`${classes.ceil}, ${classes.inputFileCeil}`}>
        <input type="file" name="files[]" accept={acceptFiles} onChange={onFileChange} style={{ width: '186px' }} />
      </TableCell>
      <TableCell className={classes.ceil}>
        <textarea
          onChange={onDescriptionChange}
          className={localClasses.textArea}
          disabled={file.disableDescription}
          value={file.description}
        />
      </TableCell>
      <TableCell className={`${classes.ceil} ${classes.deleteCeil}`}>
        {file.isDeleteble && <DeleteIcon className={classes.deleteIcon} onClick={onFileDelete} />}
      </TableCell>
    </TableRow>
  );
};

const FormNewFiles = ({
  imageBefore,
  imageAfter,
  newFiles,
  onAddFile,
  canAddFiles,
  handleImagesChange,
  onNewFileChange,
  onNewFileDescChange,
  deleteNewFileHandler,
}) => {
  const classes = useStyles();
  const localClasses = localStyles();
  return (
    <>
      {(imageBefore || imageAfter) && (
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tr}>
                <TableCell className={classes.ceil}>{dictionary.file}</TableCell>
                <TableCell className={classes.ceil}>{dictionary.description}</TableCell>
                <TableCell className={classes.ceil} />
              </TableRow>
            </TableHead>
            <TableBody>
              {imageBefore && (
                <NewFileRow
                  file={imageBefore}
                  onDescriptionChange={null}
                  onFileChange={(e) => handleImagesChange(e, 'imageBefore')}
                  onFileDelete={null}
                />
              )}

              {imageAfter && (
                <NewFileRow
                  file={imageAfter}
                  onDescriptionChange={null}
                  onFileChange={(e) => handleImagesChange(e, 'imageAfter')}
                  onFileDelete={null}
                />
              )}
            </TableBody>
          </Table>
        </Paper>
      )}
      {newFiles && newFiles.length > 0 && (
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tr}>
                <TableCell className={classes.ceil}>{dictionary.file}</TableCell>
                <TableCell className={classes.ceil}>{dictionary.description}</TableCell>
                <TableCell className={classes.ceil} />
              </TableRow>
            </TableHead>
            <TableBody>
              {newFiles.map((file, fileIndexInArray) => (
                <NewFileRow
                  key={file.id}
                  file={file}
                  fileIndexInArray={fileIndexInArray}
                  onDescriptionChange={(e) => onNewFileDescChange(e, fileIndexInArray)}
                  onFileChange={(e) => onNewFileChange(e, fileIndexInArray)}
                  onFileDelete={() => deleteNewFileHandler(fileIndexInArray)}
                />
              ))}
            </TableBody>
          </Table>
        </Paper>
      )}

      {canAddFiles && (
        // NOTE this div is needed for markup
        <div className={localClasses.attachButtonWrapper}>
          <AttachButton onClick={onAddFile} />
        </div>
      )}
    </>
  );
};

NewFileRow.propTypes = {
  file: T.objectOf(T.any).isRequired,
  onFileChange: T.func.isRequired,
  onDescriptionChange: T.func,
  onFileDelete: T.func,
};

NewFileRow.defaultProps = {
  onFileDelete: () => {},
  onDescriptionChange: () => {},
};

FormNewFiles.propTypes = {
  imageBefore: T.objectOf(T.any),
  imageAfter: T.objectOf(T.any),
  newFiles: T.arrayOf(T.any).isRequired,
  onAddFile: T.func.isRequired,
  canAddFiles: T.bool.isRequired,
  handleImagesChange: T.func.isRequired,
  onNewFileChange: T.func.isRequired,
  onNewFileDescChange: T.func.isRequired,
  deleteNewFileHandler: T.func.isRequired,
};

FormNewFiles.defaultProps = {
  imageBefore: null,
  imageAfter: null,
};

export default FormNewFiles;
