import React from 'react';
import T from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';

import dictionaryPhrases from 'constants/dictionary.phrases';

const styles = () => ({
  deleteModalWrapper: {
    padding: '20px 0 10px',
  },
  deleteModalHeader: {
    margin: '0 auto',
    marginBottom: '25px',
    maxWidth: '75%',
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 auto',
    maxWidth: '75%',
    minWidth: '68%',
    '& > button': {
      marginRight: '10px',
    },
  },
});

const DeleteModal = ({ classes, deleteModal, handleModalClose, handleDeleteSubmit }) => {
  return (
    <Dialog
      maxWidth="md"
      open={deleteModal.isOpen}
      onClose={handleModalClose}
      disableEscapeKeyDown
      aria-labelledby="scroll-dialog-title"
      BackdropProps={{
        invisible: true,
      }}
    >
      <div className={classes.deleteModalWrapper}>
        <div className={classes.deleteModalHeader}>
          <h2>{dictionaryPhrases.deleteModalHeader}</h2>
        </div>
        <DialogActions>
          <div className={classes.buttonContainer}>
            <Button onClick={handleDeleteSubmit} variant="contained" color="primary">
              Delete
            </Button>
            <Button onClick={handleModalClose} variant="contained" color="primary">
              Close
            </Button>
          </div>
        </DialogActions>
      </div>
    </Dialog>
  );
};

DeleteModal.propTypes = {
  deleteModal: T.objectOf(T.any).isRequired,
  classes: T.objectOf(T.any).isRequired,
  handleModalClose: T.func.isRequired,
  handleDeleteSubmit: T.func.isRequired,
};

export default withStyles(styles)(DeleteModal);
