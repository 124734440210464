// @flow
/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import { DashboardContentWrapper, Loading } from 'components/index';
import routes from 'pages/routes';
import { SUPER_ADMIN, ADMIN, OPERATOR, QC, FOREMAN } from 'constants/global-roles';
import api from 'api/index';
import { FORM_HEADER } from 'constants/formSetup';
import { showNotification } from 'utils/index';
import dictionaryPhrases from 'constants/dictionary.phrases';
import useUpdate from 'hooks/useUpdate';
import { useRouting } from 'hooks/useRouting';
import useRoleContext from 'hooks/useRoleContext';

import {
  initialValuesUpdate as initialValues,
  validationSchemaUpdate as validationSchema,
} from '../UserForm/UserForm.schema';
import Form from '../UserForm/UserForm';

const changePasswordQuery = (id: string) => ({
  query: `mutation {
    resetPassword(
      userId: "${id}"
    )
  }`,
});

const getCurrentUserQuery = (userId: string) => ({
  query: `query findAllUser{
    findAllUser(finder:{id:"${userId}"}){
      firstName
      lastName
      phone
      globalRole
      contact
    }
  }`,
});

const updateUserQuery = ({ id, firstName, lastName, phone, contact, globalRole, deleted = false }) => ({
  query: `mutation updateUser{
    updateUser(
      id: "${id}"
      deleted:${String(deleted)}
      ${firstName ? `firstName:"${firstName}"` : ''}
      ${lastName ? `lastName:"${lastName}"` : ''}
      ${phone ? `phone:"${phone}"` : ''}
      ${contact !== undefined ? `contact:${contact}` : ''}
      ${globalRole ? `globalRole:${globalRole}` : ''}
      ){
        id
      }
    }
  `,
});

export default () => {
  const { match } = useRouting();
  const [isShowLicenseLink, setIsShowLicenseLink] = useState(false);
  const { role } = useRoleContext();
  const showChangePassword = !(role === ADMIN && [ADMIN, SUPER_ADMIN].includes(initialValues.globalRole));
  const globalRoleSelectValiants = role === SUPER_ADMIN ? [ADMIN, OPERATOR, QC, FOREMAN] : [OPERATOR, QC, FOREMAN];

  useEffect(() => {
    api.isLicenseFileExist(match.params.id).then(setIsShowLicenseLink, setIsShowLicenseLink);
  }, []);

  const { isLoading, backAction, pageValues, updatePage } = useUpdate({
    updatePageQuery: async (values) => {
      if (values.fileForCode) {
        await api.sendCodeToUser([
          { name: 'userId', value: match.params.id },
          { name: 'file', value: values.fileForCode },
        ]);
      }

      return api.fetchQuery(updateUserQuery({ ...values, id: match.params.id }));
    },
    notification: dictionaryPhrases.updateUserSuccess,
    redirectRoute: routes.users.path,
    getPageQuery: () => api.fetchQuery(getCurrentUserQuery(match.params.id)).then(({ findAllUser: users }) => users[0]),
    initialValues,
    isPartialUpdate: true,
  });

  const handleChangePassword = async () => {
    await api.fetchQuery(changePasswordQuery(match.params.id));
    showNotification(dictionaryPhrases.resetPasswordSuccess, 'success');
  };

  const handleClickFile = () => api.openLicenseFile(match.params.id);

  return (
    <>
      {isLoading && <Loading />}

      <DashboardContentWrapper header={FORM_HEADER.user.update}>
        <Form
          validationSchema={validationSchema}
          isShowLicenseLink={isShowLicenseLink}
          handleChangePassword={handleChangePassword}
          handleClickFile={handleClickFile}
          handleBackAction={backAction}
          showChangePassword={showChangePassword}
          globalRoleSelectValiants={globalRoleSelectValiants}
          onSubmit={updatePage}
          initialValues={pageValues}
        />
      </DashboardContentWrapper>
    </>
  );
};
