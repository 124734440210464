// @flow
import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  wrapperCircle: {
    color: '#d77e7e',
    fontSize: '14px',
    width: '30px',
    height: '30px',
    background: '#ffe3e1',
    borderRadius: '50%',
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      border: '1px solid #d77e7e',
      cursor: 'ponter',
    },
  },
  pencil: {
    width: '15px',
    fill: '#d77e7e',
  },
}));

const Icon = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapperCircle}>
      {/* <EditSvg className={classes.pencil} /> */}
      <div className={classes.pencil}>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" enableBackground="new 0 0 512 512">
          <g>
            <g>
              <path
                d="m455.1,137.9l-32.4,32.4-81-81.1
              32.4-32.4c6.6-6.6 18.1-6.6 24.7,0l56.3,56.4c6.8,6.8 6.8,17.9
              0,24.7zm-270.7,271l-81-81.1 209.4-209.7 81,81.1-209.4,209.7zm-99.7-42l60.6,60.7-84.4,
              23.8 23.8-84.5zm399.3-282.6l-56.3-56.4c-11-11-50.7-31.8-82.4,0l-285.3,285.5c-2.5,2.5-4.3,5.5-5.2,8.9l-43,
              153.1c-2,7.1 0.1,14.7 5.2,20 5.2,5.3 15.6,6.2 20,5.2l153-43.1c3.4-0.9 6.4-2.7
              8.9-5.2l285.1-285.5c22.7-22.7 22.7-59.7 0-82.5z"
              />
            </g>
          </g>
        </svg>
      </div>
    </div>
  );
};

export default Icon;
