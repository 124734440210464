// @flow
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: `${theme.spacing(5)}px auto ${theme.spacing(5)}px`,
    textAlign: 'center',
    fontSize: '2.2em',
  },
}));

const CommonInformation = ({ children }: { children: React.Node }) => {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
};

export default CommonInformation;
