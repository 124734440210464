// @flow
import dictionary from 'constants/dictionary';

export type File = {
  id: number,
  nameWithExtension: string,
  value: string,
  description: string,
  isDeleteble: boolean,
  disableDescription: boolean,
};

export const isFilesHaveDescription = (files: File[], checkedDescription: string) => {
  return files.some((file) => {
    if (file === null) return false;

    return file.value && file.description === checkedDescription;
  });
};

export const isFilesHaveDescriptions = (files: File[], checkedDescriptions: string[]) => {
  return checkedDescriptions.every((checkedDescription) => {
    return isFilesHaveDescription(files, checkedDescription);
  });
};

export const getNewFile = (
  description: string,
  id: number,
  isDisableDescription: boolean,
  isDeleteble: boolean = true,
): File => {
  return {
    id,
    nameWithExtension: '',
    value: '',
    description,
    isDeleteble,
    disableDescription: isDisableDescription,
  };
};

export const createImageFile = (description: string, id: number, isDeleteble: boolean) => {
  return getNewFile(description, id, true, isDeleteble);
};

export const createInputFile = (description: string, id: number, isDeleteble: boolean) => {
  return getNewFile(description, id, false, isDeleteble);
};

type YesOrNo = 'כן' | 'לא';

export const getFilesFromImagesInfo = ({
  imageBefore: imageBeforeInfo,
  imageAfter: imageAfterInfo,
}: {
  imageBefore: YesOrNo,
  imageAfter: YesOrNo,
}) => {
  return {
    imageBefore: imageBeforeInfo === dictionary.no ? createImageFile(dictionary.imageBefore, 0, false) : null,
    imageAfter: imageAfterInfo === dictionary.no ? createImageFile(dictionary.imageAfter, 1, false) : null,
  };
};

export const getImagesInfo = (oldFiles: File[]) => {
  const mergeObject = {
    imageBefore: 'לא',
    imageAfter: 'לא',
  };

  // eslint-disable-next-line no-param-reassign
  if (!oldFiles) oldFiles = [];

  if (isFilesHaveDescription(oldFiles, dictionary.imageBefore)) {
    mergeObject.imageBefore = dictionary.yes;
  }

  if (isFilesHaveDescription(oldFiles, dictionary.imageAfter)) {
    mergeObject.imageAfter = dictionary.yes;
  }

  return mergeObject;
};
