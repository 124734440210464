// @flow
import React from 'react';
import { DatePicker } from '@material-ui/pickers';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import type { FieldProps } from 'formik';
import type { Record } from 'types/utility';

// FIX should be in one place
const theme = createMuiTheme({
  direction: 'rtl', // Both here and <body dir="rtl">
});

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '16px',
    marginBottom: '8px',
  },
  inputLabel: {
    position: 'absolute',
    bottom: '-15px',
    left: '0',
  },
}));

type Props = FieldProps<any> & {
  enabledFields: Record<string, boolean>,
  label: string,
};

const DatePickerField = ({
  enabledFields,
  label,
  field,
  field: { name, value },
  form: { setFieldValue, errors, touched },
  ...props
}: Props) => {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <div dir="rtl" className={classes.root}>
        <DatePicker
          clearable
          // disablePast
          disabled={enabledFields ? !enabledFields.hasOwnProperty(name) : false}
          clearLabel="נקה"
          cancelLabel="ביטול"
          okLabel="עדכן"
          name={name}
          value={value}
          inputVariant="outlined"
          className={classes.textField}
          label={label}
          format="dd/MM/yyyy"
          helperText={touched[name] ? errors[name] : ''}
          error={errors[name] && touched[name]}
          onChange={(date) => setFieldValue(name, date, true)}
          {...props}
          InputLabelProps={{
            shrink: true,
          }}
          FormHelperTextProps={{
            classes: {
              root: classes.inputLabel,
            },
          }}
        />
      </div>
    </ThemeProvider>
  );
};

export default DatePickerField;
