// @flow
import * as React from 'react';

import useRoleContext from 'hooks/useRoleContext';
import initSideBar from 'constants/initSideBar';
import Sidebar from './SideBar';

const SidebarContainer = () => {
  const { role } = useRoleContext();
  const menu = initSideBar(role);

  return <Sidebar menu={menu} />;
};

export default SidebarContainer;
