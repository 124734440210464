// @flow
import { showNotification } from 'utils/index';

export default class Localstore {
  getItemFromLocalstore(storageKey: string) {
    try {
      const serializedData = localStorage.getItem(storageKey);

      if (serializedData === null) {
        return undefined;
      }

      return serializedData;
    } catch (err) {
      return undefined;
    }
  }

  setItemToLocalstore(storageKey: string, data: mixed) {
    try {
      if (!data) {
        this.removeFromLocalstore(storageKey);
        return;
      }

      localStorage.setItem(storageKey, String(data));
    } catch (error) {
      showNotification(error, 'error');
    }
  }

  removeFromLocalstore(storageKey: string) {
    try {
      localStorage.removeItem(storageKey);
    } catch (error) {
      showNotification(error, 'error');
    }
  }
}
