// @flow
import React, { useEffect } from 'react';

import useCreateItem from 'hooks/useCreateItem';
import { showNotification } from 'utils/index';
import api from 'api/index';
import dictionaryPhrases from 'constants/dictionary.phrases';

type Props<Values> = {
  createItemQuery: (values: Values) => Promise<any>,
  newFiles: any[],
  formType: string,
  createListItemResponseName: string,
  projectId: string,
};

export default <Values = any>({
  createItemQuery,
  newFiles,
  formType,
  createListItemResponseName,
  projectId,
}: Props<Values>) => {
  const { isLoading, handleCreateItem, handleBackAction } = useCreateItem<Values>({
    createItemQuery,
  });

  const handleCreateForm = async (values: Values) => {
    if (newFiles.some((file) => file.value && !file.description)) {
      showNotification(dictionaryPhrases.descriptionsWarning, 'warning');

      return Promise.reject();
    }

    const res = await handleCreateItem(({ ...values, projectId }: any));
    return api.sendAddFilesForm(formType, res[createListItemResponseName].id, newFiles);
  };

  return { isLoading, handleCreateItem: handleCreateForm, handleBackAction };
};
