// @flow
import { useState, useEffect } from 'react';
import type { PageBy } from 'types/frontTypes';
// import config from 'config';

export default ({ data }: { data: any[] }) => {
  const [pageBy, setPageBy] = useState<PageBy>({
    page: 1,
    rowsPerPage: 10,
  });

  const changePage = (page: number) => {
    setPageBy({ page, rowsPerPage: pageBy.rowsPerPage });
  };

  useEffect(() => {
    if (data.length === 0 && pageBy.page > 1) {
      changePage(pageBy.page - 1);
    }
  }, [data]);

  return { pageBy, changePage };
};
