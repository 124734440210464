/* eslint-disable react/prop-types */
// @flow
import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';

import api from 'api/index';
import routes from 'pages/routes';
import { EXPIRATION_TIME } from 'constants/auth';
import useRoleContext from 'hooks/useRoleContext';

import NotFound from 'pages/NotFound/NotFound';

const now = Date.now();
type Props = {
  location: any,
  path: string,
  component: React.Node,
};

type ProtectedRoutesSetup = {
  [key: string]: React$Element<React$ComponentType<any>>,
};

const protectedRoutes: ProtectedRoutesSetup = {
  [routes.login.path]: ({ component: Component, ...rest }) => {
    // if (api.isLoggedIn && api[EXPIRATION_TIME] > now) {
    //   return <Redirect to={{ pathname: routes.dashboard, state: { from: rest.location } }} />;
    // }

    // api.removeAllMetaData();

    return <Route render={() => <Component {...rest} />} />;
  },
  [routes.home.path]: ({ location }) => {
    // if (api.isLoggedIn && api[EXPIRATION_TIME] > now) {
    //   return <Redirect to={{ pathname: routes.dashboard, state: { from: props.location } }} />;
    // }

    api.removeAllMetaData();

    return <Redirect to={{ pathname: routes.login.path, state: { from: location } }} />;
  },
  // $FlowFixMe
  default: ({ component: Component, access, role, ...rest }: any) => {
    if (api.isLoggedIn && api[EXPIRATION_TIME] > now) {
      if (access.includes(role)) {
        return <Route component={Component} {...rest} />;
      }

      return <NotFound />;
    }

    api.removeAllMetaData();

    return <Redirect to={{ pathname: routes.login.path, state: { from: rest.location } }} />;
  },
};

const ProtectedRoute = ({ path, ...rest }: Props) => {
  const { role } = useRoleContext();
  return protectedRoutes[path]
    ? // $FlowFixMe
      protectedRoutes[path]({ path, ...rest })
    : // $FlowFixMe
      protectedRoutes.default({ path, role, ...rest });
};

export default ProtectedRoute;
