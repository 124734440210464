import { compose, withHandlers } from 'recompose';

import { objectDifference } from 'utils/index';
import api from 'api/index';

export default compose(
  withHandlers({
    makePartialUpdate: () => ({ initialValues, values, queryCreator, id }) => {
      const updatedFields = objectDifference(initialValues, values);
      console.log(initialValues, values, updatedFields, id, 'makePartialUpdate');

      if (Object.keys(updatedFields).length === 0) {
        return Promise.resolve();
      }

      return api.fetchQuery(queryCreator({ ...updatedFields, id }));
    },
  }),
);
