import { compose, withHandlers, lifecycle, withProps, withStateHandlers, renameProps } from 'recompose';
import { withRouter } from 'react-router-dom';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import _isEqual from 'lodash/isEqual';

import paginationCount from 'constants/paginationCount';
import { showNotification } from 'utils/index';
import api from 'api/index';
import routes from 'pages/routes';
import { initialValues as additionalFilterInitValues } from 'components/List/AdditionalFilters/AdditionalFilters.schema';

const getAllProjectsQuery = () => ({
  query: `query{
    findAllProject{
      name
      id
    }
  }`,
});

const enchance = compose(
  withRouter,
  // withProjects,
  withStateHandlers(
    {
      isFetching: false,
      // NOTE: initialList settet only once and needed to protect from unnecessary filtering
      initialList: [],
      currentList: [],
      total: 0,
      pageNumber: 1,
    },
    {
      onLoadedList: (state) => (currentList, total, initialList = state.initialList) => ({
        ...state,
        currentList,
        initialList,
        total,
      }),
      handleChangeListState: (state) => (stateName, value) => ({
        ...state,
        [stateName]: value,
      }),
    },
  ),
  withHandlers({
    setListFromResponse: (props) => (response) => {
      const users = response[props.getAllListResponseName]
        // .filter((user) => !user.deleted)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      props.onLoadedList(users, users.length);
    },
  }),
  withHandlers({
    changePage: (props) => (isMoveForward) => {
      const newPageNumber = isMoveForward ? props.pageNumber + 1 : props.pageNumber - 1;

      if (newPageNumber <= 0 || newPageNumber * paginationCount > props.total + paginationCount - 1) return;

      props.handleChangeListState('pageNumber', newPageNumber);
    },
    handleDelete: (props) => (id) => {
      props.handleChangeListState('isFetching', true);

      // FIX union when in server will be ready the same schema with pagination
      const deleteItem = () => {
        if (
          routes.users.path === props.match.path ||
          routes.projectUsers.path === props.match.path ||
          routes.projects.path === props.match.path
        ) {
          return api
            .fetchQuery(props.deleteListItemQuery(id))
            .then(() => {
              if (props.currentList.length % paginationCount === 1 && props.pageNumber > 1) {
                props.handleChangeListState('pageNumber', props.pageNumber - 1);
              }

              return api.fetchQuery(props.getAllListQuery());
            })
            .then(props.setListFromResponse);
        }

        if (
          routes.contacts.path === props.match.path ||
          [routes.forms1.path, routes.forms2.path, routes.forms3.path, routes.forms4.path].includes(props.match.path)
        ) {
          return api
            .fetchQuery(props.deleteListItemQuery(id))
            .then(() => {
              if (props.currentList.length === 1 && props.pageNumber > 1) {
                props.handleChangeListState('pageNumber', props.pageNumber - 1);

                return Promise.reject();
              }

              return api.fetchQuery(props.getAllListQuery(props.projectId, props.pageNumber));
            })
            .then((res) => {
              props.onLoadedList(
                res[props.getAllListResponseName][props.getAllListResponseNameSecond],
                parseInt(res[props.getAllListResponseName].size, 10),
              );
            });
        }

        return Promise.reject();
      };

      deleteItem()
        .then(() => showNotification(props.deleteSuccessMessage, 'success'))
        .catch((err) => {
          console.error(err);
        })
        .finally(() => props.handleChangeListState('isFetching', false));
    },
    handleRecovery: (props) => (id) => {
      props.handleChangeListState('isFetching', true);

      // FIX union when in server will be ready the same schema with pagination
      const recoveryItem = () => {
        if (
          routes.users.path === props.match.path ||
          routes.projectUsers.path === props.match.path ||
          routes.projects.path === props.match.path
        ) {
          return api
            .fetchQuery(props.recoveryListUserQuery(id))
            .then(() => {
              if (props.currentList.length % paginationCount === 1 && props.pageNumber > 1) {
                props.handleChangeListState('pageNumber', props.pageNumber - 1);
              }

              return api.fetchQuery(props.getAllListQuery());
            })
            .then(props.setListFromResponse);
        }

        if (
          routes.contacts.path === props.match.path ||
          [routes.forms1.path, routes.forms2.path, routes.forms3.path, routes.forms4.path].includes(props.match.path)
        ) {
          return api
            .fetchQuery(props.recoveryListUserQuery(id))
            .then(() => {
              if (props.currentList.length === 1 && props.pageNumber > 1) {
                props.handleChangeListState('pageNumber', props.pageNumber - 1);

                return Promise.reject();
              }

              return api.fetchQuery(props.getAllListQuery(props.projectId, props.pageNumber));
            })
            .then((res) => {
              props.onLoadedList(
                res[props.getAllListResponseName][props.getAllListResponseNameSecond],
                parseInt(res[props.getAllListResponseName].size, 10),
              );
            });
        }

        return Promise.reject();
      };

      recoveryItem()
        .then(() => showNotification(props.deleteSuccessMessage, 'success'))
        .catch((err) => {
          console.error(err);
        })
        .finally(() => props.handleChangeListState('isFetching', false));
    },
    handleChangeProjectId: (props) => ({ target: { value } }) => {
      props.handleChangeListState('isFetching', true);

      api
        .fetchQuery(props.getAllListQuery(value, props.pageNumber))
        .then((res) => {
          props.handleChangeProjectState('projectId', value);

          props.onLoadedList(
            res[props.getAllListResponseName].forms,
            parseInt(res[props.getAllListResponseName].size, 10),
            res[props.getAllListResponseName].forms,
          );
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          props.handleChangeListState('isFetching', false);
        });
    },
    filterSubmit: (props) => (values) => {
      if (
        (_isEqual(values, additionalFilterInitValues) && _isEqual(props.initialList, props.currentList)) ||
        props.initialList.length === 0
      )
        return;

      api
        .fetchQuery(
          props.filterQuery({
            ...values,
            projectId: props.projectId,
            pageNumber: props.pageNumber,
            // NOTE: it is needed for filter in same day
            startDate: values.startDate && startOfDay(values.startDate),
            finishDate: values.finishDate && endOfDay(values.finishDate),
          }),
        )
        .then((res) => {
          props.handleChangeListState('currentList', res[props.getAllListResponseName].forms);
        });
    },
  }),
  withProps((props) => {
    let from = 0;
    let to = 0;

    if (props.total > 0) {
      from = (props.pageNumber - 1) * paginationCount + 1;
      to = from + paginationCount - 1 > props.total ? props.total : from + paginationCount - 1;
    }

    return {
      from,
      to,
      goForward: () => props.changePage(true),
      goBack: () => props.changePage(false),
      isShowEdit: (formStatus) => props.updateAvailable.includes(formStatus.value),
    };
  }),
  renameProps({
    toggleIsCreate: 'handleBackAction',
  }),
  lifecycle({
    componentDidMount() {
      this.props.handleChangeListState('isFetching', true);

      // OPTIMIZE should be optimized
      if (
        [routes.forms1.path, routes.forms2.path, routes.forms3.path, routes.forms4.path].includes(this.props.match.path)
      ) {
        api
          .fetchQuery(getAllProjectsQuery())
          .then(({ findAllProject: projects }) => {
            if (!projects || !projects.length) return Promise.reject();

            // NOTE: if make destructurization in parameters
            // "Cannot destructure property `id` of 'undefined' or 'null'"
            const { id: initialProjectId } = projects[0];
            this.props.onLoadedProjects(projects, initialProjectId);

            return initialProjectId;
          })
          .then((projectId) => {
            return api.fetchQuery(this.props.getAllListQuery(projectId, this.props.pageNumber));
          })
          .then((res) => {
            this.props.onLoadedList(
              res[this.props.getAllListResponseName][this.props.getAllListResponseNameSecond],
              parseInt(res[this.props.getAllListResponseName].size, 10),
              res[this.props.getAllListResponseName][this.props.getAllListResponseNameSecond],
            );
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            this.props.handleChangeListState('isFetching', false);
          });
      }

      if (
        routes.projectUsers.path === this.props.match.path ||
        routes.users.path === this.props.match.path ||
        routes.projects.path === this.props.match.path
      ) {
        api
          .fetchQuery(this.props.getAllListQuery())
          .then(this.props.setListFromResponse)
          .catch((err) => {
            console.error(err);
          })
          .finally(() => this.props.handleChangeListState('isFetching', false));
      }

      if (routes.contacts.path === this.props.match.path) {
        api
          .fetchQuery(this.props.getAllListQuery(null, this.props.pageNumber))
          .then((res) => {
            this.props.onLoadedList(
              res[this.props.getAllListResponseName][this.props.getAllListResponseNameSecond],
              parseInt(res[this.props.getAllListResponseName].size, 10),
            );
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => this.props.handleChangeListState('isFetching', false));
      }
    },

    componentDidUpdate(prevProps) {
      // OPTIMIZE should be optimized
      if (routes.contacts.path === this.props.match.path) {
        if (prevProps.pageNumber !== this.props.pageNumber) {
          this.props.handleChangeListState('isFetching', true);

          api
            .fetchQuery(this.props.getAllListQuery(null, this.props.pageNumber))
            .then((res) => {
              this.props.onLoadedList(
                res[this.props.getAllListResponseName][this.props.getAllListResponseNameSecond],
                parseInt(res[this.props.getAllListResponseName].size, 10),
              );
            })
            .catch((err) => {
              console.error(err);
            })
            .finally(() => this.props.handleChangeListState('isFetching', false));
        }
      }

      if (
        [routes.forms1.path, routes.forms2.path, routes.forms3.path, routes.forms4.path].includes(this.props.match.path)
      ) {
        if (prevProps.pageNumber !== this.props.pageNumber) {
          this.props.handleChangeListState('isFetching', true);

          api
            .fetchQuery(this.props.getAllListQuery(this.props.projectId, this.props.pageNumber))
            .then((res) => {
              this.props.onLoadedList(
                res[this.props.getAllListResponseName][this.props.getAllListResponseNameSecond],
                parseInt(res[this.props.getAllListResponseName].size, 10),
              );
            })
            .catch((err) => {
              console.error(err);
            })
            .finally(() => this.props.handleChangeListState('isFetching', false));
        }
      }
    },
  }),
);

export default enchance;
