// @flow
import React from 'react';

import { CommonDialog, CommonPopover } from 'components/index';
import type { Modal, Popover } from 'hooks/usePopup';

type Props = {
  modal?: Modal | null,
  popover?: Popover | null,
  closePopup: () => void,
};

const Popup = ({ modal, popover, closePopup }: Props) => (
  <>
    {modal && (
      <CommonDialog open={Boolean(modal)} title={modal.title} onClose={closePopup}>
        {modal.content()}
      </CommonDialog>
    )}

    {popover && (
      <CommonPopover open={Boolean(popover)} anchorEl={popover.anchor} onClose={closePopup}>
        {popover.content()}
      </CommonPopover>
    )}
  </>
);

export default Popup;
