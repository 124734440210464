// @flow
import React from 'react';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';

import type { Record } from 'types/utility';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '16px',
    marginBottom: '8px',
  },
  inputLabel: {
    position: 'absolute',
    bottom: '-15px',
    left: '0',
  },
}));

type Props = {
  enabledFields: Record<string, string>,
  label: string,
  name: string,
  value: mixed,
};

const DateTimePickerField = ({ enabledFields, label, name, value }: Props) => {
  const classes = useStyles();

  return (
    <KeyboardDateTimePicker
      clearable
      disabled={enabledFields ? !enabledFields.hasOwnProperty(name) : false}
      name={name}
      value={value}
      label={label}
      margin="normal"
      // {...props}
      FormHelperTextProps={{
        classes: {
          root: classes.inputLabel,
        },
      }}
    />
  );
};

export default DateTimePickerField;
