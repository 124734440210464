import { OPERATOR, QC, FOREMAN, ADMIN, SUPER_ADMIN } from 'constants/global-roles';

const allRoles = [OPERATOR, QC, FOREMAN, ADMIN, SUPER_ADMIN];
const admins = [ADMIN, SUPER_ADMIN];

export default {
  home: { access: allRoles, path: '/' },
  login: { access: allRoles, path: '/login' },
  dashboard: { access: allRoles, path: '/dashboard' },

  users: { access: admins, path: '/users' },
  userCreate: { access: admins, path: '/users/new' },
  userPage: { access: admins, path: '/users/update/:id' },

  projects: { access: admins, path: '/projects' },
  projectCreate: { access: admins, path: '/projects/new' },
  projectPage: { access: admins, path: '/projects/update/:id' },

  projectUsers: { access: admins, path: '/project-users' },
  projectUserCreate: { access: admins, path: '/project-users/new' },
  projectUserPage: { access: admins, path: '/project-users/update/:id' },

  emailSchedule: { access: [...admins, QC], path: '/email-schedule' },

  forms1: { access: [...admins, OPERATOR], path: '/traffic-accidents' },
  form1Create: { access: [...admins, OPERATOR], path: '/traffic-accidents/new' },
  form1Page: { access: [...admins, OPERATOR], path: '/traffic-accidents/update/:id' },

  forms2: { access: allRoles, path: '/failes-safety' },
  form2Create: { access: allRoles, path: '/failes-safety/new' },
  form2Page: { access: allRoles, path: '/failes-safety/update/:id' },

  forms3: { access: [...admins, QC, FOREMAN], path: '/treatment-of-impaired' },
  form3Create: { access: [...admins, QC, FOREMAN], path: '/treatment-of-impaired/new' },
  form3Page: { access: [...admins, QC, FOREMAN], path: '/treatment-of-impaired/update/:id' },

  forms4: { access: [...admins, OPERATOR], path: '/traffic-events' },
  form4Create: { access: [...admins, OPERATOR], path: '/traffic-events/new' },
  form4Page: { access: [...admins, OPERATOR], path: '/traffic-events/update/:id' },

  contacts: { access: allRoles, path: '/contacts' },
  contactCreate: { access: allRoles, path: '/contacts/new' },
  contactPage: { access: allRoles, path: '/contacts/update/:id' },

  getReports: { access: allRoles, path: '/reports' },
  changePassword: { access: allRoles, path: '/change-password' },
};
