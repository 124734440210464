import React from 'react';
import T from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Formik, Field } from 'formik';

import { TextField, Actions } from 'components/index';
import dictionary from 'constants/dictionary';
import validationSchema from './ChangePasswordForm.schema';

const styles = (theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '300px',
    margin: '0 auto',
  },
});

const ChangePasswordForm = ({ classes, onSubmit, initialValues, handleBackAction }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit} className={classes.form}>
            <Field
              component={TextField}
              name="password"
              label={dictionary.password}
              type="password"
            />

            <Field
              component={TextField}
              name="confirmPassword"
              label={dictionary.confirmPassword}
              type="password"
            />

            <Actions handleBackAction={handleBackAction} />
          </form>
        );
      }}
    </Formik>
  );
};

ChangePasswordForm.propTypes = {
  classes: T.objectOf(T.string).isRequired,
  initialValues: T.objectOf(T.string).isRequired,
  onSubmit: T.func.isRequired,
  handleBackAction: T.func.isRequired,
};

export default withStyles(styles)(ChangePasswordForm);
