import { compose, withHandlers, withState } from 'recompose';

import withProjects from './withProjects';

const enchance = compose(
  withProjects,
  withState('isCreate', 'setIsCreate', false),
  withHandlers({
    toggleIsCreate: (props) => () => {
      props.setIsCreate(!props.isCreate);
    },
  }),
);

export default enchance;
