import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import withStyles from '@material-ui/core/styles/withStyles';
import T from 'prop-types';

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  chartWrapper: {
    position: 'relative',
  },
  totalWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
  total: {
    fontSize: '2em',
  },
});

const Chart = ({ classes, data, header, total }) => {
  return (
    <div className={classes.root}>
      <div>{header}</div>

      <div className={classes.chartWrapper}>
        <div className={classes.totalWrapper}>
          <span className={classes.total}>{total}</span>
        </div>

        <Doughnut
          data={data}
          height={200}
          width={200}
          legend={{
            display: false,
          }}
        />
      </div>
    </div>
  );
};

Chart.propTypes = {
  classes: T.objectOf(T.string).isRequired,
  data: T.objectOf(T.any).isRequired,
  header: T.string.isRequired,
  total: T.number.isRequired,
};

export default withStyles(styles)(Chart);
