import React from 'react';
import T from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Formik, Field } from 'formik';

import dictionary from 'constants/dictionary';
import { TextField, File, Actions } from 'components/index';
import validationSchema from './ProjectForm.schema';

const styles = () => ({
  form: {
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
  },
});

const UserForm = ({ classes, onSubmit, initialValues, handleBackAction }) => {
  console.log('initialValues', initialValues);
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit} className={classes.form}>
            <Field component={TextField} name="name" label={dictionary.name} />
            <Field component={TextField} name="mainContractor" label={dictionary.mainContractor} />
            <Field
              component={File}
              name="logoMainContractor"
              label={dictionary.logoMainContractor}
            />
            <Field
              component={TextField}
              name="managementCompany"
              label={dictionary.managementCompany}
            />
            <Field component={TextField} name="contractNumber" label={dictionary.contractNumber} />
            <Field component={TextField} name="qcCompany" label={dictionary.qcCompany} />
            <Field component={TextField} name="qaCompany" label={dictionary.qaCompany} />
            <Field
              component={TextField}
              name="customerCompany"
              label={dictionary.customerCompany}
            />

            <Actions handleBackAction={handleBackAction} />
          </form>
        );
      }}
    </Formik>
  );
};

UserForm.propTypes = {
  classes: T.objectOf(T.string).isRequired,
  initialValues: T.objectOf(T.string).isRequired,
  onSubmit: T.func.isRequired,
  handleBackAction: T.func.isRequired,
};

export default withStyles(styles)(UserForm);
