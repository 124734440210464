/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
import { lifecycle, compose, withProps, withState, withHandlers, withStateHandlers } from 'recompose';
import { withRouter } from 'react-router';
import { format, parse } from 'date-fns';

import routes from 'pages/routes';
import { showNotification, getImagesInfo, isFilesHaveDescriptions, objectDifference, fromUTC } from 'utils/index';
import { OPERATOR, ADMIN, SUPER_ADMIN } from 'constants/global-roles';
import { OPERATOR_SUCCESS, COMPLETED, form14StatusAliases } from 'constants/formStatus';
import api from 'api/index';
import { withRole } from 'hoc/index';
import dictionary from 'constants/dictionary';
import dictionaryPhrases from 'constants/dictionary.phrases';
import withAddedFiles from './withAddedFiles';
import withNewFiles from './withNewFiles';

const enchance = compose(
  withRouter,
  withRole,
  withNewFiles,
  withAddedFiles,
  withState('isFetching', 'setIsFetching', false),
  withStateHandlers(
    {
      dialogs: {
        isOpenEmails: false,
        // NOTE: callback for signing form2 operator_success only after email
        closeDialogCallback: null,
        isOpenCode: false,
      },
    },
    {
      handleDialogOpen: (state) => (fieldName) => ({
        ...state,
        dialogs: {
          ...state.dialogs,
          [fieldName]: true,
        },
      }),
      handleDialogClose: (state) => (fieldName) => ({
        ...state,
        dialogs: {
          ...state.dialogs,
          [fieldName]: false,
        },
      }),
      setStateDialogs: (oldState) => (newState) => ({ ...oldState, ...newState }),
    },
  ),
  withStateHandlers(
    {
      initialValues: { testCode: '', status: COMPLETED },
      canAddFiles: false,
      projectId: '',
      showRollBack: false,

      code: {
        value: '',
        error: null,
      },
    },
    {
      onLoadedPage: (state, props) => (data) => {
        return {
          ...state,
          canAddFiles: data.canAddFiles,
          initialValues: props.manuallyUpdateInitialValues(data.initialValues),
          projectId: data.projectId,
          showRollBack: data.showRollBack,
        };
      },
      handleChangeInitialValues: (state) => (initialValues) => {
        return {
          ...state,
          initialValues,
        };
      },
      handleChangeFormStatus: (state) => (value) => ({
        ...state,
        initialValues: {
          ...state.initialValues,
          status: value,
        },
      }),
      handleCodeStateChange: (state) => (fieldName, value) => {
        return {
          ...state,
          code: {
            ...state.code,
            error: null,
            [fieldName]: value,
          },
        };
      },
    },
  ),
  withHandlers({
    _handleUpdate: (props) => (values) => {
      const newFormFields = [routes.form2Page.path, routes.form3Page.path].includes(props.match.path)
        ? {
            ...values,
            ...getImagesInfo(props.addedFiles),
          }
        : values;
      // NOTE: send only changed fields, if there aren't any => don't send query
      const updatedFields = objectDifference(props.initialValues, newFormFields);

      console.log('updatedFields', updatedFields, props.initialValues, newFormFields);

      if (Object.keys(updatedFields).length === 0) {
        return Promise.resolve();
      }

      return api
        .fetchQuery(
          props.updateCurrentPageQuery(props.initialValues.status)({
            ...updatedFields,
            formId: props.match.params.id,
            newFormFields,
          }),
        )
        .then(() => {
          /*
          NOTE: save current formValues snapshot
          it is neccessary for future compare on update
        */
          props.handleChangeInitialValues(newFormFields);
        });
    },
    _handleSendingFiles: (props) => () => {
      if (props.newFiles.some((file) => file.value && !file.description)) {
        showNotification(dictionaryPhrases.descriptionsWarning, 'warning');

        return Promise.reject();
      }

      return api
        .sendAddFilesForm(props.formType, props.match.params.id, [
          ...props.newFiles,
          props.imageBefore,
          props.imageAfter,
        ])
        .then((response) => (response.body instanceof ReadableStream ? response.json() : response))
        .then((responseAddedFiles) => {
          const responseFilesWithValue = responseAddedFiles.map((file) => ({
            ...file,
            value: {},
          }));

          props.setNewFiles([]);
          props.setAddedFiles([...props.addedFiles, ...responseFilesWithValue], props.canAddFiles);

          return responseFilesWithValue;
        });
    },
    _handleDeleteFile: (props) => (fileId, arrayId) => {
      return api.fetchQuery(props.deleteFileQuery(fileId, props.match.params.id)).then(() => {
        const newAddedFilesArray = [...props.addedFiles.slice(0, arrayId), ...props.addedFiles.slice(arrayId + 1)];
        props.setAddedFiles(newAddedFilesArray, props.canAddFiles);
        return newAddedFilesArray;
      });
    },
    handleRollBack: (props) => (rollBackQuery) => {
      api
        .fetchQuery(props.rollBackFormQuery(props.match.params.id))
        .then((response) => {
          console.log('_handleRollBack', props, response);
          props.handleChangeFormStatus(response.revertStatusForm);
        })
        .then(showNotification(dictionaryPhrases.rollBackFormSuccess, 'success'));
    },
  }),
  withHandlers({
    handleValidateCode: (props) => () => {
      if (!props.code.value) {
        props.handleCodeStateChange('error', 'Required');

        return false;
      }

      return true;
    },
    handleSign: (props) => (queryCallback) => {
      return api
        .fetchQuery(queryCallback(props.match.params.id, props.code.value))
        .then(() => showNotification(dictionaryPhrases.signFormSuccess, 'success'))
        .then(() => {
          props.handleDialogClose('isOpenCode');

          props.history.goBack();
        });
    },
    handleUpdate: (props) => (values) => {
      return props
        ._handleSendingFiles()
        .then(() => props._handleUpdate(values))
        .then(() => showNotification(dictionaryPhrases.updateFormSuccess, 'success'));
    },
  }),
  withHandlers({
    handleUpdateAndSign: (props) => (values, signCallback) => {
      return props.handleUpdate(values).then(() => props.handleSign(signCallback));
    },
    hasFilesDescriptions: (props) => () =>
      isFilesHaveDescriptions(
        [...props.addedFiles, ...props.newFiles, props.imageBefore, props.imageAfter],
        [dictionary.imageBefore, dictionary.imageAfter],
      ),
  }),
  withHandlers({
    handleUpdateForm: (props) => (values) => {
      props.setIsFetching(true);

      props
        .handleUpdate(values)
        .catch((err) => {
          console.error(err);
        })
        .finally(() => props.setIsFetching(false));
    },
    handleSignForm: (props) => (values) => {
      if (
        !(
          [OPERATOR, ADMIN, SUPER_ADMIN].includes(props.roleContext.role) &&
          props.initialValues.status === OPERATOR_SUCCESS
        )
      ) {
        if (!props.handleValidateCode()) return;
      }

      if (props.match.path.startsWith(routes.forms2.path) && props.initialValues.status === OPERATOR_SUCCESS) {
        props.setStateDialogs({
          dialogs: {
            ...props.dialogs,
            isOpenEmails: true,
            closeDialogCallback: () =>
              props
                .signCurrentPageQuery(props)(values)
                .catch((err) => {
                  console.error(err);
                })
                .finally(() => props.setIsFetching(false)),
          },
        });
        return;
      }

      props
        .signCurrentPageQuery(props)(values)
        .catch((err) => {
          console.error(err);
        })
        .finally(() => props.setIsFetching(false));
    },
  }),
  withHandlers({
    handleDeleteFile: (props) => (fileId, arrayId) => {
      props.setIsFetching(true);

      props
        ._handleDeleteFile(fileId, arrayId)
        .then((newAddedFilesArray) => props._handleUpdate(props.initialValues, newAddedFilesArray))
        .then(() => showNotification(dictionaryPhrases.updateFormSuccess, 'success'))
        .catch((err) => {
          console.error(err);
        })
        .finally(() => props.setIsFetching(false));
    },
  }),
  withProps((props) => {
    return {
      onSubmit: props.handleUpdateForm,
      handleBackAction: () => props.history.goBack(),
      formId: props.match.params.id,
      projectName: props.initialValues.projectName,
      failesSafetyNo: props.initialValues.failesSafetyNo,
      mapInfo: [
        ...([routes.forms1.path, routes.forms4.path].some((route) => props.match.path.startsWith(route))
          ? [
              {
                name: dictionary.status,
                value: dictionary[form14StatusAliases[props.initialValues.status]],
              },
              {
                name: dictionary.submitDateForm14,
                value: props.initialValues.submitDate
                  ? format(fromUTC(props.initialValues.submitDate), 'yyyy/MM/dd HH:mm:ss')
                  : '-',
              },
            ]
          : []),
        ...([routes.forms3.path, routes.forms2.path].some((route) => props.match.path.startsWith(route))
          ? [
              {
                name: dictionary.status,
                value: dictionary[props.initialValues.openClosedStatus],
              },
              {
                name: dictionary.submitDateForm14,
                value: props.initialValues.finishDate
                  ? format(fromUTC(props.initialValues.finishDate), 'yyyy/MM/dd')
                  : '-',
              },
            ]
          : []),
        {
          name: dictionary.projectName,
          value: props.initialValues.projectName,
        },
        {
          name: dictionary.contractNumber,
          value: props.initialValues.contractNumber,
        },
        {
          name: dictionary.failesSafetyNo,
          value: props.initialValues.failesSafetyNo,
        },
        {
          name: dictionary.openingName,
          value: props.initialValues.openingName,
        },
        {
          name: dictionary.openingDate,
          value: props.initialValues.openingDate ? format(fromUTC(props.initialValues.openingDate), 'yyyy/MM/dd') : '-',
        },
        {
          name: dictionary.openingHour,
          value: props.initialValues.openingHour
            ? format(fromUTC(parse(props.initialValues.openingHour, 'HH:mm:ss', new Date())), 'HH:mm:ss')
            : '-',
        },
      ],
    };
  }),
  lifecycle({
    componentDidMount() {
      this.props.setIsFetching(true);

      api
        .fetchQuery(this.props.getCurrentPageQuery(this.props.match.params.id))
        .then((res) => {
          const [data] = res[this.props.getCurrentPageResponseName].forms;
          let documents = data.documents || [];
          // NOTE: make all file objects to have value property
          documents = documents.map((file) => ({ ...file, value: {} }));

          const { canAddFiles } = this.props.dependencies[this.props.roleContext.role].dependencies[data.status];

          this.props.setAddedFiles(documents, canAddFiles);

          this.props.onLoadedPage({
            canAddFiles,
            initialValues: data,
            projectId: data.projectId.id,
          });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.props.setIsFetching(false);
        });
    },
  }),
  // take all setup props depends on role and form status
  withProps((props) => {
    if (!props.roleContext.role || !props.initialValues.status) return;

    return props.dependencies[props.roleContext.role].dependencies[props.initialValues.status];
  }),
);

export default enchance;
