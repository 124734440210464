import { compose, withHandlers } from 'recompose';

import { withDialogHandler } from 'hoc/index';
import api from 'api/index';
import FormInfo from './FormInfo';

const enchance = compose(
  withHandlers({
    handleDownloadFile: (props) => (fileType) => {
      console.log('handleDownloadFile executed');
      api.openReport(props.addedFiles, props.formType, props.formId, fileType);
    },
  }),
);

export default enchance(FormInfo);
