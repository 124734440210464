import * as Yup from 'yup';

const Schema = Yup.object().shape({
  projectId: Yup.string().required('Required'),
  userId: Yup.string().required('Required'),
  defaultContact: Yup.bool(),
});

export const initialValues = {
  projectId: '',
  userId: '',
  defaultContact: false,
};

export default Schema;
