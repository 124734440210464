import React from 'react';
import T from 'prop-types';

import { DashboardContentWrapper, Loading } from 'components/index';
import Form from '../ProjectForm/ProjectForm';
import ProjectLogo from './ProjectLogo';

const UserCreate = ({ headerUpdate, isFetching, initialValues, ...props }) => {
  return (
    <>
      {isFetching && <Loading />}
      <DashboardContentWrapper header={headerUpdate}>
        <div style={{ display: 'flex', alignItems: 'start' }}>
          <ProjectLogo logoMainContractor={initialValues.logoMainContractor} />
          <Form initialValues={initialValues} {...props} />
        </div>
      </DashboardContentWrapper>
    </>
  );
};

UserCreate.propTypes = {
  headerUpdate: T.string.isRequired,
  isFetching: T.bool.isRequired,
};

export default UserCreate;
