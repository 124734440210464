// @flow
import * as React from 'react';

import { toOffsettedTimestamp, showNotification } from 'utils/index';
import { FORM_HEADER, FORM_TYPE } from 'constants/formSetup';
import dependenciesForm4 from 'constants/form4Setup';
import dictionaryPhrases from 'constants/dictionary.phrases';
import useCreateForm from 'hooks/useCreateForm';
import useNewFiles from 'hooks/useNewFiles';
import { useRouting } from 'hooks/useRouting';
import useRoleContext from 'hooks/useRoleContext';
import { DashboardContentWrapper } from 'components/index';
import api from 'api/index';

import Form from '../TrafficEventForm/TrafficEventForm';

const createForm4Query = ({
  projectId,
  side,
  location,
  reportingFactor,
  element,
  datailOfTheAccident,
  mixedCars,
  telephoneReportingForProjectManagement,
  callCenterReportingOperationsTeam,
  reportByTelephoneThePolice,
  telephoneReportingAndEmergencyPowers,
  specialPatrolUnitStaffInfo,
  remarks,
  endOfTreatmentTime,
  treatmentDateEnd,
  eventHappeningTime,
  eventHappeningDate,
}) => {
  return {
    query: `mutation createHandlingTrafficEvent{
      createHandlingTrafficEvent(
        projectId:"${projectId}"
        side:"${side}"
        location:"${location}"
        reportingFactor:"${reportingFactor}"
        element:"${element}"
        datailOfTheAccident:"${datailOfTheAccident}"
        mixedCars:"${mixedCars}"
        telephoneReportingForProjectManagement:"${telephoneReportingForProjectManagement}"
        callCenterReportingOperationsTeam:"${callCenterReportingOperationsTeam}"
        reportByTelephoneThePolice:"${reportByTelephoneThePolice}"
        telephoneReportingAndEmergencyPowers:"${telephoneReportingAndEmergencyPowers}"
        specialPatrolUnitStaffInfo:"${specialPatrolUnitStaffInfo}"
        remarks:"${remarks}"
        endOfTreatmentTime: "${toOffsettedTimestamp(endOfTreatmentTime)}",
        treatmentDateEnd: "${toOffsettedTimestamp(treatmentDateEnd)}",
        eventHappeningTime: "${toOffsettedTimestamp(eventHappeningTime)}",
        eventHappeningDate: "${toOffsettedTimestamp(eventHappeningDate)}",
      ){
        id
        projectId{
          id
        }
      }
    }`,
  };
};

export default () => {
  const { getParams } = useRouting();
  const { projectId } = getParams(['projectId']);
  const { role } = useRoleContext();
  const {
    newFiles,
    handleAddNewFile,
    handleNewDeleteFile,
    handleNewFileChange,
    handleNewFileDescriptionChange,
  } = useNewFiles();
  const { handleCreateItem, handleBackAction } = useCreateForm({
    createItemQuery: (values) =>
      api.fetchQuery(createForm4Query(values)).then(() => showNotification(dictionaryPhrases.createFormSuccess)),
    newFiles,
    formType: FORM_TYPE.form4,
    createListItemResponseName: 'createHandlingTrafficEvent',
    projectId: projectId || '',
  });

  return (
    <DashboardContentWrapper header={FORM_HEADER.form4.create}>
      <Form
        handleBackAction={handleBackAction}
        initialValues={dependenciesForm4[role].enabledFields}
        onSubmit={handleCreateItem}
        canAddFiles
        showSubmit
        showFiles={false}
        validationSchema={dependenciesForm4[role].validationSchema}
        enabledFields={dependenciesForm4[role].enabledFields}
        handleAddNewFile={handleAddNewFile}
        handleNewDeleteFile={handleNewDeleteFile}
        handleNewFileChange={handleNewFileChange}
        handleNewFileDescriptionChange={handleNewFileDescriptionChange}
        newFiles={newFiles}
      />
    </DashboardContentWrapper>
  );
};
