import { lifecycle, compose, withProps, withState, withHandlers } from 'recompose';
import { withRouter } from 'react-router';

import { withLogProps, withRole, withPartialUpdatingAdmins } from 'hoc/index';
import routes from 'pages/routes';
import { toBase64, showNotification } from 'utils/index';
import api from 'api/index';
import { FORM_HEADER } from 'constants/formSetup';
import dictionaryPhrases from 'constants/dictionary.phrases';

import { initialValues } from '../ProjectForm/ProjectForm.schema';
import Component from './ProjectPage';

const getCurrentProjectQuery = (projectId) => ({
  query: `query findAllProject{
    findAllProject(finder:{id:"${projectId}"}){
      name
      mainContractor
      logoMainContractor
      managementCompany
      contractNumber
      qcCompany
      qaCompany
      customerCompany
    }
  }`,
});

const updateProjectQuery = ({
  id,
  name,
  logoMainContractor,
  mainContractor,
  managementCompany,
  contractNumber,
  qcCompany,
  qaCompany,
  customerCompany,
}) => ({
  query: `mutation updateProject{
    updateProject(
      id: "${id}"
      deleted:false
      ${name ? `name:"${name}"` : ''}
      ${logoMainContractor ? `logoMainContractor: "${logoMainContractor}"` : ''}
      ${mainContractor ? `mainContractor: "${mainContractor}"` : ''}
      ${managementCompany ? `managementCompany: "${managementCompany}"` : ''}
      ${contractNumber ? `contractNumber: "${contractNumber}"` : ''}
      ${qcCompany ? `qcCompany: "${qcCompany}"` : ''}
      ${qaCompany ? `qaCompany: "${qaCompany}"` : ''}
      ${customerCompany ? `customerCompany: "${customerCompany}"` : ''}
      ){
        id
      }
    }
  `,
});

const enchance = compose(
  withRouter,
  withRole,
  withPartialUpdatingAdmins,
  withState('initialValues', 'setInitialValues', initialValues),
  withState('isFetching', 'setIsFetching', false),
  withHandlers({
    handleUpdate: (props) => (values) => {
      props.setIsFetching(true);
      // NOTE: needed to defend from base64 initial value
      const promisedLogoValue =
        typeof values.logoMainContractor === 'object'
          ? toBase64(values.logoMainContractor)
          : Promise.resolve(values.logoMainContractor);

      promisedLogoValue.then((logoMainContractor) =>
        props
          .makePartialUpdate({
            initialValues: props.initialValues,
            values: { ...values, logoMainContractor },
            queryCreator: updateProjectQuery,
            id: props.match.params.id,
          })
          .then(() => showNotification(dictionaryPhrases.updateProjectSuccess, 'success'))
          .then(() => {
            props.history.push(routes.projects.path);
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => props.setIsFetching(false)),
      );
    },
  }),
  withProps((props) => {
    return {
      headerUpdate: FORM_HEADER.project.update,
      onSubmit: props.handleUpdate,
      handleSubmit: props.handleUpdate,
      handleBackAction: () => props.history.goBack(),
    };
  }),
  withLogProps,
  lifecycle({
    componentDidMount() {
      this.props.setIsFetching(true);

      api
        .fetchQuery(getCurrentProjectQuery(this.props.match.params.id))
        .then(({ findAllProject: projects }) => {
          this.props.setInitialValues({
            ...this.props.initialValues,
            ...projects[0],
          });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => this.props.setIsFetching(false));
    },
  }),
);

export default enchance(Component);
