// @flow
import * as React from 'react';

import { toOffsettedTimestamp, showNotification } from 'utils/index';
import { FORM_HEADER, FORM_TYPE } from 'constants/formSetup';
import dependenciesForm3 from 'constants/form3Setup';
import dictionary from 'constants/dictionary';
import dictionaryPhrases from 'constants/dictionary.phrases';
import useCreateForm from 'hooks/useCreateForm';
import useNewFiles from 'hooks/useNewFiles';
import { useRouting } from 'hooks/useRouting';
import useRoleContext from 'hooks/useRoleContext';
import { DashboardContentWrapper } from 'components/index';
import api from 'api/index';

import Form from 'pages/TreatmentOfImpaired/TreatmentOfImpairedForm/TreatmentOfImpairedForm';
import { initialValuesCreate } from 'pages/TreatmentOfImpaired/TreatmentOfImpairedForm/TreatmentOfImpairedForm.schema';

const createForm3Query = ({
  projectId,
  side,
  location,
  element,
  testCode,
  safetyFlawDetails,
  telephoneReportingOperationsTeam,
  remarks,
  monitoringDate,
  correctiveAction,
  responsiblePerson,
  descriptionOfPerformedCorrectiveAction,
  endOfTreatmentTime,
  treatmentDateEnd,
  reportNumber,
  eventHappeningTime,
  eventHappeningDate,
  imageBefore = dictionary.no,
  imageAfter = dictionary.no,
  durationRequiredForTreatment,
  actualTreatmentTime,
  deviationTime,
  repairDateOfDefects,
}) => {
  return {
    query: `mutation createTreatmentOfImpairedByMonitoringReport {
      createTreatmentOfImpairedByMonitoringReport(
        projectId: "${projectId}"
        side: "${side}"
        location: "${location}"
        element: "${element}"
        monitoringDate: "${toOffsettedTimestamp(monitoringDate)}"
        testCode: "${testCode}"
        safetyFlawDetails: "${safetyFlawDetails}"
        telephoneReportingOperationsTeam: "${telephoneReportingOperationsTeam}"
        remarks: "${remarks}"
        correctiveAction: "${correctiveAction}"
        responsiblePerson: "${responsiblePerson}"
        descriptionOfPerformedCorrectiveAction: "${descriptionOfPerformedCorrectiveAction}"
        endOfTreatmentTime: ${toOffsettedTimestamp(endOfTreatmentTime)}
        treatmentDateEnd: ${toOffsettedTimestamp(treatmentDateEnd)}
        reportNumber: "${reportNumber}"
        eventHappeningTime: ${toOffsettedTimestamp(eventHappeningTime)}
        eventHappeningDate: ${toOffsettedTimestamp(eventHappeningDate)}
        imageBefore: "${imageBefore}"
        imageAfter: "${imageAfter}"
        durationRequiredForTreatment: "${durationRequiredForTreatment}"
        actualTreatmentTime: "${actualTreatmentTime}"
        deviationTime: "${deviationTime}"
        repairDateOfDefects: ${toOffsettedTimestamp(repairDateOfDefects)}
      ) {
        id
        projectId {
          id
        }
      }
    }`,
  };
};

export default () => {
  const { getParams } = useRouting();
  const { projectId } = getParams(['projectId']);
  const { role } = useRoleContext();
  const {
    newFiles,
    handleAddNewFile,
    handleNewDeleteFile,
    handleNewFileChange,
    handleNewFileDescriptionChange,
  } = useNewFiles();
  const { handleCreateItem, handleBackAction } = useCreateForm({
    createItemQuery: (values) =>
      api.fetchQuery(createForm3Query(values)).then(() => showNotification(dictionaryPhrases.createFormSuccess)),
    newFiles,
    formType: FORM_TYPE.form3,
    createListItemResponseName: 'createTreatmentOfImpairedByMonitoringReport',
    projectId: projectId || '',
  });

  return (
    <DashboardContentWrapper header={FORM_HEADER.form3.create}>
      <Form
        handleBackAction={handleBackAction}
        initialValues={initialValuesCreate}
        onSubmit={handleCreateItem}
        canAddFiles
        showSubmit
        showFiles={false}
        isCreateForm
        validationSchema={dependenciesForm3[role].validationSchema}
        enabledFields={dependenciesForm3[role].enabledFields}
        handleAddNewFile={handleAddNewFile}
        handleNewDeleteFile={handleNewDeleteFile}
        handleNewFileChange={handleNewFileChange}
        handleNewFileDescriptionChange={handleNewFileDescriptionChange}
        newFiles={newFiles}
      />
    </DashboardContentWrapper>
  );
};
