// @flow
const dictionary: { [key: string]: string } = {
  actualTreatmentTime: 'זמן טיפול בפועל',
  add: 'הוסף',
  addEmails: 'הוסף דוא"ל',
  additionalFilters: 'פילטרים נוספים',
  agreementDocuments: 'מסמך להסכם',
  alertLog: 'יומן התראות',
  attachFile: 'הוסף קובץ',
  callCenterReportingOperationsTeam: 'דיווח טלפוני לצוות תפעול',
  cancel: 'בטל',
  changePassword: 'שינוי סיסמה',
  changePasswordHeader: 'שנה סיסמא',
  clear: 'נקה',
  close: 'בטל',
  CLOSED: 'סגור',
  closedStatusForm14: 'סגור',
  codeModalHeader: 'קוד',
  COMPLETED: 'טיפול הסתיים',
  confirmPassword: 'אשר סיסמה',
  contact: 'איש קשר',
  contacts: 'אנשי קשר',
  contractNumber: 'מספר חוזה',
  correctiveAction: 'טיפול הנדרש',
  create: 'יצירה',
  createBy: 'נפתח ע"י',
  createContact: 'צור איש קשר',
  createHandlingRoadAccident: 'טיפול באירוע תאונת דרכים',
  createHandlingTrafficEvent: 'טיפול באירוע תנועה',
  createProject: 'צור פרויקט',
  createSafetyTreatment: 'טיפול בליקוי בטיחות',
  createTreatmentOfImpairment: 'טיפול בליקויים לאחר דוח ניטור',
  createUser: 'צור משתמש',
  createUserPermission: 'צור הרשאה',
  customerCompany: 'מזמין',
  datailOfTheAccident: 'פירוט תאונה',
  detailsOfTheEvent: 'פירוט האירוע',
  dateOfDefectsReport: 'תאריך דוח ליקויים',
  dateOfRepairOfDefectsReport: 'תאריך דוח תיקון ליקויים',
  dateOfRepairOfDefects: 'תאריך תיקון הליקויים',
  defaultContact: 'איש קשר בפרויקט',
  description: 'תיאור',
  descriptionOfPerformedCorrectiveAction: 'פירוט ביצוע פעולה מתקנת',
  deviationTime: 'זמן חריגה',
  durationRequiredForTreatment: 'משך זמן נדרש לטיפול',
  element: 'אלמנט',
  email: 'דוא"ל',
  endOfTreatmentTime: 'שעת סיום טיפול',
  endTime: 'שעת סיום',
  eventHappeningDate: 'תאריך האירוע',
  eventHappeningTime: 'זמן האירוע',
  monitoringReportTime: 'שעת דוח ניטור',
  excel: 'excel',
  failesSafetyNo: 'מס טופס',
  file: 'קובץ',
  filter: 'הפל',
  finishDate: 'תאריך סיום',
  firstName: 'שם פרטי',
  forms: 'טפסים',
  from: 'From',
  fromDate: 'מהתאריך',
  generalInformationManagement: 'ניהול מידע כללי',
  globalRole: 'תפקיד גלובלי',
  headerTitle: 'כבישים, תפעול ואחזקה',
  imageAfter: 'תמונה ממקום האירוע אחרי הטיפול',
  imageBefore: 'תמונה ממקום האירוע לפני הטיפול',
  lastName: 'שם משפחה',
  location: 'מיקום התאונה',
  eventLocation: 'מיקום האירוע',
  defectiveLocation: 'מיקום ליקוי',
  locationEclipce: 'מיקום הליקוי',
  locationMonitoringBySegments: 'מיקום ניטור לפי קטעים',
  login: 'התחברות',
  logoMainContractor: 'לוגו',
  mainContractor: 'קבלן ראשי',
  managementCompany: 'חברת ניהול',
  message: 'הודעה',
  mixedCars: 'רכבים מעורבים',
  monitoringDate: 'תאריך ביצוע ניטור',
  monitoringReportDate: 'תאריך דוח ניטור',
  name: 'שם פרויקט',
  no: 'לא',
  number: 'מספר',
  OPEN: 'פתוח',
  openedStatusForm14: 'פתוח',
  openingDate: 'תאריך פתיחה',
  openingHour: 'שעת פתיחה',
  openingName: 'נפתח ע"י',
  OPERATOR_SUBMIT: 'מפעיל סיים',
  OPERATOR_SUCCESS: 'מפעיל בתהליך',
  otherSafetyFlaw: 'אחר',
  password: 'סיסמה',
  pdf: 'pdf',
  phone: 'טלפון',
  position: 'תפקיד',
  projectId: 'בחר פרויקט',
  projectName: 'פרויקט',
  projects: 'פרויקטים',
  qaCompany: 'הבטחת איכות',
  QC_SUBMIT: 'בקר איכות סיים',
  QC_SUCCESS: 'בקר איכות בתהליך',
  qcCompany: 'בקרת איכות',
  remarks: 'הערות',
  reportByTelephoneThePolice: 'דווח טלפוני למשטרה',
  reportingFactor: 'גורם מדווח',
  reportNumber: 'דו"ח ניטור מס',
  reports: 'דוחות',
  responsiblePerson: 'גורם המטפל',
  rollBack: 'חזור',
  safetyFlawDetails: 'פירוט ליקוי בטיחות',
  save: 'שמור',
  selectedEmails: 'כתובות דוא"ל נבחרות',
  sendEmail: 'שלח דוא"ל',
  side: 'הסט',
  sidebarHandlingRoadAccident: 'טיפול באירוע תאונת דרכים',
  sidebarTreatmentOfDefectsAfterMonitoring: 'טיפול בליקויים לאחר ניטור',
  sidebarSafetyTreatment: 'טיפול בליקוי בטיחות',
  sidebarTreatmentOfImpairment: 'טיפול בליקויים לאחר דוח ניטור',
  sidebarHandlingTrafficEvent: 'טיפול באירוע תנועה',
  sigIn: 'היכנס',
  sign: 'חתום',
  specialPatrolUnitStaffInfoL: 'פרטי צוות היס"מ',
  status: 'סטאטוס',
  step: 'שלב',
  submitDateForm14: 'סגירת אירוע',
  telephoneReportingAndEmergencyPowers: 'דיווח טלפוני לכוחות חירום',
  telephoneReportingForProjectManagement: 'דיווח טלפוני להנהלת הפרויקט',
  telephoneReportingOperationsTeam: 'דיווח טלפוני לצוות',
  telephoneReportToTheCallCenterAboutSafetyDeficiencies: 'דיווח טלפוני למוקד על ליקויי בטיחות',
  telephoneReportingToTheOperationsTeamForTerminationOfTreatment: 'דיווח טלפוני למרכז התפעול לסיום הטיפול',
  testCode: 'קוד בדיקה',
  to: 'to',
  toDate: 'עד תאריך',
  total: 'Total',
  treatmentDateEnd: 'תאריך סיום טיפול',
  updateAt: 'תאריך עדכון אחרון',
  updateBy: "עודכן ע''י",
  updateContact: 'עדכן איש קשר',
  updateHandlingRoadAccident: 'טיפול באירוע תאונת דרכים',
  updateHandlingTrafficEvent: 'טיפול באירוע תנועה',
  updateProject: 'עדכן פרויקט',
  updateSafetyTreatment: 'טיפול בליקוי בטיחות',
  updateTreatmentOfImpairment: 'טיפול בליקויים לאחר דוח ניטור',
  updateUser: 'צור משתמש',
  updateUserPermission: 'צור הרשאה',
  userId: 'בחר משתמש',
  userPermissions: 'ניהול הרשאות',
  users: 'משתמשים',
  withReport: 'דוח',
  yes: 'כן',

  'reportForm.formTypeMap.trafficEvents.showName': 'ריכוז אירועי תנועה',
  'reportForm.formTypeMap.trafficEvents.saveName': 'ריכוז_אירועי_תנועה',

  'reportForm.formTypeMap.trafficAccidents.showName': 'ריכוז תאונות דרכים',
  'reportForm.formTypeMap.trafficAccidents.saveName': 'ריכוז_תאונות_דרכים',

  'reportForm.formTypeMap.safetyTreatments.showName': 'ריכוז ליקוי בטיחות',
  'reportForm.formTypeMap.safetyTreatments.saveName': 'ריכוז_ליקוי_בטיחות',

  'reportForm.formTypeMap.treatmentOfImpairments.showName': 'ריכוז טיפול בליקויים לאחר דוח ניטור',
  'reportForm.formTypeMap.treatmentOfImpairments.saveName': 'טופס ריכוז – טיפול בליקויים לאחר דוח ניטור',

  OPERATOR: 'מפעיל',
  QC: 'בקר איכות',
  FOREMAN: 'מנהל עבודה',
  ADMIN: 'admin',
  SUPER_ADMIN: 'super_admin',
};
export default dictionary;
