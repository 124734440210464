// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import { Popup, Loading, CommonInformation } from 'components/index';
import type { PageBy } from 'types/Pageby';
import type { Record } from 'types/utility';
import type { AnyObject, Column } from 'types/frontTypes';
import type { Modal, Popover } from 'hooks/usePopup';
import type { PopoverNameList } from 'hooks/useTable';

import Toolbar from './Toolbar/Toolbar';
import AdditionalFilters from './AdditionalFilters/AdditionalFilters';
import Data from './Data/Data';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    background: '#f6f6f6',
  },
}));

type Props = {|
  createAvailable: boolean,
  createLink: string,
  data: { isLoading: boolean, tableItems: Array<{ id: string }>, rows: number },
  columns: Column<*>[],
  pagination: {
    pageBy: PageBy,
    changePage: (page: number) => void,
  },
  useProjectsData: ?{
    projects: Array<{ id: string, name: string }>,
    projectId: string,
    setProjectId: (string) => void,
  },
  popup: {
    modal: Modal | null,
    popover: Popover | null,
    openPopover: (
      name: PopoverNameList,
      record: Record<string, any>,
    ) => (event: SyntheticMouseEvent<HTMLButtonElement, MouseEvent>) => void,
    closePopup: () => void,
  },
  filtering: ?{
    submitFilters: (values: AnyObject) => void,
    formStatusVariants: ?(string[]),
  },
|};

const DashboardList = ({
  filtering,
  createAvailable,
  createLink,
  data: { isLoading, tableItems, rows },
  columns,
  pagination,
  useProjectsData,
  popup: { modal, popover, closePopup },
}: Props) => {
  const classes = useStyles();

  return (
    <>
      {isLoading && <Loading />}

      <Paper className={classes.root}>
        <Toolbar
          createAvailable={createAvailable}
          createLink={createLink}
          rows={rows}
          pagination={pagination}
          useProjectsData={useProjectsData}
        />

        {filtering && <AdditionalFilters filtering={filtering} />}

        {tableItems.length ? (
          <Data isLoading={isLoading} tableItems={tableItems} columns={columns} />
        ) : (
          <CommonInformation>No data to showing</CommonInformation>
        )}

        <Popup modal={modal} popover={popover} closePopup={closePopup} />
      </Paper>
    </>
  );
};

export default DashboardList;
