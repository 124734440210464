import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withStyles from '@material-ui/core/styles/withStyles';
import InputLabel from '@material-ui/core/InputLabel';
import T from 'prop-types';

import dictionary from 'constants/dictionary';
import dictionaryPhrases from 'constants/dictionary.phrases';
import { DefaultTextField } from 'components';

const styles = () => ({
  bottomBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  textMessage: {
    width: '100%',
    direction: 'rtl',
  },
  buttonContainer: {
    '& > button': {
      marginRight: '10px',
    },
  },
});

const SignModal = ({ classes, handleDialogClose, handleCodeStateChange, handleSignForm, code, dialogs }) => {
  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={dialogs.isOpenCode}
      onClose={() => handleDialogClose('isOpenCode')}
      aria-labelledby="scroll-dialog-title"
    >
      <DialogTitle id="scroll-dialog-title">{dictionary.codeModalHeader}</DialogTitle>

      <DialogContent>
        <div className={classes.bottomBlock}>
          <InputLabel htmlFor="message-text" className={classes.label}>
            {dictionary.codeModalHeader}
          </InputLabel>
          <DefaultTextField
            name="code"
            value={code && code.value}
            type="password"
            placeholder={dictionaryPhrases.signCodePlaceholder}
            onChange={(event) => handleCodeStateChange('value', event.target.value)}
            error={code && code.error}
            helperText={code && code.error}
          />
        </div>
      </DialogContent>

      <DialogActions>
        <div className={classes.buttonContainer}>
          <Button onClick={handleSignForm} variant="contained" color="primary">
            {dictionary.sign}
          </Button>
          <Button onClick={() => handleDialogClose('isOpenCode')} variant="contained" color="primary">
            {dictionary.close}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

SignModal.propTypes = {
  classes: T.objectOf(T.string).isRequired,
  handleDialogClose: T.func.isRequired,
  handleCodeStateChange: T.func.isRequired,
  handleSignForm: T.func.isRequired,
  code: T.objectOf(T.string).isRequired,
  dialogs: T.objectOf(T.oneOfType([T.bool, T.object])).isRequired,
};

export default withStyles(styles)(SignModal);
