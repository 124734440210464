import React from 'react';
import T from 'prop-types';

import { DashboardContentWrapper, Loading } from 'components/index';
import Form from '../ContactForm/ContactForm';

const ContactPage = ({ isFetching, ...props }) => {
  return (
    <>
      {isFetching && <Loading />}

      <DashboardContentWrapper header="Update contact">
        <Form {...props} />
      </DashboardContentWrapper>
    </>
  );
};

ContactPage.propTypes = {
  isFetching: T.bool.isRequired,
};

export default ContactPage;
