// @flow
import * as React from 'react';
import jwtDecode from 'jwt-decode';

import api from 'api/index';
import { AUTH_TOKEN } from 'constants/auth';
import type { Role } from 'types/ServerTypes/Role';
import { RoleObjectEnum } from 'types/ServerTypes/Role';

type ContextType = {
  roleContext: {
    role: Role,
    setRole: (newRole: Role) => void,
  },
  role: Role,
  setRole: (newRole: Role) => void,
};

const authToken = api[AUTH_TOKEN];

const initialState: {
  role: Role,
} = { role: authToken ? jwtDecode(authToken).role : RoleObjectEnum.ADMIN };

export const RoleContext = React.createContext<ContextType>({
  ...initialState,
  setRole: () => {},
  roleContext: {
    ...initialState,
    setRole: () => {},
  },
});

type Props = {
  children: React.Node,
};

export const RoleContextProvider = ({ children }: Props) => {
  const [role, setRole] = React.useState<Role>(initialState.role);

  return (
    <RoleContext.Provider
      value={{
        role,
        setRole,
        roleContext: {
          role,
          setRole,
        },
      }}
    >
      {children}
    </RoleContext.Provider>
  );
};
export default RoleContextProvider;
