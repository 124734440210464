// @flow
import * as React from 'react';
import { FORM_HEADER } from 'constants/formSetup';
import { OPERATOR, ADMIN, SUPER_ADMIN, QC, FOREMAN } from 'constants/global-roles';
import dictionaryPhrases from 'constants/dictionary.phrases';
import useCreateItem from 'hooks/useCreateItem';
import useRoleContext from 'hooks/useRoleContext';
import { DashboardContentWrapper } from 'components/index';
import api from 'api/index';
import { showNotification } from 'utils/index';

import Form from '../UserForm/UserForm';
import {
  initialValuesCreate as initialValues,
  validationSchemaCreate as validationSchema,
} from '../UserForm/UserForm.schema';

const createUserQuery = ({ email, firstName, lastName, phone, contact, globalRole }) => ({
  query: `mutation createUser{
    createUser(
    email:"${email}"
    firstName:"${firstName}"
    lastName:"${lastName}"
    phone:"${phone}"
    deleted:false
    contact: ${contact}
    globalRole:${globalRole}
    ){
      id
    }
  }`,
});

export default () => {
  const { role } = useRoleContext();
  const { handleCreateItem, handleBackAction } = useCreateItem({
    createItemQuery: async (values) => {
      const res = await api.fetchQuery(createUserQuery(values));
      showNotification(dictionaryPhrases.createUserSuccess);

      if (values.fileForCode) {
        const inputs = [
          { name: 'userId', value: res.createUser.id },
          { name: 'file', value: values.fileForCode },
        ];

        return api.sendCodeToUser(inputs);
      }

      return res;
    },
  });

  const globalRoleSelectValiants = role === SUPER_ADMIN ? [ADMIN, OPERATOR, QC, FOREMAN] : [OPERATOR, QC, FOREMAN];

  return (
    <DashboardContentWrapper header={FORM_HEADER.user.create}>
      <Form
        validationSchema={validationSchema}
        globalRoleSelectValiants={globalRoleSelectValiants}
        handleBackAction={handleBackAction}
        initialValues={initialValues}
        onSubmit={handleCreateItem}
        showEmail
      />
    </DashboardContentWrapper>
  );
};
