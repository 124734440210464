import React from 'react';
import T from 'prop-types';

import { DashboardContentWrapper, FormInfo } from 'components/index';

const FormPage = ({ headerUpdate, formContent: FormContent, ...props }) => {
  return (
    <DashboardContentWrapper header={headerUpdate}>
      <FormInfo {...props} />

      <FormContent {...props} />
    </DashboardContentWrapper>
  );
};

FormPage.propTypes = {
  headerUpdate: T.string.isRequired,
};

export default FormPage;
