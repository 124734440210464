// @flow
import * as React from 'react';
import { useReducer } from 'react';
import { useAsyncEffect } from 'use-async-effect';

import api from 'api/index';
import { FORM_HEADER } from 'constants/formSetup';
import dictionaryPhrases from 'constants/dictionary.phrases';
import { DashboardContentWrapper } from 'components/index';
import useCreateItem from 'hooks/useCreateItem';
import { showNotification } from 'utils/index';

import { initialValues } from '../ProjectUserForm/ProjectUserForm.schema';
import Form from '../ProjectUserForm/ProjectUserForm';

const createUserProjectQuery = ({ projectId, userId, defaultContact }) => ({
  query: `mutation createProjectUser{
    createProjectUser(
      projectId: "${projectId}"
      userId: "${userId}"
      defaultContact:${defaultContact}
    ){
      id
    }
  }`,
});

const getAllProjectsQuery = () => ({
  query: `query findAllProject{
    findAllProject{
      name
      id
      deleted
    }
  }`,
});

const getAllUsersQuery = () => ({
  query: `query findAllUser{
    findAllUser{
      id
      firstName
      lastName
      globalRole
      deleted
    }
  }`,
});

export default () => {
  const [state, setState] = useReducer((oldStatestate, newState) => ({ ...oldStatestate, ...newState }), {
    users: [],
    projects: [],
  });
  const { handleCreateItem, handleBackAction } = useCreateItem({
    createItemQuery: (values) =>
      api
        .fetchQuery(createUserProjectQuery(values))
        .then(() => showNotification(dictionaryPhrases.createUserPermissionSuccess)),
  });

  useAsyncEffect(async () => {
    const [projects, users] = await Promise.all([
      api.fetchQuery(getAllProjectsQuery()).then((res) => res.findAllProject.filter((project) => !project.deleted)),
      api.fetchQuery(getAllUsersQuery()).then((res) => res.findAllUser.filter((user) => !user.deleted)),
    ]);

    setState({ projects, users });
  }, []);

  return (
    <DashboardContentWrapper header={FORM_HEADER.userPermission.create}>
      <Form
        handleBackAction={handleBackAction}
        initialValues={initialValues}
        onSubmit={handleCreateItem}
        users={state.users}
        projects={state.projects}
      />
    </DashboardContentWrapper>
  );
};
