// @flow
import { useReducer } from 'react';
import { useAsyncEffect } from 'use-async-effect';
import api from 'api/index';

type ShortProject = {
  id: string,
  name: string,
};

type State = {
  projects: ShortProject[],
  projectId: string,
};

const getAllProjectsQuery = () => ({
  query: `query{
    findAllProject(
      finder: {
        deleted: false
      }
    ){
      id
      name
    }
  }`,
});

export default () => {
  const [state, setState] = useReducer(
    (oldState: State, newState: $Shape<State>): State => ({ ...oldState, ...newState }),
    {
      projects: [],
      projectId: '',
    },
  );

  const setProjectId = (newValue: string | ((oldValue: string) => string)): void => {
    if (typeof newValue === 'function') {
      setState({ projectId: newValue(state.projectId) });
      return;
    }

    setState({ projectId: newValue });
  };

  useAsyncEffect(async () => {
    const res = await api.fetchQuery(getAllProjectsQuery());
    const projectList = (res.findAllProject: ShortProject[] | null);

    if (projectList && projectList.length) {
      setState({ projects: projectList, projectId: projectList[0].id });
    }
  }, []);

  return {
    ...state,
    setProjectId,
  };
};
