import { compose, withStateHandlers } from 'recompose';

const enhancer = compose(
  withStateHandlers(
    {
      deleteModal: {
        isOpen: false,
        deleteIndex: 0,
      },
    },
    {
      handleModalOpen: () => (deleteIndex) => ({
        deleteModal: {
          isOpen: true,
          deleteIndex,
        },
      }),
      handleModalClose: () => () => ({
        deleteModal: {
          isOpen: false,
        },
      }),
    },
  ),
);

export default enhancer;
