// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import routes from 'pages/routes';
import { showNotification } from 'utils/index';
import dictionary from 'constants/dictionary';
import dictionaryPhrases from 'constants/dictionary.phrases';
import { useRouting } from 'hooks/useRouting';
import useTable from 'hooks/useTable';
import useRoleContext from 'hooks/useRoleContext';
import api from 'api/index';
import { Link } from 'react-router-dom';
import { EditIcon, DeleteIcon, List } from 'components/index';
import type { Column } from 'types/frontTypes';
import type { ProjectUser } from 'types/ServerTypes/ProjectUser';

const deleteUserPermissionQuery = (id) => ({
  query: `mutation deleteProjectUser{
		deleteProjectUser(
			id:"${id}"
		)
	}`,
});

const getAllUserPermissionsQuery = () => ({
  query: `query {
      findAllProjectUser{
        id
        createdAt
        projectId{
          name
        }
        userId{
          firstName
          lastName
          globalRole
        }
      }
    }`,
});

const useStyles = makeStyles({
  buttons: {
    whiteSpace: 'nowrap',
    width: 120,
    display: 'flex',
    justifyContent: 'space-around',
  },
});

export default () => {
  const { match, formatRoute, location } = useRouting();
  const { role } = useRoleContext();
  const classes = useStyles();

  const createLink = routes.projectUserCreate.path;
  const isShowDelete = true;
  const createAvailable = true;

  const {
    data,
    pagination,
    popup,
    popup: { openPopover },
  } = useTable({
    getItemsQuery: () =>
      api
        .fetchQuery(getAllUserPermissionsQuery())
        .then(({ findAllProjectUser }) => ({ data: findAllProjectUser, size: findAllProjectUser.length })),
    deleteItemQuery: (id) =>
      api.fetchQuery(deleteUserPermissionQuery(id)).then(() => {
        showNotification(dictionaryPhrases.deleteUserPermissionSuccess);
      }),
    isServerPagination: false,
  });

  const columns: Column<ProjectUser>[] = [
    {
      title: '',
      key: 'action',
      render: (record) => {
        return (
          <div className={classes.buttons}>
            <Link to={formatRoute(routes.projectUserPage.path, { id: record.id })}>
              <EditIcon />
            </Link>
            <DeleteIcon onClick={openPopover('deleteItem', record)} />
          </div>
        );
      },
    },
    {
      title: dictionary.name,
      key: 'name',
      render: (record) => `${record.userId.firstName} ${record.userId.lastName}`,
    },
    {
      title: dictionary.projectName,
      key: 'projectName',
      render: (record) => record.projectId.name,
    },
    {
      title: dictionary.position,
      key: 'position',
      render: (record) => record.userId.globalRole,
    },
  ];

  const useProjectsData = null;
  const filtering = null;

  return (
    <List
      data={data}
      createLink={createLink}
      columns={columns}
      pagination={pagination}
      createAvailable={createAvailable}
      popup={popup}
      useProjectsData={useProjectsData}
      filtering={filtering}
    />
  );
};
