// @flow
import { ADMIN, SUPER_ADMIN, OPERATOR, QC, FOREMAN } from 'constants/global-roles';
import { FORM_NAME } from 'constants/formSetup';
import routes from 'pages/routes';
import type { Role } from 'types/ServerTypes/Role';
import dictionary from './dictionary';

// TODO connect hasAccess with formSetup url

export type RouteTab = {
  hasAccess: boolean,
  name: string,
  route: string,
};

export type MenuTab = {
  tabKey?: string,
  hasAccess: boolean,
  name: string | null,
  tabs: RouteTab[],
};

export default (role: Role): MenuTab[] => [
  {
    tabKey: 'common',
    name: dictionary.generalInformationManagement,
    hasAccess: role === ADMIN || role === SUPER_ADMIN,
    tabs: [
      {
        hasAccess: true,
        name: dictionary.users,
        route: routes.users.path,
      },
      {
        hasAccess: true,
        name: dictionary.userPermissions,
        route: routes.projectUsers.path,
      },
      {
        hasAccess: true,
        name: dictionary.projects,
        route: routes.projects.path,
      },
    ],
  },
  {
    name: null,
    hasAccess: [ADMIN, SUPER_ADMIN, QC].includes(role),
    tabs: [
      {
        hasAccess: true,
        name: dictionary.alertLog,
        route: routes.emailSchedule.path,
      },
    ],
  },
  {
    name: null,
    hasAccess: true,
    tabs: [
      {
        hasAccess: true,
        name: dictionary.contacts,
        route: routes.contacts.path,
      },
    ],
  },
  {
    tabKey: 'forms',
    name: dictionary.forms,
    hasAccess: true,
    tabs: [
      {
        name: FORM_NAME.form4,
        route: routes.forms4.path,
        hasAccess: role === ADMIN || role === SUPER_ADMIN || role === OPERATOR,
      },
      {
        name: FORM_NAME.form1,
        route: routes.forms1.path,
        hasAccess: role === ADMIN || role === SUPER_ADMIN || role === OPERATOR,
      },
      {
        name: FORM_NAME.form2,
        route: routes.forms2.path,
        hasAccess: true,
      },
      {
        name: FORM_NAME.form3,
        route: routes.forms3.path,
        hasAccess: role === ADMIN || role === SUPER_ADMIN || role === QC || role === FOREMAN,
      },
    ],
  },
  {
    name: null,
    hasAccess: true,
    tabs: [
      {
        hasAccess: true,
        name: dictionary.reports,
        route: routes.getReports.path,
      },
    ],
  },
  {
    name: null,
    hasAccess: true,
    tabs: [
      {
        hasAccess: true,
        name: dictionary.changePassword,
        route: routes.changePassword.path,
      },
    ],
  },
];
