import React from 'react';
import ReactDOM from 'react-dom';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// FIX no folder scss, bacause too hard to find any styles and maintenance this
import './index.css';
import Router from 'pages/router';
import { RoleContextProvider } from 'store/role';

const App = () => (
  <RoleContextProvider>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Router />
    </MuiPickersUtilsProvider>
  </RoleContextProvider>
);

ReactDOM.render(<App />, document.getElementById('root'));
