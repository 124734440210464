// @flow
import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Watch from '@material-ui/icons/RemoveRedEye';

const useStyles = makeStyles(() => ({
  wrapperCircle: {
    color: '#d77e7e',
    fontSize: '14px',
    width: '30px',
    height: '30px',
    background: '#ffe3e1',
    borderRadius: '50%',
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      border: '1px solid #d77e7e',
      cursor: 'ponter',
    },
  },
  eye: {
    width: '20px',
    fill: '#d77e7e',
  },
}));

const Icon = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapperCircle}>
      <Watch className={classes.eye} />
    </div>
  );
};

export default Icon;
