// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import dictionary from 'constants/dictionary';
import type { Column } from 'types/frontTypes';
import dateFormLocale from 'constants/dateFormLocale';
import withDeleteDialogHandler from './DeleteModal/withDeleteDialogHandler';
import DeleteModal from './DeleteModal/DeleteModal';

const getStripedStyle = (index) => ({ background: index % 2 ? 'white' : 'rgb(236, 236, 236)' });

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 700,
    borderCollapse: 'separate',
    borderSpacing: '0px 15px',
  },
  headerCeil: {
    padding: '17px 0',
    textAlign: 'center',
    borderTop: 'none',
    borderBottom: 'none',
    color: '#000',
    fontWeight: '600',
  },
  tr: {
    borderBottom: '1px solid #e0e0e0',
  },
  ceil: {
    padding: '30px 10px',
    textAlign: 'center !important',
    borderTop: '1px solid #e0e0e0',
    borderLeft: '1px solid #e0e0e0',
    color: 'rgb(94, 94, 94)',
    maxWidth: '220px',
  },
  icon: {
    padding: '0 15px !important',
    width: '63px',
    borderTop: '1px solid #e0e0e0',
  },
}));

type ElemWithId = {
  id: string,
};

type Props = {|
  deleteModal: any,
  handleModalClose: () => void,
  isLoading: boolean,
  tableItems: ElemWithId[],
  columns: Column<ElemWithId>[],
|};

const TableData = ({ deleteModal, handleModalClose, isLoading, tableItems, columns }: Props) => {
  const classes = useStyles();

  return (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {columns.map((column) =>
              typeof column === 'string' ? (
                <TableCell key={column} className={classes.headerCeil}>
                  {dictionary[column]}
                </TableCell>
              ) : (
                <TableCell key={column.key} className={classes.headerCeil}>
                  {column.title}
                </TableCell>
              ),
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableItems.map((tableItem, index) => {
            return (
              <TableRow key={tableItem.id} className={classes.tr} style={{ ...getStripedStyle(index) }}>
                {columns.map((column) => {
                  let renderValue = typeof column === 'string' ? tableItem[column] : column.render(tableItem) || '-';

                  const regDate = /^\d{4}[-]\d{2}[-]\d{2}T/;

                  if (typeof renderValue === 'string' && regDate.test(renderValue)) {
                    renderValue = new Date(renderValue).toLocaleDateString(dateFormLocale);
                  }

                  return (
                    <TableCell className={classes.ceil} key={typeof column === 'string' ? column : column.key}>
                      {renderValue}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <DeleteModal
        handleDeleteSubmit={() => {
          // handleDelete(deleteModal.deleteIndex);
          handleModalClose();
        }}
        deleteModal={deleteModal}
        handleModalClose={handleModalClose}
      />
    </>
  );
};

export default withDeleteDialogHandler(TableData);
