import { compose, withHandlers } from 'recompose';
import { connect } from 'formik';

import dictionaryPhrases from 'constants/dictionary.phrases';
import Component from './SignModal';

const enchance = compose(
  connect,
  withHandlers({
    handleSignForm: (props) => () => {
      props.formik.validateForm().then((validation) => {
        if (Object.entries(validation).length || validation.constructor !== Object) {
          props.formik.setTouched(validation);
          props.handleDialogClose('isOpenCode');
          props.handleCodeStateChange('value', '');
          return;
        }
        if (!props.code || !props.code.value) {
          props.handleCodeStateChange('error', dictionaryPhrases.emptySignCodeWarning);
          return;
        }

        props.handleDialogClose('isOpenCode');
        props.handleSignForm(props.formik.values);
      });
    },
  }),
);

export default enchance(Component);
