// @flow
import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import type { FieldProps } from 'formik';

const useStyles = makeStyles({
  root: {
    maxWidth: '80%',
  },
});

type Props = FieldProps<any> & {
  label: string,
};

const CheckBoxField = ({ label, field: { name, value, onChange } }: Props) => {
  const classes = useStyles();
  return (
    <FormControlLabel
      value={value}
      checked={value}
      control={<Checkbox color="default" />}
      label={label}
      labelPlacement="end"
      onChange={onChange}
      name={name}
      className={classes.root}
    />
  );
};

export default CheckBoxField;
