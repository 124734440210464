import { lifecycle, compose, withProps, withState, withHandlers, renameProps } from 'recompose';
import { withRouter } from 'react-router';

import { showNotification } from 'utils/index';
import api from 'api/index';
import { withLogProps } from 'hoc/index';
import routes from 'pages/routes';
import dictionaryPhrases from 'constants/dictionary.phrases';
import Component from './ContactPage';
import { initialValues } from '../ContactForm/ContactForm.schema';

const getAllProjectsQuery = () => ({
  query: `query findAllProject{
    findAllProject{
      id
      name
    }
  }`,
});

const findAllContacts = (id) => ({
  query: `query {
    findAllContacts(
      finder: {
        id: "${id}"
      }
    ) {
      contacts {
        id
        name
        email
        position
        enable
        sendSaftyErrorAlertContact
        projectId {
          id
          name
        }
      }
      size
    }
  }`,
});

const updateContactQuery = ({ id, projectId, name, position, email, enable, sendSaftyErrorAlertContact }) => ({
  query: `mutation {
    updateContact(
      id: "${id}"
      projectId: "${projectId}"
      name: "${name}"
      position: "${position}"
      email: "${email}"
      enable: ${enable}
      sendSaftyErrorAlertContact: ${sendSaftyErrorAlertContact}
    ) {
        id
      }
  }`,
});

const enchance = compose(
  withRouter,
  withState('isFetching', 'setIsFetching', false),
  withState('initialValues', 'setInitialValues', initialValues),
  withState('projects', 'setProjects', []),
  withHandlers({
    handleUpdate: (props) => (values) => {
      props.setIsFetching(true);

      api
        .fetchQuery(updateContactQuery({ ...values, id: props.match.params.id }))
        .then(() => {
          showNotification(dictionaryPhrases.updateContactSuccess, 'success');

          props.history.push(routes.contacts.path);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => props.setIsFetching(false));
    },
  }),
  withProps((props) => {
    return {
      handleBackAction: () => props.history.goBack(),
    };
  }),
  renameProps({
    handleUpdate: 'onSubmit',
  }),
  withLogProps,
  lifecycle({
    componentDidMount() {
      this.props.setIsFetching(true);

      const query1 = api.fetchQuery(getAllProjectsQuery()).then((response) => {
        this.props.setProjects(response.findAllProject);
      });

      const query2 = api
        .fetchQuery(findAllContacts(this.props.match.params.id))
        .then(({ findAllContacts: { contacts } }) => {
          this.props.setInitialValues({
            ...this.props.initialValues,
            ...contacts[0],
            projectId: contacts[0].projectId.id,
          });
        });

      Promise.all([query1, query2])
        .catch((err) => {
          console.error(err);
        })
        .finally(() => this.props.setIsFetching(false));
    },
  }),
);

export default enchance(Component);
