// @flow
import { useContext } from 'react';

import { RoleContext } from 'store/role';

const useRoleContext = () => {
  return useContext(RoleContext);
};

export default useRoleContext;
