// @flow
import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  footerWrapper: {
    width: '100%',
    padding: theme.spacing(1.25),
    // background: theme.palette.primary.main,
    background: 'rgb(47, 94, 69)',
  },
  title: {
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
    fontWeight: 900,
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.footerWrapper}>
      <Typography variant="h6" className={classes.title}>
        {new Date().getFullYear()}
      </Typography>
    </div>
  );
};
export default Footer;
