import { compose, withState, withHandlers, renameProp } from 'recompose';
import { withRouter } from 'react-router-dom';

import api from 'api/index';
import routes from 'pages/routes';
import { withRole } from 'hoc/index';
import Component from './Login';

const getUserFromTokenQuery = () => ({
  query: `query getUserFromToken {
    getUserFromToken{
      authDataId{
        agreement
      }
    }
  }`,
});

const enchance = compose(
  withRouter,
  withRole,
  withState('isOpenAgreements', 'setIsOpenAgreements', false),
  withState('isOpenTerms', 'setIsOpenTerms', false),
  withHandlers({
    toggleOpenAgreements: (props) => () => {
      props.setIsOpenAgreements(!props.isOpenAgreements);
    },
    handleOpenTerms: (props) => () => {
      props.setIsOpenTerms(true);
    },
    handleCloseTerms: (props) => () => {
      props.setIsOpenTerms(false);
    },
  }),
  withHandlers({
    handleSubmit: (props) => ({ email, password }) => {
      console.log('login', email, password);

      api
        .login(email, password)
        .then((role) => {
          props.roleContext.setRole(role);
        })
        .then(() => api.fetchQuery(getUserFromTokenQuery()))
        .then((res) => {
          if (res.getUserFromToken.authDataId.agreement) {
            props.history.push(routes.dashboard.path);
          } else {
            props.handleOpenTerms();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  }),
  renameProp('handleSubmit', 'onSubmit'),
);

export default enchance(Component);
