// @flow
import * as React from 'react';
import Popover from '@material-ui/core/Popover';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paperRoot: {
    padding: theme.spacing(1, 2),
  },
}));

type PopoverOrigin = {
  vertical: 'top' | 'center' | 'bottom' | number,
  horizontal: 'left' | 'center' | 'right' | number,
};

interface IProps {
  children: React.Node;
  anchorEl: HTMLButtonElement;
  onClose: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  open: boolean;
}

const CommonPopover = ({
  children,
  anchorEl,
  onClose,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'center',
  },
  open,
}: IProps) => {
  const classes = useStyles();

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    >
      <Paper classes={{ root: classes.paperRoot }}>{children}</Paper>
    </Popover>
  );
};

export default CommonPopover;
