export const USER = 'USER';
export const ADMIN = 'ADMIN';
export const SUPER_ADMIN = 'SUPER_ADMIN';
export const QC = 'QC';
export const OPERATOR = 'OPERATOR';
export const FOREMAN = 'FOREMAN';

export const contactRelations = {
  OPERATOR: (roleOnProject) => roleOnProject === QC || roleOnProject === FOREMAN,
  QC: (roleOnProject) => roleOnProject === FOREMAN || roleOnProject === OPERATOR,
  FOREMAN: (roleOnProject) => roleOnProject === QC || roleOnProject === OPERATOR,
  default: true,
};
