import React from 'react';
import T from 'prop-types';

import { DashboardContentWrapper, Loading } from 'components/index';
import Form from '../ProjectUserForm/ProjectUserForm';

const UserCreate = ({ headerUpdate, isFetching, ...props }) => {
  return (
    <>
      {isFetching && <Loading />}
      <DashboardContentWrapper header={headerUpdate}>
        <Form {...props} />
      </DashboardContentWrapper>
    </>
  );
};

UserCreate.propTypes = {
  headerUpdate: T.string.isRequired,
  isFetching: T.bool.isRequired,
};

export default UserCreate;
