import * as Yup from 'yup';

export default Yup.object().shape({
  status: Yup.string(),
  failesSafetyNo: Yup.string(),
  startDate: Yup.date().nullable(),
  finishDate: Yup.date().nullable(),
});

export const initialValues = {
  status: '',
  failesSafetyNo: '',
  startDate: null,
  finishDate: null,
};
