import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import T from 'prop-types';

import { Loading } from 'components/index';
import Chart from './Chart/Chart';

const styles = () => ({
  root: {
    display: 'flex',
    justifyContent: 'space-around',
  },
});

const HomeDashboard = ({ classes, charts, isFetching }) => {
  return (
    <>
      {isFetching && <Loading />}

      <div className={classes.root}>
        {Object.values(charts)
          .filter(Boolean)
          .map((chart, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Chart key={index} data={chart.data} header={chart.header} total={chart.total} />
          ))}
      </div>
    </>
  );
};

HomeDashboard.propTypes = {
  classes: T.objectOf(T.string).isRequired,
  charts: T.objectOf(T.any).isRequired,
  isFetching: T.bool.isRequired,
};

export default withStyles(styles)(HomeDashboard);
