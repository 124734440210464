// @flow
import * as React from 'react';
import { useState } from 'react';
import { useAsyncEffect } from 'use-async-effect';
import api from 'api/index';
import { FORM_HEADER } from 'constants/formSetup';
import dictionaryPhrases from 'constants/dictionary.phrases';
import { DashboardContentWrapper } from 'components/index';
import useCreateItem from 'hooks/useCreateItem';
import { showNotification } from 'utils/index';

import { initialValues } from '../ContactForm/ContactForm.schema';
import Form from '../ContactForm/ContactForm';

const createContactQuery = ({ projectId, name, position, email, enable, sendSaftyErrorAlertContact }) => ({
  query: `mutation {
    createContact(
      projectId: "${projectId}"
      name: "${name}"
      position: "${position}"
      email: "${email}"
      enable: ${enable}
      sendSaftyErrorAlertContact: ${sendSaftyErrorAlertContact}
    ) {
      id
    }
  }`,
});

const getAllProjectsQuery = () => ({
  query: `query findAllProject{
    findAllProject{
      name
      id
    }
  }`,
});

export default () => {
  const [projects, setProjects] = useState([]);

  const { handleCreateItem, handleBackAction } = useCreateItem({
    createItemQuery: (values) =>
      api.fetchQuery(createContactQuery(values)).then(() => showNotification(dictionaryPhrases.createContactSuccess)),
  });

  useAsyncEffect(async () => {
    // this.props.setIsFetching(true);

    const res = await api.fetchQuery(getAllProjectsQuery());
    setProjects(res.findAllProject || []); // to not to set null as projects set empty

    // .finally(() => this.props.setIsFetching(false));
  }, []);

  return (
    <DashboardContentWrapper header={FORM_HEADER.contact.create}>
      <Form
        initialValues={initialValues}
        onSubmit={handleCreateItem}
        projects={projects}
        handleBackAction={handleBackAction}
      />
    </DashboardContentWrapper>
  );
};
