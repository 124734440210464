/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';

import { DashboardContentWrapper } from 'components/index';
import api from 'api/index';
import dateFormLocale from 'constants/dateFormLocale';
import dictionary from 'constants/dictionary';

import { initialValues } from './ReportsForm/ReportsForm.schema';
import Form from './ReportsForm/ReportsForm';

const getAllProjectsQuery = () => ({
  query: `{
    findAllProject {
      name
      id
    }
  }`,
});

const getSummaryReportQuery = ({ formType, projectId, fromDate, toDate }) => ({
  query: `query report {
    getSummaryReport(
      formType: ${formType}
      projectId: "${projectId}"
      fromDate: "${fromDate.getTime()}"
      toDate: "${toDate.getTime()}"
    )
  }`,
});

// UGLY so ugly decision, need refactoring with formSetup and initSidebar
const formTypeMap = [
  {
    formType: 'FOURTH_FORM',
    name: dictionary['reportForm.formTypeMap.trafficEvents.showName'],
    reportName: dictionary['reportForm.formTypeMap.trafficEvents.saveName'],
  },
  {
    formType: 'FIRST_FORM',
    name: dictionary['reportForm.formTypeMap.trafficAccidents.showName'],
    reportName: dictionary['reportForm.formTypeMap.trafficAccidents.saveName'],
  },
  {
    formType: 'SECOND_FORM',
    name: dictionary['reportForm.formTypeMap.safetyTreatments.showName'],
    reportName: dictionary['reportForm.formTypeMap.safetyTreatments.saveName'],
  },
  {
    formType: 'THIRD_FORM',
    name: dictionary['reportForm.formTypeMap.treatmentOfImpairments.showName'],
    reportName: dictionary['reportForm.formTypeMap.treatmentOfImpairments.saveName'],
  },
];

export default () => {
  const history = useHistory();
  const [projectList, setProjectList] = useState([]);

  useEffect(() => {
    api.fetchQuery(getAllProjectsQuery()).then((res) => setProjectList(res.findAllProject));
  }, []);

  const handleSubmit = ({ fromDate, toDate, formType, projectId }) => {
    fromDate = fromDate === null ? new Date(0) : startOfDay(fromDate);
    toDate = toDate === null ? endOfDay(new Date()) : endOfDay(toDate);

    const summaryReportQuery = getSummaryReportQuery({
      fromDate,
      toDate,
      formType,
      projectId,
    });

    api
      .fetchQuery(summaryReportQuery)
      .then((res) => {
        const link = document.createElement('a');
        const { reportName } = formTypeMap.find((elem) => formType === elem.formType);

        const formatedFromDate = fromDate.toLocaleDateString(dateFormLocale);
        const formatedToDate = toDate.toLocaleDateString(dateFormLocale);

        link.download = `${reportName}_${formatedFromDate}-${formatedToDate}.xlsx`;
        link.href = `data:application/vnd.ms-excel;base64, ${encodeURI(res.getSummaryReport)}`;
        link.click();
      })
      .catch(console.error);
  };

  return (
    <DashboardContentWrapper header="צור ריכוז">
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        handleBackAction={history.goBack}
        selectValues={projectList}
        formTypeMap={formTypeMap}
      />
    </DashboardContentWrapper>
  );
};
