import dictionary from './dictionary';

export const COMPLETED = 'COMPLETED';
export const OPERATOR_SUCCESS = 'OPERATOR_SUCCESS';
export const OPERATOR_SUBMIT = 'OPERATOR_SUBMIT';
export const QC_SUCCESS = 'QC_SUCCESS';
export const QC_SUBMIT = 'QC_SUBMIT';

export const statusOrder = [OPERATOR_SUCCESS, OPERATOR_SUBMIT, QC_SUCCESS, QC_SUBMIT, COMPLETED];

export const statusColors = {
  [dictionary[OPERATOR_SUCCESS]]: '#ff4d4d',
  [dictionary[OPERATOR_SUBMIT]]: '#ff9999',
  [dictionary[QC_SUCCESS]]: '#FFCE56',
  [dictionary[QC_SUBMIT]]: '#ffe299',
  [dictionary[COMPLETED]]: '#00ac66',
  TOTAL: '#00cc7a',
};

export const form14StatusAliases = {
  OPERATOR_SUCCESS: 'openedStatusForm14',
  COMPLETED: 'closedStatusForm14',
};
