import { OPERATOR_SUCCESS, COMPLETED } from 'constants/formStatus';
import { OPERATOR, ADMIN, SUPER_ADMIN, QC, FOREMAN } from 'constants/global-roles';
import {
  initialValuesCreate,
  initialValuesUpdate,
  schemaAll,
} from 'pages/TrafficAccidents/TrafficAccidentForm/TrafficAccidentFormschema';

const adminEnabled = {
  [OPERATOR_SUCCESS]: {
    enabledFields: initialValuesUpdate,
    validationSchema: schemaAll,
    canAddFiles: true,
    showEmails: true,
    showSubmit: true,
    showSign: true,
  },
  [COMPLETED]: {
    enabledFields: {},
    validationSchema: {},
    canAddFiles: false,
    showEmails: true,
    showSubmit: false,
    showSign: false,
  },
};

const operatorEnabled = {
  [OPERATOR_SUCCESS]: {
    enabledFields: initialValuesUpdate,
    validationSchema: schemaAll,
    canAddFiles: true,
    showEmails: true,
    showSubmit: true,
    showSign: true,
  },
  [COMPLETED]: {
    enabledFields: {},
    validationSchema: {},
    canAddFiles: false,
    showEmails: true,
    showSubmit: false,
    showSign: false,
  },
};

export default {
  [SUPER_ADMIN]: {
    createAvailable: true,
    dependencies: adminEnabled,
    enabledFields: initialValuesCreate,
    validationSchema: schemaAll,
  },
  [ADMIN]: {
    createAvailable: true,
    dependencies: adminEnabled,
    enabledFields: initialValuesCreate,
    validationSchema: schemaAll,
  },
  [OPERATOR]: {
    createAvailable: true,
    dependencies: operatorEnabled,
    enabledFields: initialValuesCreate,
    validationSchema: schemaAll,
  },
  [QC]: { createAvailable: false, dependencies: {}, enabledDependency: {}, validationSchema: null },
  [FOREMAN]: {
    createAvailable: false,
    dependencies: {},
    enabledDependency: {},
    validationSchema: null,
  },
};
