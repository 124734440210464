import { compose, withProps, defaultProps } from 'recompose';

import { toOffsettedTimestamp, fromUTC } from 'utils/index';
import { withLogProps, withFormPage } from 'hoc/index';
import { FORM_TYPE, FORM_HEADER } from 'constants/formSetup';
import enabledDependencyForm1 from 'constants/form1Setup';

import Form1Content from '../TrafficAccidentForm/TrafficAccidentForm';
import Component from './FormPage';

const getForm1Query = (id) => ({
  query: `query{
    findAllHandlingTrafficAccident(
      finder:{
        id:"${id}"
      }
    ) {
      forms{
        id
        projectId{
          id
        }
        projectName
        contractNumber
        failesSafetyNo
        openingName
        openingDate
        openingHour
        element
        reportingFactor
        location
        side
        datailOfTheAccident
        mixedCars
        callCenterReportingOperationsTeam
        telephoneReportingForProjectManagement
        telephoneReportingAndEmergencyPowers
        reportByTelephoneThePolice
        remarks
        specialPatrolUnitStaffInfo
        endOfTreatmentTime
        treatmentDateEnd
        eventHappeningDate
        eventHappeningTime
        submitDate
        documents{
          id
          type
          nameWithExtension
          description
        }
        status
      }
      size
    }
  }`,
});

const submitStatusForm1Query = (id) => ({
  query: `mutation {
    submitHandlingTrafficAccidentStatus(
      handlingTrafficAccidentId: "${id}"
    ) {
      id
    }
  }`,
});

const updateForm1Query = ({
  formId,
  side,
  location,
  reportingFactor,
  element,
  datailOfTheAccident,
  mixedCars,
  telephoneReportingForProjectManagement,
  callCenterReportingOperationsTeam,
  reportByTelephoneThePolice,
  telephoneReportingAndEmergencyPowers,
  specialPatrolUnitStaffInfo,
  remarks,
  endOfTreatmentTime,
  treatmentDateEnd,
}) => {
  return {
    query: `mutation updateFormOperator{
      updateFormOperator(
        ${formId ? `formId:"${formId}"` : ''}
        ${side ? `side:"${side}"` : ''}
        ${location ? `location:"${location}"` : ''}
        ${reportingFactor ? `reportingFactor:"${reportingFactor}"` : ''}
        ${element ? `element:"${element}"` : ''}
        ${datailOfTheAccident ? `datailOfTheAccident:"${datailOfTheAccident}"` : ''}
        ${mixedCars ? `mixedCars:"${mixedCars}"` : ''}
        ${
          telephoneReportingForProjectManagement
            ? `telephoneReportingForProjectManagement:"${telephoneReportingForProjectManagement}"`
            : ''
        }
        ${
          callCenterReportingOperationsTeam
            ? `callCenterReportingOperationsTeam:"${callCenterReportingOperationsTeam}"`
            : ''
        }
        ${reportByTelephoneThePolice ? `reportByTelephoneThePolice:"${reportByTelephoneThePolice}"` : ''}
        ${
          telephoneReportingAndEmergencyPowers
            ? `telephoneReportingAndEmergencyPowers:"${telephoneReportingAndEmergencyPowers}"`
            : ''
        }
        ${specialPatrolUnitStaffInfo ? `specialPatrolUnitStaffInfo:"${specialPatrolUnitStaffInfo}"` : ''}
        ${remarks ? `remarks:"${remarks}"` : ''}
        ${endOfTreatmentTime ? `endOfTreatmentTime: "${toOffsettedTimestamp(endOfTreatmentTime)}"` : ''}
        ${treatmentDateEnd ? `treatmentDateEnd: "${toOffsettedTimestamp(treatmentDateEnd)}"` : ''}
      ) {
        id
      }
    }`,
  };
};

const enchance = compose(
  defaultProps({}),
  // NOTE this is config, so should be before main logic
  withProps(() => {
    return {
      headerUpdate: FORM_HEADER.form1.update,
      formType: FORM_TYPE.form1,
      dependencies: enabledDependencyForm1,
      showFiles: true,
      getCurrentPageQuery: (id) => getForm1Query(id),
      getCurrentPageResponseName: 'findAllHandlingTrafficAccident',
      formContent: Form1Content,
      manuallyUpdateInitialValues: (values) => {
        return {
          ...values,
          treatmentDateEnd: new Date(values.treatmentDateEnd),
          endOfTreatmentTime: fromUTC(`${values.treatmentDateEnd} ${values.endOfTreatmentTime}`),
          eventHappeningDate: new Date(values.eventHappeningDate),
          eventHappeningTime: fromUTC(`${values.eventHappeningDate} ${values.eventHappeningTime}`),
        };
      },
      updateCurrentPageQuery: () => (values) => updateForm1Query(values),
      signCurrentPageQuery: (props) => (values) => {
        props.setIsFetching(true);

        return props.handleUpdateAndSign(values, submitStatusForm1Query);
      },
    };
  }),
  withFormPage,
  withLogProps,
);

export default enchance(Component);
