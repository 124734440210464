// @flow
import { formatString } from 'utils/index';

type Valueble = {
  value: string,
};

export default (extendedText: string, { value = '', ...rest }: Valueble) => ({
  ...rest,
  value: formatString(extendedText + value),
});
