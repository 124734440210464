/* eslint-disable no-undef */
// @flow
import toastr from 'toastr';

type MessageType = 'success' | 'error' | 'warning' | 'info';

export default (message: string, type: MessageType = 'success'): void => {
  toastr.options = {
    closeButton: false,
    debug: false,
    newestOnTop: false,
    progressBar: false,
    preventDuplicates: false,
    onclick: () => {},
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 3000,
    extendedTimeOut: 1000,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    tapToDismiss: false,
  };

  if (type === 'success') {
    toastr.success(message);
  }

  if (type === 'error') {
    toastr.error(message);
  }

  if (type === 'warning') {
    toastr.warning(message);
  }

  if (type === 'info') {
    toastr.info(message);
  }
};
