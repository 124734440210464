import { compose, withProps } from 'recompose';

import { toOffsettedTimestamp, fromUTC } from 'utils/index';
import { FORM_TYPE, FORM_HEADER } from 'constants/formSetup';
import enabledDependencyForm4 from 'constants/form4Setup';
import { withLogProps, withFormPage } from 'hoc/index';

import Form4Content from '../TrafficEventForm/TrafficEventForm';
import Component from './FormPage';

const getForm4Query = (id) => ({
  query: `query{
    findAllHandlingTrafficEvent(
      finder:{
        id:"${id}"
      }
    ) {
      forms{
        id
        projectId{
          id
        }
        projectName
        contractNumber
        failesSafetyNo
        openingName
        openingDate
        openingHour
        element
        reportingFactor
        location
        side
        datailOfTheAccident
        mixedCars
        callCenterReportingOperationsTeam
        telephoneReportingForProjectManagement
        telephoneReportingAndEmergencyPowers
        reportByTelephoneThePolice
        remarks
        specialPatrolUnitStaffInfo
        endOfTreatmentTime
        treatmentDateEnd
        eventHappeningTime
        eventHappeningDate
        submitDate
        documents{
          id
          type
          nameWithExtension
          description
        }
        status
      }
      size
    }
  }`,
});

const submitStatusForm4Query = (id) => ({
  query: `mutation {
    submitHandlingTrafficEventStatus(
      handlingTrafficEventId: "${id}"
    ) {
      id
    }
  }`,
});

const updateForm4Query = ({
  formId,
  side,
  location,
  reportingFactor,
  element,
  datailOfTheAccident,
  mixedCars,
  telephoneReportingForProjectManagement,
  callCenterReportingOperationsTeam,
  reportByTelephoneThePolice,
  telephoneReportingAndEmergencyPowers,
  specialPatrolUnitStaffInfo,
  remarks,
  endOfTreatmentTime,
  treatmentDateEnd,
}) => {
  return {
    query: `mutation updateHandlingTrafficEvent{
      updateHandlingTrafficEvent(
        id:"${formId}"
        ${side ? `side:"${side}"` : ''}
        ${location ? `location:"${location}"` : ''}
        ${reportingFactor ? `reportingFactor:"${reportingFactor}"` : ''}
        ${element ? `element:"${element}"` : ''}
        ${datailOfTheAccident ? `datailOfTheAccident:"${datailOfTheAccident}"` : ''}
        ${mixedCars ? `mixedCars:"${mixedCars}"` : ''}
        ${
          telephoneReportingForProjectManagement
            ? `telephoneReportingForProjectManagement:"${telephoneReportingForProjectManagement}"`
            : ''
        }
        ${
          callCenterReportingOperationsTeam
            ? `callCenterReportingOperationsTeam:"${callCenterReportingOperationsTeam}"`
            : ''
        }
        ${reportByTelephoneThePolice ? `reportByTelephoneThePolice:"${reportByTelephoneThePolice}"` : ''}
        ${
          telephoneReportingAndEmergencyPowers
            ? `telephoneReportingAndEmergencyPowers:"${telephoneReportingAndEmergencyPowers}"`
            : ''
        }
        ${specialPatrolUnitStaffInfo ? `specialPatrolUnitStaffInfo:"${specialPatrolUnitStaffInfo}"` : ''}
        ${remarks ? `remarks:"${remarks}"` : ''}
        ${endOfTreatmentTime ? `endOfTreatmentTime: "${toOffsettedTimestamp(endOfTreatmentTime)}"` : ''}
        ${treatmentDateEnd ? `treatmentDateEnd: "${toOffsettedTimestamp(treatmentDateEnd)}"` : ''}
      ) {
        id
      }
    }`,
  };
};

const enchance = compose(
  // NOTE this is config, so should be before main logic
  withProps(() => {
    return {
      headerUpdate: FORM_HEADER.form4.update,
      formType: FORM_TYPE.form4,
      dependencies: enabledDependencyForm4,
      showFiles: true,
      getCurrentPageQuery: (id) => getForm4Query(id),
      getCurrentPageResponseName: 'findAllHandlingTrafficEvent',
      formContent: Form4Content,
      manuallyUpdateInitialValues: (values) => ({
        ...values,
        treatmentDateEnd: new Date(values.treatmentDateEnd),
        endOfTreatmentTime: fromUTC(`${values.treatmentDateEnd} ${values.endOfTreatmentTime}`),
        eventHappeningDate: new Date(values.eventHappeningDate),
        eventHappeningTime: fromUTC(`${values.eventHappeningDate} ${values.eventHappeningTime}`),
      }),
      updateCurrentPageQuery: () => (values) => updateForm4Query(values),
      signCurrentPageQuery: (props) => (values) => {
        props.setIsFetching(true);

        return props.handleUpdateAndSign(values, submitStatusForm4Query);
      },
    };
  }),
  withFormPage,
  withLogProps,
);

export default enchance(Component);
